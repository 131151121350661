import * as React from 'react'
import { useTranslation } from 'react-i18next'

import _isEmpty from 'lodash/isEmpty'
import { Box, HStack, Spacer, Text, VStack } from '@chakra-ui/react'

import { Category } from '@/app/category/Category.model'
import { Section } from '@/app/section/Section.model'
import { ImgixImage } from '@/ui/components/image/ImgixImage'

type SectionRowProps = {
  readonly section: Section
  readonly categories?: ReadonlyArray<Category>
}

export const SectionRow: React.FC<SectionRowProps> = ({ section, categories }) => {
  const { t } = useTranslation('sections')
  return (
    <Box border='1px solid' borderColor='gray.300' pl='4' pt='2' pb='3' pr='3' borderRadius='4'>
      <HStack>
        <VStack alignItems='start' spacing='0'>
          <Text fontFamily='Inter' fontWeight='700' fontSize='20' lineHeight='7' color='gray.900'>
            {section.name}
          </Text>
          <Text fontFamily='Inter' fontWeight='500' fontSize='12' lineHeight='5' color='gray.900'>
            {categories?.map((category) => category.title).join(', ')}
          </Text>
          {_isEmpty(categories) && (
            <Text fontFamily='Inter' fontWeight='500' fontSize='12' lineHeight='5' color='gray.600' fontStyle='italic'>
              {t('noCategories')}
            </Text>
          )}
        </VStack>
        <Spacer />
        <Text fontWeight='400' fontSize='16' lineHeight='9' color='gray.900'>
          {t('backgroundImage')}
        </Text>
        <ImgixImage
          altText={section.name}
          id={section.imageId}
          backgroundHeight='48px'
          backgroundWidth='48px'
          heightInPixel={48}
          widthInPixel={48}
          path='sections/background'
        />
      </HStack>
    </Box>
  )
}
