import * as React from 'react'

import { Box, Text } from '@chakra-ui/react'

type OrderTableColumnTextCellProps = {
  readonly displayText: string
}

export const OrderTableColumnTextCell: React.FC<OrderTableColumnTextCellProps> = ({ displayText }) => (
  <Box maxWidth='xs'>
    <Text isTruncated>{displayText}</Text>
  </Box>
)
