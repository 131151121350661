import React from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Heading } from '@chakra-ui/react'

import { CategoryOperatingHoursType } from '@/app/category/Category.model'
import { Day } from '@/app/types/Day'

import { OperatingHoursNormalHourHeader } from './OperatingHoursNormalHourHeader'
import { OperatingHoursNormalHourRow } from './OperatingHoursNormalHourRow'

type OperatingHoursNormalHourFormProps = {
  type: CategoryOperatingHoursType
  showHeading?: boolean
}

export const OperatingHoursNormalHourForm: React.FC<OperatingHoursNormalHourFormProps> = ({
  type,
  showHeading = true,
}) => {
  const { t } = useTranslation('operatingHours')

  return (
    <Grid templateColumns='1fr min-content 10rem 1.5rem' rowGap={1} columnGap={4} width='full'>
      {showHeading && (
        <Heading size='lg' fontWeight='400' whiteSpace='nowrap' gridColumnStart={1} gridColumnEnd={-3}>
          {t(`operatingHours:${type}`)}
        </Heading>
      )}
      <OperatingHoursNormalHourHeader />
      <OperatingHoursNormalHourRow type={type} day={Day.MONDAY} />
      <OperatingHoursNormalHourRow type={type} day={Day.TUESDAY} />
      <OperatingHoursNormalHourRow type={type} day={Day.WEDNESDAY} />
      <OperatingHoursNormalHourRow type={type} day={Day.THURSDAY} />
      <OperatingHoursNormalHourRow type={type} day={Day.FRIDAY} />
      <OperatingHoursNormalHourRow type={type} day={Day.SATURDAY} />
      <OperatingHoursNormalHourRow type={type} day={Day.SUNDAY} />
    </Grid>
  )
}
