import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, Heading, Spinner } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { Category } from '@/app/category/Category.model'
import { useGroupListQuery } from '@/app/group/useGroupListQuery'
import { useUpdateGroupListMutation } from '@/app/group/useUpdateGroupListMutation'
import { EditModalActions, EditModalBody, EditModalHeader } from '@/ui/components/editModal/EditModal'

import { UpdateGroupsForm, UpdateGroupsRequest } from './UpdateGroupsForm'

export type UpdateGroupsModalBodyProps = {
  categoryId: Category['id']
  onSave: () => void
}

export const UpdateGroupsModalBody: React.FC<UpdateGroupsModalBodyProps> = ({ categoryId, onSave }) => {
  const { t } = useTranslation('categories')
  const methods = useForm<UpdateGroupsRequest>()
  const { isSuccess, dto } = useGroupListQuery({
    categoryId,
    enabled: !methods.formState.isDirty,
  })
  const { mutate } = useUpdateGroupListMutation({ categoryId, onSuccess: onSave })

  const saveGroups = ({ groups }: UpdateGroupsRequest) => {
    mutate(groups.map((group) => (group.isNew ? { name: group.name } : group)))
  }

  return (
    <>
      <EditModalHeader>
        <Heading size='lg' fontWeight='medium' pb={4}>
          {t('groups.edit.title')}
        </Heading>
      </EditModalHeader>
      <EditModalBody>
        {isSuccess ? (
          <FormProvider {...methods}>
            <UpdateGroupsForm groups={dto} />
          </FormProvider>
        ) : (
          <Spinner />
        )}
      </EditModalBody>
      <EditModalActions>
        <Flex alignItems='center' justifyContent='flex-start'>
          <ButtonQg data-testid='submit-button' buttonType='primary' onClick={methods.handleSubmit(saveGroups)}>
            {t('common:action.save')}
          </ButtonQg>
        </Flex>
      </EditModalActions>
    </>
  )
}
