import { useQueryClient } from 'react-query'

import { UseAxiosMutationOptions, useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { ItemDto } from '@/app/item/Item.dto'
import { Item } from '@/app/item/Item.model'
import { ITEMS_QUERY_KEY } from '@/app/item/useItemsQuery'

export type UpdateItemRequest = Partial<
  Pick<
    ItemDto,
    | 'isActiveOnline'
    | 'isActiveOnSite'
    | 'isChefExperience'
    | 'categoryId'
    | 'description'
    | 'groupIds'
    | 'name'
    | 'mainImage'
    | 'stockManagement'
    | 'operatingHours'
  > &
    Pick<Item, 'availabilities'>
>

export type UpdateItemMutationOptions = UseAxiosMutationOptions<void, UpdateItemRequest> & { readonly id: string }

export const useUpdateItemMutation = ({
  id,
  onSuccess,
  ...options
}: UpdateItemMutationOptions): UseMutationExtendedResult<void, UpdateItemRequest> => {
  const queryClient = useQueryClient()
  return useMutation<void, UpdateItemRequest>({
    ...options,
    path: `/v1/admin/items/${id}`,
    method: 'patch',
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(ITEMS_QUERY_KEY)
      return onSuccess?.(data, variables, context)
    },
  })
}
