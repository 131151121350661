import React from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'

import { Category } from '@/app/category/Category.model'
import { Group } from '@/app/group/Group.model'
import { useUpdateGroupMutation } from '@/app/group/useUpdateGroupMutation'
import { SwitchWithDisruptive } from '@/ui/components/switch/SwitchWithDisruptive'

export type GroupListItemProps = {
  categoryId: Category['id']
  group: Group
  isSelected: boolean
  setSelected: () => void
}

export const GroupsListItem: React.FC<GroupListItemProps> = ({ categoryId, group, isSelected, setSelected }) => {
  const { mutateAsync } = useUpdateGroupMutation({ categoryId, groupId: group.id })
  const mutateGroupActiveStatus = async (newStatus: boolean) => mutateAsync({ active: newStatus })

  return (
    <Flex
      data-testid={`groups-list-item-${group.id}`}
      data-props-is_selected={isSelected}
      width='100%'
      alignItems='center'
      bg={isSelected ? 'blue.100' : 'none'}
    >
      <Text
        data-testid={`groups-list-item-text-${group.id}`}
        cursor='pointer'
        flex={1}
        fontWeight={isSelected ? 'bold' : 'normal'}
        fontSize='lg'
        lineHeight='shorter'
        p={2}
        onClick={setSelected}
      >
        {group.name}
      </Text>
      <Box p={2}>
        <SwitchWithDisruptive
          id={`active-group-${group.id}`}
          size='sm'
          initialValue={group.active}
          onConfirm={mutateGroupActiveStatus}
        />
      </Box>
    </Flex>
  )
}
