import React from 'react'
import { useTranslation } from 'react-i18next'

import { Td, Text, Tr } from '@chakra-ui/react'

import { Item } from '@/app/item/Item.model'

type CategoryItemTableProps = {
  readonly item: Item
}

export const CategoryItemTableRow: React.FC<CategoryItemTableProps> = ({ item }) => {
  const { t } = useTranslation()

  return (
    <Tr>
      <Td px={4} width='full'>
        <Text fontWeight='bold'>{item.name}</Text>
        <Text>{item.description}</Text>
      </Td>
      <Td px={4} isNumeric>
        {item.externalId}
      </Td>
      <Td px={4} isNumeric fontWeight='bold'>
        {t('price', { value: item.price })}
      </Td>
    </Tr>
  )
}
