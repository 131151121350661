import React from 'react'

import { Text } from '@chakra-ui/react'

import { Image } from '@/app/types/image'
import { HStackItem } from '@/ui/components/hstackItem/HStackItem'
import { ImgixImage } from '@/ui/components/image/ImgixImage'
import { ImageType } from '@/ui/pages/categories/update/image/ImageType'

export type CategoryImageHStackItemProps = {
  onClick: () => void
  text: string
  type: ImageType
  readonly image?: Image
}

export const CategoryImageHStackItem: React.FC<CategoryImageHStackItemProps> = ({ onClick, image, text, type }) => {
  return (
    <HStackItem spacing={3} onClick={onClick}>
      <ImgixImage
        altText={image?.altText}
        id={image?.id}
        backgroundHeight='62px'
        backgroundWidth='100px'
        heightInPixel={62}
        widthInPixel={100}
        path={`categories/${type}`}
      />
      <Text fontSize='lg' lineHeight='none'>
        {text}
      </Text>
    </HStackItem>
  )
}
