import {
  UseAxiosQueryOptions,
  useModelBuilderWithCurrentLocale,
  useQuery,
  UseQueryExtendedResult,
} from '@district-qg/frontend-common'

import { EmployeesListDto } from '@/app/employee/Employee.dto'
import { buildModel, Employee } from '@/app/employee/Employee.model'

export const EMPLOYEES_QUERY_KEY = 'employees'

type EmployeesQueryOptions = UseAxiosQueryOptions<ReadonlyArray<Employee>, EmployeesListDto>

export const useEmployeesQuery = (
  options?: EmployeesQueryOptions
): UseQueryExtendedResult<ReadonlyArray<Employee>, EmployeesListDto> => {
  const mapper = useModelBuilderWithCurrentLocale(buildModel)

  return useQuery({
    queryKey: EMPLOYEES_QUERY_KEY,
    ...options,
    path: '/v1/admin/employees',
    mapper,
  })
}
