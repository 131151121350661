import { UseMutationOptions } from 'react-query'

import { useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { useQGId } from '@/environment'

export const useFetchItemsMutation = (options: UseMutationOptions): UseMutationExtendedResult => {
  const qgId = useQGId()

  return useMutation<void, void>({
    path: `/v1/admin/qgs/${qgId}/synchronize-items`,
    ...options,
  })
}
