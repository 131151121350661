import * as React from 'react'

import { Category } from '@/app/category/Category.model'
import { OperatingHoursFormProvider } from '@/ui/pages/operatingHours/components/OperatingHoursFormProvider'

export type ScheduleViewProps = {
  category: Category
  isEditingSchedule: boolean
}

export const ScheduleView: React.FC<ScheduleViewProps> = ({ category, isEditingSchedule }) => {
  if (!isEditingSchedule) return null

  return <OperatingHoursFormProvider category={category} />
}
