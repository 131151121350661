import React from 'react'

import _times from 'lodash/times'
import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'

import { useCategoryQuery } from '@/app/category/useCategoryQuery'
import { OrderItem } from '@/app/order/OrderItem.model'
import { CategoryItemTableRow } from '@/ui/pages/orders/components/orderViewModal/categoryItemTable/CategoryItemTableRow'

type CategoryItemTableProps = {
  readonly categoryId: string
  readonly items: OrderItem[]
}

export const CategoryItemTable: React.FC<CategoryItemTableProps> = ({ categoryId, items }) => {
  const { data: category } = useCategoryQuery({ categoryId, refetchOnMount: false })

  if (!category) return null

  return (
    <Table borderWidth='1px' fontSize='sm'>
      <Thead bg='steel.700'>
        <Tr>
          <Th color='white' colSpan={3} textAlign='center' fontSize={14}>
            {category.title}
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {items.map((item, index) =>
          _times(item.quantity, (i) => (
            // eslint-disable-next-line react/no-array-index-key
            <CategoryItemTableRow item={item} key={`${item.id}-${index}-${i}`} />
          ))
        )}
      </Tbody>
    </Table>
  )
}
