import React from 'react'
import { useTranslation } from 'react-i18next'

import _isFinite from 'lodash/isFinite'
import _map from 'lodash/map'
import _uniq from 'lodash/uniq'

import { Order, OrderReceptionMode } from '@/app/order/Order.model'
import { OrderStatusBadge } from '@/ui/pages/orders/components/OrderStatusBadge'
import { OrderTableColumnTextCell } from '@/ui/pages/orders/components/OrderTableColumnTextCell'
import { TableColumn } from '@/ui/pages/orders/components/tableWithSearch/TableContent'

export const useOrderTableColumns = (): TableColumn[] => {
  const { t } = useTranslation('orders')

  const receptionModeLabel = ({ receptionMode }: Order) => {
    switch (receptionMode) {
      case OrderReceptionMode.delivery:
        return t('tableContent.columns.deliveryType')
      case OrderReceptionMode.takeout:
        return t('tableContent.columns.takeoutType')
      default:
        return t('tableContent.columns.unknownType')
    }
  }

  return [
    {
      id: 'orderNumber',
      header: t('tableContent.columns.veloceOrder'),
      cell({ orderNumber }: Order) {
        return _isFinite(orderNumber) && <OrderTableColumnTextCell displayText={String(orderNumber)} />
      },
      alignment: 'flex-start',
    },
    {
      id: 'clientName',
      header: t('tableContent.columns.clientName'),
      cell({ customer }: Order) {
        return <OrderTableColumnTextCell displayText={`${customer.firstName} ${customer.lastName}`} />
      },
      alignment: 'flex-start',
    },
    {
      id: 'type',
      header: t('tableContent.columns.type'),
      cell(order: Order) {
        return <OrderTableColumnTextCell displayText={receptionModeLabel(order)} />
      },
      alignment: 'center',
    },
    {
      id: 'items',
      header: t('tableContent.columns.items'),
      cell({ items }: Order) {
        return (
          <OrderTableColumnTextCell
            data-testid='itemsTextCell'
            displayText={items.reduce((acc, curr) => acc + curr.quantity, 0).toString()}
          />
        )
      },
      alignment: 'center',
    },
    {
      id: 'sections',
      header: t('tableContent.columns.sections'),
      cell({ items }: Order) {
        const numberOfCategories = _uniq(_map(items, 'categoryId')).length
        return <OrderTableColumnTextCell data-testid='sectionsTextCell' displayText={String(numberOfCategories)} />
      },
      alignment: 'center',
    },
    {
      id: 'cost',
      header: t('tableContent.columns.cost'),
      cell({ invoice }: Order) {
        return <OrderTableColumnTextCell displayText={t('price', { value: invoice.totalPrice })} />
      },
      alignment: 'flex-end',
    },
    {
      id: 'paymentStatus',
      header: t('tableContent.columns.paymentStatus'),
      cell({ paymentStatus }: Order) {
        return <OrderStatusBadge status={paymentStatus} />
      },
      alignment: 'center',
    },
    {
      id: 'posStatus',
      header: t('tableContent.columns.posStatus'),
      cell({ posStatus }: Order) {
        return <OrderStatusBadge status={posStatus} />
      },
      alignment: 'center',
    },
    {
      id: 'creationTime',
      header: t('tableContent.columns.creationDate'),
      cell({ creationTime }: Order) {
        return <OrderTableColumnTextCell displayText={t('tableContent.creationDateTime', { date: creationTime })} />
      },
      alignment: 'flex-end',
    },
  ]
}
