import * as React from 'react'
import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Flex, Heading } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { Category } from '@/app/category/Category.model'
import { usePatchCategoryMutation } from '@/app/category/usePatchCategoryMutation'
import { useQGQuery } from '@/app/qg/useQGQuery'
import { EditModal, EditModalActions, EditModalHeader } from '@/ui/components/editModal/EditModal'
import { CategoryModalForm } from '@/ui/pages/categories/modal/CategoryModalForm'

import { mapFieldValuesToRequest } from './mapFieldValuesToRequest'

export type UpdateCategoryModalProps = {
  isOpen: boolean
  onClose: () => void
  category: Category
}

export const UpdateCategoryModal: React.FC<UpdateCategoryModalProps> = ({ isOpen, onClose, category }) => {
  const { t } = useTranslation(['categories', 'common'])
  const formMethods = useForm<FieldValues>({
    defaultValues: {
      sectionId: category.sectionId,
      title: category.title,
      subtitle: category.subtitle,
      description: category.description,
    },
  })
  const { handleSubmit, reset } = formMethods

  useEffect(() => {
    reset({
      sectionId: category.sectionId,
      title: category.title,
      subtitle: category.subtitle,
      description: category.description,
    })
  }, [reset, category])

  const { data: qg } = useQGQuery()
  const { isLoading, mutateAsync } = usePatchCategoryMutation(category.id)
  const history = useHistory()
  const closeModal = () => {
    onClose()
    reset()
  }

  const updateCategory = async (fieldValues: FieldValues) => {
    try {
      await mutateAsync(mapFieldValuesToRequest(fieldValues))
      history.push(`/categories/${category.id}`)
    } finally {
      closeModal()
    }
  }

  if (!qg) return null

  return (
    <EditModal isOpen={isOpen} onClose={closeModal} sideBarColor='white'>
      <EditModalHeader>
        <Heading size='lg' fontWeight='medium' pb={4}>
          {t('update.title')}
        </Heading>
      </EditModalHeader>
      <CategoryModalForm formMethods={formMethods} sections={qg.sections} />
      <EditModalActions>
        <Flex alignItems='center' justifyContent='flex-start'>
          <ButtonQg
            data-testid='submit-button'
            isLoading={isLoading}
            buttonType='primary'
            bg='steel.base'
            onClick={handleSubmit(updateCategory)}
          >
            {t('common:action.save')}
          </ButtonQg>
        </Flex>
      </EditModalActions>
    </EditModal>
  )
}
