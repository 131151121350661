import * as React from 'react'
import { ReactElement, ReactNode } from 'react'

import { Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'

import { Order } from '@/app/order/Order.model'
import { useOrderView } from '@/ui/pages/orders/components/OrderViewContext'

type Alignment = 'flex-start' | 'center' | 'flex-end'

export type TableColumn = {
  id: string
  header: string
  cell: (order: Order) => ReactNode
  alignment: Alignment
}

export type TableContentProps = {
  orders: ReadonlyArray<Order>
  columns: ReadonlyArray<TableColumn>
}

export function TableContent({ columns, orders }: TableContentProps): ReactElement {
  const { openOrderId } = useOrderView()

  return (
    <Table my='8' borderWidth='1px' fontSize='sm'>
      <Thead bg='gray.50'>
        <Tr>
          {columns.map((column) => (
            <Th key={column.id} whiteSpace='nowrap' p={4} scope='col'>
              <Flex justify={column.alignment}>{column.header}</Flex>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {orders.map((order) => {
          return (
            <Tr key={order.id} onClick={() => openOrderId(order.id)} cursor='pointer' data-testid={`row-${order.id}`}>
              {columns.map((column) => (
                <Td key={column.id} p={4}>
                  <Flex justify={column.alignment}>{column.cell(order)}</Flex>
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}
