import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { GroupDto } from '@/app/group/Group.dto'

export interface Group {
  readonly id: string
  readonly active: boolean
  readonly name: string
  readonly imageId?: string
}

export class GroupModel implements Group {
  constructor(private readonly data: Readonly<GroupDto>, private readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get active(): boolean {
    return this.data.active
  }

  get name(): string {
    return localize(this.data.name, this.locale)
  }

  get imageId(): string | undefined {
    return this.data.imageId
  }
}

export function buildModel(data: GroupDto, locale: SupportedLocales): Group
export function buildModel(data: GroupDto[], locale: SupportedLocales): Group[]
export function buildModel(data: GroupDto | GroupDto[], locale: SupportedLocales): Group | Group[] {
  return Array.isArray(data) ? data.map((group) => buildModel(group, locale)) : new GroupModel(data, locale)
}
