import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import { createBrowserHistory } from 'history'
import { Auth0Provider } from '@auth0/auth0-react'
import { EnvironmentProvider, sentryConfig } from '@district-qg/frontend-common'
import { init as initSentry } from '@sentry/react'

import { App } from '@/App'
import { UserRolesProvider } from '@/app/role/UserRolesProvider'

import { init18n } from './i18n'
import { ROUTES } from './routes'

const { VITE_AUTH0_DOMAIN, VITE_AUTH0_ADMIN_APP_CLIENT_ID, VITE_AUTH0_AUDIENCE, VITE_AUTH0_ADMIN_DATABASE_NAME, MODE } =
  import.meta.env

export const history = createBrowserHistory()
initSentry(sentryConfig(history, ROUTES, import.meta.env))
init18n()

ReactDOM.render(
  <React.StrictMode>
    <EnvironmentProvider environment={import.meta.env}>
      <Auth0Provider
        domain={VITE_AUTH0_DOMAIN}
        clientId={VITE_AUTH0_ADMIN_APP_CLIENT_ID}
        redirectUri={`${window.location.origin}`}
        audience={VITE_AUTH0_AUDIENCE}
        connection={VITE_AUTH0_ADMIN_DATABASE_NAME}
      >
        <UserRolesProvider>
          <Router history={history}>
            <App isDev={MODE === 'development'} />
          </Router>
        </UserRolesProvider>
      </Auth0Provider>
    </EnvironmentProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
if (import.meta.hot) {
  import.meta.hot?.accept?.()
}
