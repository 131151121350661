import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import _pick from 'lodash/pick'
import { Box, Heading } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { Item } from '@/app/item/Item.model'
import { UpdateItemRequest, useUpdateItemMutation } from '@/app/item/useUpdateItemMutation'
import { EditModalActions, EditModalHeader } from '@/ui/components/editModal/EditModal'
import { ItemEditForm } from '@/ui/pages/items/components/form/ItemEditForm'
import { useItemList } from '@/ui/pages/items/ItemListContext'

import {
  ItemOperatingHoursFormData,
  toItemOperatingHours,
  toItemOperatingHoursFormData,
} from '../form/ItemOperatingHoursFormData'

export type ItemEditModalBodyProps = {
  readonly item: Item
}

type UpdateItemRequestForm = Omit<UpdateItemRequest, 'operatingHours'> & {
  readonly operatingHoursActive: boolean
  readonly operatingHours: {
    online: ItemOperatingHoursFormData
  }
}

export const ItemEditModalBody: React.FC<ItemEditModalBodyProps> = ({ item }) => {
  const { categories, closeEdit } = useItemList()
  const { t } = useTranslation(['items', 'common'])
  const form = useForm<UpdateItemRequestForm>({
    defaultValues: {
      ..._pick(item, ['stockManagement', 'availabilities']),
      operatingHoursActive: !!item.operatingHours,
      operatingHours: {
        online: toItemOperatingHoursFormData(item.operatingHours),
      },
    },
    mode: 'onChange',
  })
  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = form

  const { mutateAsync, isLoading } = useUpdateItemMutation({
    id: item.id,
    onSuccess: closeEdit,
  })

  const onSubmit = async (data: UpdateItemRequestForm): Promise<void> => {
    const { operatingHoursActive, ...rest } = data
    await mutateAsync({
      ...rest,
      stockManagement: {
        active: data.stockManagement?.active ?? false,
        availableQuantity: data.stockManagement?.availableQuantity ?? 0,
        totalQuantity: data.stockManagement?.availableQuantity ?? 0,
      },
      operatingHours:
        data.operatingHoursActive && data.operatingHours ? toItemOperatingHours(data.operatingHours.online) : null,
    })
  }

  return (
    <FormProvider {...form}>
      <EditModalHeader>
        <Heading size='lg' fontWeight='medium' pb={4}>
          {item.name}
        </Heading>
      </EditModalHeader>
      <ItemEditForm item={item} categories={categories} />
      <EditModalActions>
        <Box pt='8'>
          <ButtonQg
            buttonType='primary'
            isLoading={isLoading}
            disabled={!isDirty || !isValid || isLoading}
            onClick={handleSubmit(onSubmit)}
            data-testid='submit-button'
          >
            {t('common:action.save')}
          </ButtonQg>
        </Box>
      </EditModalActions>
    </FormProvider>
  )
}
