import * as React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'
import { FormControl, FormErrorMessage, FormLabel, Input, Select, Stack, Textarea } from '@chakra-ui/react'

import { QG } from '@/app/qg/QG.model'
import { EditModalBody } from '@/ui/components/editModal/EditModal'

export type CategoryModalBodyProps = {
  formMethods: UseFormReturn
  sections?: QG['sections']
}

export const CategoryModalForm: React.FC<CategoryModalBodyProps> = ({ sections, formMethods }) => {
  const {
    register,
    formState: { errors },
  } = formMethods
  const { t } = useTranslation(['categories', 'common'])
  return (
    <EditModalBody includeFooter>
      <Stack spacing='6'>
        <FormControl id='section' isRequired isInvalid={errors.title}>
          <FormLabel size='xs' fontWeight='semibold' color='gray'>
            {t('create.section')}
          </FormLabel>
          <Select type='text' {...register('sectionId', { required: true })}>
            {_map(
              _sortBy(sections, ({ name }) => name.toUpperCase()),
              ({ id, name }) => (
                <option data-testid={id} key={id} value={id} label={name} />
              )
            )}
          </Select>
          <FormErrorMessage>{t('common:error.fieldRequired')}</FormErrorMessage>
        </FormControl>
        <FormControl id='title' isRequired isInvalid={errors.title}>
          <FormLabel size='xs' fontWeight='semibold' color='gray'>
            {t('create.title')}
          </FormLabel>
          <Input type='text' {...register('title', { required: true })} />
          <FormErrorMessage>{t('common:error.fieldRequired')}</FormErrorMessage>
        </FormControl>
        <FormControl id='subtitle' isRequired isInvalid={errors.subtitle}>
          <FormLabel size='xs' fontWeight='semibold' color='gray'>
            {t('create.subtitle')}
          </FormLabel>
          <Input type='text' {...register('subtitle', { required: true })} />
          <FormErrorMessage>{t('common:error.fieldRequired')}</FormErrorMessage>
        </FormControl>
        <FormControl id='description'>
          <FormLabel size='xs' fontWeight='semibold' color='gray'>
            {t('create.description')}
          </FormLabel>
          <Textarea display='block' height='xs' type='text' {...register('description', { required: false })} />
        </FormControl>
      </Stack>
    </EditModalBody>
  )
}
