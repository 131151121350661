import React from 'react'
import { useTranslation } from 'react-i18next'

import { Heading, HStack, Spinner, useDisclosure } from '@chakra-ui/react'
import { ButtonAdd } from '@district-qg/ui'

import { useEmployeesQuery } from '@/app/employee/useEmployeesQuery'
import { ContentHeader } from '@/ui/layout/components/ContentHeader'
import { CreateEmployeeModal } from '@/ui/pages/employees/components/modals/create/CreateEmployeeModal'
import { UpdateEmployeeModal } from '@/ui/pages/employees/components/modals/update/UpdateEmployeeModal'

import { EmployeesTable } from './components/EmployeesTable'
import { EmployeesListProvider } from './EmployeesListContext'

export const EmployeesListPage: React.FC = () => {
  const { t } = useTranslation('employees')
  const {
    isOpen: isOpenCreateEmployeeModal,
    onClose: onCloseCreateEmployeeModal,
    onOpen: onOpenCreateEmployeeModal,
  } = useDisclosure()
  const { data: employees, isLoading } = useEmployeesQuery()

  return (
    <>
      <ContentHeader>
        <HStack alignItems='baseline' spacing='3'>
          <Heading size='lg' fontWeight='normal'>
            {t('title')}
          </Heading>
          {isLoading ? (
            <Spinner />
          ) : (
            <ButtonAdd
              colorScheme='white'
              size='2xs'
              onClick={onOpenCreateEmployeeModal}
              data-testid='add-employee-button'
            />
          )}
        </HStack>
      </ContentHeader>
      <EmployeesListProvider employees={employees}>
        <EmployeesTable />
        <UpdateEmployeeModal />
      </EmployeesListProvider>
      <CreateEmployeeModal isOpen={isOpenCreateEmployeeModal} onClose={onCloseCreateEmployeeModal} />
    </>
  )
}
