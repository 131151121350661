import * as React from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Heading, useDisclosure, useToast } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { UpdateEmployeeMutationRequest, useUpdateEmployeeMutation } from '@/app/employee/useUpdateEmployeeMutation'
import { EditModal, EditModalActions, EditModalBody, EditModalHeader } from '@/ui/components/editModal/EditModal'
import {
  EmployeeModalForm,
  EmployeeModalFormFields,
} from '@/ui/pages/employees/components/modals/common/EmployeeModalForm'
import { useEmployeesList } from '@/ui/pages/employees/EmployeesListContext'

export const UpdateEmployeeModal: React.FC = () => {
  const { t } = useTranslation('employees')
  const formMethods = useForm<EmployeeModalFormFields>({
    mode: 'onChange',
    defaultValues: {},
  })
  const { handleSubmit, reset, formState, setValue } = formMethods

  const { editEmployee, setEditEmployee } = useEmployeesList()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const onCloseModal = () => {
    onClose()
    setEditEmployee(undefined)
    reset()
  }

  useEffect(() => {
    if (editEmployee) {
      onOpen()
      setValue('auth0Id', editEmployee.auth0Id)
      setValue('email', editEmployee.email)
      setValue('firstName', editEmployee.firstName)
      setValue('lastName', editEmployee.lastName)
      setValue('blocked', editEmployee.blocked)
      if (editEmployee.role) {
        setValue('role', editEmployee.role)
      }
    }
  }, [editEmployee, onOpen, setValue])

  const toast = useToast()

  const { isLoading, mutateAsync: updateEmployee } = useUpdateEmployeeMutation({
    onSuccess: () => {
      onCloseModal()
    },
    onError: () => {
      toast({
        title: t('update.error.title'),
        description: t('update.error.description'),
        status: 'error',
        isClosable: true,
        duration: 9000,
      })
    },
  })

  const updateEmployeeAction = async (updateEmployeeRequest: UpdateEmployeeMutationRequest) => {
    await updateEmployee(updateEmployeeRequest)
  }

  return (
    <EditModal isOpen={isOpen} onClose={onCloseModal} sideBarColor='steel.50'>
      <EditModalHeader>
        <Heading size='lg' fontWeight='medium' pb={4}>
          {t('update.modalTitle')}
        </Heading>
      </EditModalHeader>
      <FormProvider {...formMethods}>
        <EditModalBody>
          <EmployeeModalForm mode='update' />
        </EditModalBody>
      </FormProvider>
      <EditModalActions>
        <ButtonQg
          data-testid='submit-button'
          buttonType='primary'
          bg='steel.base'
          isLoading={isLoading}
          onClick={handleSubmit(updateEmployeeAction)}
          disabled={!formState.isValid || !formState.isDirty}
        >
          {t('common:action.save')}
        </ButtonQg>
      </EditModalActions>
    </EditModal>
  )
}
