import _pick from 'lodash/pick'

import { QGConfigurationDto } from '@/app/qg/QGConfiguration.dto'
import { UpdateQGConfigurationRequest } from '@/app/qg/useUpdateQGConfigurationMutation'
import { createValueListInputItem, getValue, ValueListInputItem } from '@/ui/components/input/ValueListInput'

export type QGConfigurationFormData = Omit<UpdateQGConfigurationRequest, 'emails'> & {
  emails: {
    orderConfirmationBCCs: Array<ValueListInputItem>
    orderError: Array<ValueListInputItem>
    accounting: Array<ValueListInputItem>
  }
}

export function fromDto(dto: QGConfigurationDto): QGConfigurationFormData {
  return {
    ..._pick(dto, [
      'address',
      'customerService',
      'openingHours',
      'payfacto',
      'delivery',
      'taxes',
      'legal',
      'orderPreparationTime',
      'earliestOrderTime',
    ]),
    emails: {
      orderConfirmationBCCs: dto.emails.orderConfirmationBCCs.map(createValueListInputItem),
      orderError: dto.emails.orderError.map(createValueListInputItem),
      accounting: dto.emails.accounting.map(createValueListInputItem),
    },
  }
}

export function toUpdateRequest(data: QGConfigurationFormData): UpdateQGConfigurationRequest {
  return {
    ...data,
    emails: {
      orderConfirmationBCCs: data.emails.orderConfirmationBCCs.map(getValue),
      orderError: data.emails.orderError.map(getValue),
      accounting: data.emails.accounting.map(getValue),
    },
  }
}
