import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useAuth0 } from '@auth0/auth0-react'
import { Button, DarkMode, HStack, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'

export const SidebarUser: React.FC = () => {
  const { t } = useTranslation()
  const { logout, user } = useAuth0()

  return (
    <DarkMode>
      <Menu>
        <MenuButton
          as={Button}
          variant='text'
          role='group'
          px='2'
          py='4'
          whiteSpace='normal'
          textAlign='left'
          border='1px solid'
          borderColor='transparent'
          _hover={{ borderColor: 'whiteAlpha.500' }}
        >
          <HStack>
            <Text fontSize='xs' lineHeight='1' isTruncated maxWidth='170px'>
              {user?.email}
            </Text>
          </HStack>
        </MenuButton>
        <MenuList
          minWidth='calc(var(--sidebar-width) - (var(--sidebar-pad-x) * 2))'
          py='1'
          borderColor='whiteAlpha.500'
        >
          <MenuItem data-testid='logout' onClick={() => logout({ returnTo: window.location.origin })}>
            {t('user.logout')}
          </MenuItem>
        </MenuList>
      </Menu>
    </DarkMode>
  )
}
