import React from 'react'
import { useTranslation } from 'react-i18next'

import { HStack, Input, VStack } from '@chakra-ui/react'

import { Customer } from '@/app/customer/Customer.model'

type OrderCustomerInfoProps = {
  readonly customer: Customer
}

export const OrderCustomerInfo: React.FC<OrderCustomerInfoProps> = ({ customer }) => {
  const { t } = useTranslation('orders')
  return (
    <VStack>
      <HStack>
        <Input
          flexBasis='32%'
          flexGrow={1}
          value={customer.firstName}
          type='text'
          backgroundColor='steel.50'
          isReadOnly
        />
        <Input
          flexBasis='32%'
          flexGrow={1}
          value={customer.lastName}
          type='text'
          backgroundColor='steel.50'
          isReadOnly
        />
        <Input
          flexBasis='26%'
          flexGrow={1}
          value={customer.phoneNumber}
          type='tel'
          backgroundColor='steel.50'
          isReadOnly
        />
      </HStack>
      <HStack>
        <Input
          flexBasis='15%'
          flexGrow={1}
          value={customer.civicNumber}
          type='text'
          backgroundColor='steel.50'
          isReadOnly
        />
        <Input
          flexBasis='50%'
          flexGrow={1}
          value={customer.streetAddress}
          type='text'
          backgroundColor='steel.50'
          isReadOnly
        />
        <Input
          flexBasis='15%'
          flexGrow={1}
          placeholder={`# ${t('modalContent.apartment')}`}
          value={customer.apartmentNumber ? `# ${customer.apartmentNumber}` : ''}
          type='text'
          backgroundColor='steel.50'
          isReadOnly
        />
        <Input
          flexBasis='20%'
          flexGrow={1}
          value={customer.postalCode}
          type='text'
          backgroundColor='steel.50'
          isReadOnly
        />
      </HStack>
    </VStack>
  )
}
