import { useState } from 'react'

import { useFetchItemsMutation } from '@/app/qg/useFetchItemsMutation'
import { useQGQuery } from '@/app/qg/useQGQuery'

export type SynchronizeItemsResult = {
  syncItems(): void
  readonly isSyncing: boolean
  readonly isLoading: boolean
}

export const useSynchronizeItems = (): SynchronizeItemsResult => {
  const [isSyncing, setIsSyncing] = useState(false)

  const { mutate, isLoading } = useFetchItemsMutation({
    onSuccess: () => setIsSyncing(true),
  })

  useQGQuery({
    onSuccess: (data) => {
      if (!data.isFetchingItems) setIsSyncing(false)
    },
    enabled: isSyncing,
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  })

  return { syncItems: mutate, isLoading, isSyncing }
}
