export const items = {
  edit: {
    active: 'Actif',
    availabilitiesStart: 'Disponible le',
    availabilitiesEnd: 'Jusqu’au',
    onSite: 'DG',
    online: 'En ligne',
    category: {
      name: 'Restaurant',
      placeholder: 'Choisir',
    },
    description: 'Description (200 char)',
    groups: 'Spécialités',
    isChefExperience: 'Chef',
    name: 'Nom de l’item',
    price: 'Prix',
    productCode: 'Code produit',
    stockManagement: 'Gestion des quantités',
    operatingHoursManagement: 'Gestion des heures de disponibilité',
    title: 'Item',
    veloceName: 'Nom Veloce: {{ externalName }}',
    lastUpdate: 'Dernière synchonisation de l’item Veloce id {{ externalId }} le {{ date, LL }} à {{ date, LT }}',
    tps: 'TPS',
    tvq: 'TVQ',
  },
  creationDate: '{{ date, L }}',
  status: {
    isActiveOnline: 'Actif en ligne',
    isActiveOnSite: 'Actif Expérience DG',
    isActiveChef: 'Actif Expérience Chef',
    isInactiveOnline: 'Inactif en ligne',
    isInactiveOnSite: 'Inactif Expérience DG',
    isInactiveChef: 'Inactif Expérience Chef',
    new: 'Nouveau',
    scheduledAvailability: 'Disponibilité limitée',
  },
  tableActions: {
    nameFilter: 'Filtrer par nom',
    statusPlaceholder: 'Tous les statuts',
    categoryIdFilterPlaceholder: 'Tous les restaurants',
    noCategoryIdValuePlaceholder: 'Aucun restaurant',
    groupPlaceholder: 'Toutes les spécialités',
  },
  tableContent: {
    columns: {
      category: 'Restaurant',
      createdAt: 'Date de création',
      groups: 'Spécialités',
      name: 'Nom',
      price: 'Prix',
      source: 'Source',
      online: 'En ligne',
      onSite: 'DG',
      isChefExperience: 'Chef',
      updatedAt: 'Mis à jour le',
    },
  },
  title: 'Items',
  url: '/items',
}
