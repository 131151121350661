import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import jwtDecode from 'jwt-decode'
import { useAuth0 } from '@auth0/auth0-react'
import { Center } from '@chakra-ui/react'

export type UserRolesContextType = {
  readonly roles: string[]
  readonly isLoading: boolean
  readonly isAdmin: boolean
  readonly isConcierge: boolean
}

export enum AccessTokenDecodedField {
  roles = 'https://district-qg-platform.com/roles',
}

export enum EmployeeRoles {
  Admin = 'admin',
  Concierge = 'concierge',
}

const initialState = { roles: [], isConcierge: false, isAdmin: false, isLoading: true, isBlocked: false }

const UserRolesContext = React.createContext<UserRolesContextType>(initialState)

export const UserRolesProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<UserRolesContextType>(initialState)
  const { isAuthenticated, isLoading, getAccessTokenSilently, error } = useAuth0()
  const { t } = useTranslation('common')

  const getRoles = useCallback(async () => {
    const token = await getAccessTokenSilently()
    const decodedToken: Record<string, unknown> = jwtDecode(token)
    const roles = (decodedToken[AccessTokenDecodedField.roles] as string[]) ?? []

    setState({
      roles,
      isAdmin: roles.includes(EmployeeRoles.Admin),
      isConcierge: roles.includes(EmployeeRoles.Concierge),
      isLoading: false,
    })
  }, [getAccessTokenSilently])

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getRoles()
    }
  }, [getRoles, isLoading, isAuthenticated])

  return (
    <UserRolesContext.Provider value={state}>
      {error?.message === 'user is blocked' ? <Center minH='100vh'>{t('blockedUserMessage')}</Center> : children}
    </UserRolesContext.Provider>
  )
}

export const useGetRoles = (): UserRolesContextType => useContext(UserRolesContext)
