import React from 'react'

import _groupBy from 'lodash/groupBy'
import { VStack } from '@chakra-ui/react'

import { Order } from '@/app/order/Order.model'
import { CategoryItemTable } from '@/ui/pages/orders/components/orderViewModal/categoryItemTable/CategoryItemTable'

type OrderItemsInfoProps = {
  readonly items: Order['items']
}

export const OrderItemsInfo: React.FC<OrderItemsInfoProps> = ({ items }) => (
  <VStack spacing={0}>
    {Object.entries(_groupBy(items, 'categoryId')).map(([categoryId, categoryItems]) => (
      <CategoryItemTable categoryId={categoryId} items={categoryItems} key={categoryId} />
    ))}
  </VStack>
)
