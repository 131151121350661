import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Badge } from '@chakra-ui/react'

import { EmployeeStatus } from '@/app/employee/EmployeeStatus'

type EmployeeStatusBadgeProps = {
  status: EmployeeStatus
}

const EmployeeStatusBadgeImpl: React.FC<EmployeeStatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation('employees')

  const statusBadgeColor = {
    pending: { background: 'steel.400', text: 'white', label: t(`status.${EmployeeStatus.pending}`) },
    active: { background: 'success.bg', text: 'black', label: t(`status.${EmployeeStatus.active}`) },
    blocked: { background: 'error.bg', text: 'white', label: t(`status.${EmployeeStatus.blocked}`) },
  }
  return (
    <Badge
      fontSize='xs'
      backgroundColor={statusBadgeColor[status].background}
      textColor={statusBadgeColor[status].text}
      textTransform='uppercase'
    >
      {statusBadgeColor[status].label}
    </Badge>
  )
}

export const EmployeeStatusBadge = React.memo(EmployeeStatusBadgeImpl)
