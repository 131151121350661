import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ReactComponent as Logo } from '@/ui/assets/images/le-qg-cadre.svg'
import { AdminLayout } from '@/ui/layout/AdminLayout'
import { CategoryPage } from '@/ui/pages/categories/CategoryPage'
import { ConfigurationPage } from '@/ui/pages/configuration/ConfigurationPage'
import { EmployeesListPage } from '@/ui/pages/employees/EmployeesListPage'
import { HomePage } from '@/ui/pages/HomePage'
import { ItemListPage } from '@/ui/pages/items/ItemListPage'
import { OrderListPage } from '@/ui/pages/orders/OrderListPage'

import { SectionListPage } from './ui/pages/sections/SectionListPage'
import {
  CATEGORIES_PATH,
  CONFIGURATION_PATH,
  EMPLOYEES_PATH,
  HOME_PAGE_PATH,
  ITEMS_PATH,
  ORDERS_PATH,
  SECTIONS_PATH,
} from './routes'

export const AppRouter: React.FC = () => {
  return (
    <AdminLayout logo={Logo}>
      <Switch>
        <Route path={CATEGORIES_PATH} component={CategoryPage} />
        <Route path={ITEMS_PATH} component={ItemListPage} />
        <Route path={SECTIONS_PATH} component={SectionListPage} />
        <Route path={ORDERS_PATH} component={OrderListPage} />
        <Route path={CONFIGURATION_PATH} component={ConfigurationPage} />
        <Route path={EMPLOYEES_PATH} component={EmployeesListPage} />
        <Route path={HOME_PAGE_PATH} component={HomePage} />
      </Switch>
    </AdminLayout>
  )
}
