export const common = {
  items: {
    tableContent: {
      columns: {
        categoryId: 'Restaurant',
        createdAt: 'Created at',
        groupIds: 'Specialties',
        name: 'Name',
        price: 'Price',
        status: 'Status',
        updatedAt: 'Updated at',
      },
    },
    stockManagement: 'Stock quantity management',
    title: 'Items',
    url: '/items',
  },
  nav: {
    orders: 'Orders',
    newItems: 'New items',
  },
  blockedUserMessage: 'You have been blocked from the admin app',
}
