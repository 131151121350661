import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { OrderInvoiceDto } from '@/app/order/OrderInvoice.dto'

export interface OrderInvoice {
  readonly totalPrice: number
  readonly totalTax: number
  readonly subTotalPrice: number
  readonly gratuity: OrderInvoiceDto['gratuity']
  readonly delivery: OrderInvoiceDto['delivery']
  readonly taxLines: ReadonlyArray<OrderInvoiceTaxLine>
}

export interface OrderInvoiceTaxLine {
  readonly id: string
  readonly title: string
  readonly value: number
}

export class OrderInvoiceModel implements OrderInvoice {
  readonly taxLines = this.data.taxLines.map((line) => ({
    ...line,
    title: localize(line.title, this.locale),
  }))

  constructor(private readonly data: Readonly<OrderInvoiceDto>, private readonly locale: SupportedLocales) {}

  get totalPrice(): number {
    return this.data.totalPrice
  }

  get totalTax(): number {
    return this.data.totalTax
  }

  get subTotalPrice(): number {
    return this.data.subTotalPrice
  }

  get gratuity(): OrderInvoice['gratuity'] {
    return this.data.gratuity
  }

  get delivery(): OrderInvoice['delivery'] {
    return this.data.delivery
  }
}
