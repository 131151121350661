import { EmployeeRole } from '@/app/employee/EmployeeRole'
import { EmployeeStatus } from '@/app/employee/EmployeeStatus'

export const employees = {
  create: {
    email: 'Courriel',
    error: {
      description: 'Une erreur s’est produite lors de la création de l’employé. Veuillez essayer de nouveau.',
      title: 'Erreur de création d’employé',
    },
    firstName: 'Prénom',
    lastName: 'Nom',
    modalTitle: 'Nouvel employé',
    role: 'Rôle',
  },
  role: {
    [EmployeeRole.admin]: 'Administrateur',
    [EmployeeRole.concierge]: 'Concierge',
  },
  status: {
    [EmployeeStatus.active]: 'Actif',
    [EmployeeStatus.blocked]: 'Bloqué',
    [EmployeeStatus.pending]: 'Invitation envoyée',
  },
  tableActions: {
    nameFilter: 'Filtrer par identifiant',
    rolePlaceholder: 'Tous les rôles',
    statusPlaceholder: 'Tous les statuts',
  },
  tableContent: {
    columns: {
      email: 'Courriel',
      firstName: 'Prénom',
      lastName: 'Nom',
      role: 'Rôle',
      status: 'Statut',
    },
  },
  title: 'Employés',
  update: {
    email: 'Courriel',
    blocked: 'Bloqué',
    error: {
      description:
        'Une erreur s’est produite lors de la modification du profil de l’employé. Veuillez essayer de nouveau.',
      title: 'Erreur lors de la modification du profil de l’employé',
    },
    firstName: 'Prénom',
    lastName: 'Nom',
    modalTitle: 'Employé',
    role: 'Rôle',
  },
}
