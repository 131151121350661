import _some from 'lodash/some'
import { SupportedLocales } from '@district-qg/frontend-common'

import { buildModel as buildCustomerModel, Customer } from '@/app/customer/Customer.model'
import { OrderDto } from '@/app/order/Order.dto'
import { OrderEventName, OrderTransaction, orderTransactions, totalMinusRefunds } from '@/app/order/OrderEvent.dto'
import { OrderInvoice, OrderInvoiceModel } from '@/app/order/OrderInvoice.model'
import { OrderItem, OrderItemModel } from '@/app/order/OrderItem.model'

export interface Order {
  readonly id: string
  readonly orderNumber?: number
  readonly creationTime: Date
  readonly expectedDateTime: Date
  readonly customer: Customer
  readonly receptionMode: OrderReceptionMode
  readonly invoice: OrderInvoice
  readonly posStatus?: OrderPosStatus
  readonly paymentStatus?: OrderPaymentStatus
  readonly items: ReadonlyArray<OrderItem>
  readonly transactions: ReadonlyArray<OrderTransaction>
  readonly totalMinusRefunds?: number
}

export enum OrderPosStatus {
  confirmed = 'confirmed',
  rejected = 'rejected',
}

export enum OrderPaymentStatus {
  cancelled = 'cancelled',
  paid = 'paid',
  rejected = 'paymentRejected',
}

export enum OrderReceptionMode {
  takeout = 'takeout',
  delivery = 'delivery',
}

function paymentStatus(dto: Readonly<OrderDto>) {
  switch (true) {
    case _some(dto.history, { name: OrderEventName.Cancelled }):
      return OrderPaymentStatus.cancelled
    case _some(dto.history, { name: OrderEventName.Paid }):
      return OrderPaymentStatus.paid
    case _some(dto.history, { name: OrderEventName.PaymentRejected }):
      return OrderPaymentStatus.rejected
    default:
      return undefined
  }
}

function posStatus(dto: Readonly<OrderDto>) {
  switch (true) {
    case _some(dto.history, { name: OrderEventName.PosConfirmed }):
    case _some(dto.history, { name: OrderEventName.PosManuallyConfirmed }):
      return OrderPosStatus.confirmed
    case _some(dto.history, { name: OrderEventName.PosRejected }):
      return OrderPosStatus.rejected
    default:
      return undefined
  }
}

export class OrderModel implements Order {
  readonly items = this.data.items.map((item) => new OrderItemModel(item, this.locale))

  readonly customer = buildCustomerModel(this.data.client, this.locale)

  readonly invoice = new OrderInvoiceModel(this.data.invoice, this.locale)

  readonly paymentStatus = paymentStatus(this.data)

  readonly posStatus = posStatus(this.data)

  readonly creationTime = new Date(this.data.createdAt)

  readonly expectedDateTime = new Date(this.data.expectedDateTime)

  readonly transactions = orderTransactions(this.data.history)

  readonly totalMinusRefunds = totalMinusRefunds(this.transactions)

  constructor(private readonly data: Readonly<OrderDto>, private readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get orderNumber(): Order['orderNumber'] {
    return this.data.orderNumber
  }

  get receptionMode(): OrderReceptionMode {
    return this.data.type as OrderReceptionMode
  }
}

export function buildModel(data: OrderDto, locale: SupportedLocales): Order
export function buildModel(data: OrderDto[], locale: SupportedLocales): Order[]
export function buildModel(data: OrderDto[] | OrderDto, locale: SupportedLocales): Order[] | Order {
  return Array.isArray(data) ? data.map((order) => buildModel(order, locale)) : new OrderModel(data, locale)
}
