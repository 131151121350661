import * as React from 'react'

import { Box } from '@chakra-ui/react'
import { BurgerIcon, ClearIcon } from '@district-qg/ui'

export type MobileMenuButtonProps = {
  readonly onClick?: () => void
  readonly isOpen?: boolean
}

export const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({ onClick, isOpen = false }) => {
  return (
    <Box
      display={{ base: 'block', md: 'none' }}
      ml='-2'
      as='button'
      type='button'
      rounded='md'
      p='1'
      fontSize='xl'
      color='gray.500'
      _hover={{ bg: 'gray.100' }}
      onClick={onClick}
    >
      <Box srOnly>{isOpen ? 'Close Menu' : 'Open Menu'}</Box>
      {isOpen ? <ClearIcon /> : <BurgerIcon />}
    </Box>
  )
}
