export const orders = {
  url: '/orders',
  title: 'Orders',
  status: {
    veloce: 'Veloce',
    rejected: 'Rejeted',
  },
  tableActions: {
    statusPlaceholder: 'All status',
  },
  tableContent: {
    columns: {
      veloceOrder: 'Order',
      clientName: "Client's name",
      type: 'Type',
      deliveryType: 'Delivery',
      takeoutType: 'Takeout',
      unknownType: 'Unknown',
      creationTime: 'Created at',
      items: 'items',
      cost: 'Total $',
      status: 'Status',
    },
  },
}
