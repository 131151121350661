import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import _findLastIndex from 'lodash/findLastIndex'
import { Box, Divider, Grid, IconButton } from '@chakra-ui/react'
import { AddIcon, DatePickerButton } from '@district-qg/ui'

import { CategoryOperatingHoursType } from '@/app/category/Category.model'
import { DEFAULT_HOURS } from '@/app/types/operatingHours'
import { CategoryCompleteOperatingHoursFormData } from '@/ui/pages/operatingHours/components/CategoryOperatingHoursFormData'

import { OperatingHoursExceptionHeader } from './OperatingHoursExceptionHeader'
import { OperatingHoursExceptionRow } from './OperatingHoursExceptionRow'

type OperatingHoursExceptionFormProps = {
  type: CategoryOperatingHoursType
}

export const OperatingHoursExceptionForm: React.FC<OperatingHoursExceptionFormProps> = ({ type }) => {
  const { t, i18n } = useTranslation()
  const { fields, remove, insert } = useFieldArray<
    CategoryCompleteOperatingHoursFormData,
    'operatingHours.online.exceptions' | 'operatingHours.onsite.exceptions',
    'fieldId'
  >({
    name: `operatingHours.${type}.exceptions`,
    keyName: 'fieldId',
  })

  const addException = (date: Date | null) => {
    const index = _findLastIndex(fields, ({ id }) => dayjs(id, 'YYYY-MM-DD').isBefore(date))
    insert(index + 1, { id: dayjs(date).format('YYYY-MM-DD'), isOpen: false, periods: [{ ...DEFAULT_HOURS }] })
  }

  return (
    <Box width='full'>
      <Grid templateColumns='1fr 1.5rem min-content 10rem 1.5rem' rowGap={1} columnGap={4} width='full'>
        <OperatingHoursExceptionHeader showColumnHeaders={!!fields.length} />
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <OperatingHoursExceptionRow type={type} index={index} day={field.id} deleteRow={() => remove(index)} />
            <Divider my={1} gridColumnStart={1} gridColumnEnd={-2} />
          </React.Fragment>
        ))}
      </Grid>
      <Box mt={2}>
        <DatePickerButton onChange={addException} locale={i18n.language}>
          <IconButton as='span' aria-label={t('action.add')} icon={<AddIcon boxSize='5' />} variant='outline' isRound />
        </DatePickerButton>
      </Box>
    </Box>
  )
}
