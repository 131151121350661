import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Box, GridItem, Heading, HStack, Select, VStack } from '@chakra-ui/react'
import { SupportedLocales } from '@district-qg/frontend-common'
import { ButtonQg } from '@district-qg/ui'

import { buildCategoryModel, Category } from '@/app/category/Category.model'
import { usePatchCategoryMutation } from '@/app/category/usePatchCategoryMutation'
import { CategoryOperatingHoursForm } from '@/ui/pages/operatingHours/components/CategoryOperatingHoursForm'
import {
  CategoryCompleteOperatingHoursFormData,
  toCategoryOperatingHours,
  toCategoryOperatingHoursFormData,
} from '@/ui/pages/operatingHours/components/CategoryOperatingHoursFormData'

export enum ScheduleType {
  online = 'online',
  onsite = 'onsite',
}

export type OperatingHoursFormProviderProps = {
  category: Category
}

export const OperatingHoursFormProvider: React.FC<OperatingHoursFormProviderProps> = ({ category }) => {
  const { t, i18n } = useTranslation('operatingHours')
  const form = useForm<CategoryCompleteOperatingHoursFormData>({
    defaultValues: {
      operatingHours: {
        online: toCategoryOperatingHoursFormData(category.operatingHours.online),
        onsite: toCategoryOperatingHoursFormData(category.operatingHours.onsite),
      },
    },
    mode: 'onChange',
  })

  const { mutateAsync, isLoading } = usePatchCategoryMutation(category.id)

  /* istanbul ignore next */
  const onSave = async (data: CategoryCompleteOperatingHoursFormData) => {
    await mutateAsync(
      {
        operatingHours: {
          online: toCategoryOperatingHours(data.operatingHours.online),
          onsite: toCategoryOperatingHours(data.operatingHours.onsite),
        },
      },
      {
        /* istanbul ignore next */
        onSuccess: (categoryDto) => {
          /* istanbul ignore next */
          const updatedCategory = buildCategoryModel(categoryDto, i18n.language as SupportedLocales)
          form.reset({
            operatingHours: {
              online: toCategoryOperatingHoursFormData(updatedCategory.operatingHours.online),
              onsite: toCategoryOperatingHoursFormData(updatedCategory.operatingHours.onsite),
            },
          })
        },
      }
    )
  }

  const [selectedScheduleType, setSelectedScheduleType] = useState(ScheduleType.online)

  return (
    <FormProvider {...form}>
      <GridItem gridArea='detailsHeader' data-testid='schedule-header'>
        <HStack spacing={4}>
          <Heading fontWeight='normal' size='lg'>
            {t('schedule')}
          </Heading>
          <Select
            data-testid='schedule-select'
            onChange={(e) => setSelectedScheduleType(e.currentTarget.value as ScheduleType)}
            backgroundColor='white'
            maxWidth='10rem'
          >
            <option
              data-testid='schedule-select-online'
              value={ScheduleType.online}
              label={t('online')}
              defaultChecked={selectedScheduleType === ScheduleType.online}
            />
            <option
              data-testid='schedule-select-onsite'
              value={ScheduleType.onsite}
              label={t('onsite')}
              defaultChecked={selectedScheduleType === ScheduleType.onsite}
            />
          </Select>
        </HStack>
      </GridItem>
      <GridItem gridArea='details'>
        <VStack spacing={8} alignItems='flex-start' mt={-2}>
          {selectedScheduleType === ScheduleType.online && <CategoryOperatingHoursForm type='online' />}
          {selectedScheduleType === ScheduleType.onsite && <CategoryOperatingHoursForm type='onsite' />}
          <Box mt='8'>
            <ButtonQg
              data-testid='submit-button'
              buttonType='primary'
              isLoading={isLoading}
              isDisabled={!form.formState.isValid || !form.formState.isDirty}
              onClick={form.handleSubmit(onSave)}
            >
              {t('common:action.save')}
            </ButtonQg>
          </Box>
        </VStack>
      </GridItem>
    </FormProvider>
  )
}
