export const operatingHours = {
  confirmDelete: {
    title: 'Effacer cette plage horaire',
  },
  day: {
    mon: 'Lundi',
    tue: 'Mardi',
    wed: 'Mercredi',
    thu: 'Jeudi',
    fri: 'Vendredi',
    sat: 'Samedi',
    sun: 'Dimanche',
  },
  exception: {
    date: '{{ date, dddd, LL }}',
  },
  header: {
    closed: 'FERMÉ',
    exceptions: 'Exceptions',
    normalHours: 'Heures d’opération régulières',
    open: 'OUVERT',
  },
  title: 'Heures d’opération',
  online: 'En ligne',
  onsite: 'En salle',
  schedule: 'Horaires',
}
