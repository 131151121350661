import React from 'react'
import { useTranslation } from 'react-i18next'

import { Heading, Text } from '@chakra-ui/react'

import { ContentHeader } from '../layout/components/ContentHeader'

export const HomePage: React.FC = () => {
  const { t } = useTranslation('common')
  return (
    <>
      <ContentHeader>
        <Heading size='lg' fontWeight='normal'>
          {t('dashboard.welcomeMessage.title')}
        </Heading>
      </ContentHeader>
      <Text>{t('dashboard.welcomeMessage.description')}</Text>
    </>
  )
}
