import React from 'react'
import { useTranslation } from 'react-i18next'

import { Heading, HStack } from '@chakra-ui/react'

export type OperatingHoursExceptionHeaderProps = {
  showColumnHeaders?: boolean
}

export const OperatingHoursExceptionHeader: React.FC<OperatingHoursExceptionHeaderProps> = ({
  showColumnHeaders = true,
}) => {
  const { t } = useTranslation('operatingHours')

  return (
    <>
      <Heading size='lg' fontWeight='400' whiteSpace='nowrap' gridColumnStart={1} gridColumnEnd={-3}>
        {t('header.exceptions')}
      </Heading>
      {showColumnHeaders && (
        <HStack w='full' spacing={1}>
          <Heading flex={1} size='xs' fontWeight='400' paddingTop={4} textAlign='center'>
            {t('header.open')}
          </Heading>
          <Heading flex={1} size='xs' fontWeight='400' paddingTop={4} textAlign='center'>
            {t('header.closed')}
          </Heading>
        </HStack>
      )}
    </>
  )
}
