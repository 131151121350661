import dayjs from 'dayjs'
import _flatMap from 'lodash/flatMap'
import _round from 'lodash/round'
import _sumBy from 'lodash/sumBy'

export enum OrderEventName {
  Received = 'OrderReceived',
  PosConfirmed = 'OrderConfirmed',
  PosManuallyConfirmed = 'OrderManuallyConfirmed',
  PosRejected = 'OrderRejected',
  PayfactoTransaction = 'PayfactoTransaction',
  Paid = 'OrderPaid',
  PaymentRejected = 'OrderPaymentRejected',
  Cancelled = 'OrderCancelled',
  Refund = 'OrderRefunded',
}

export enum OrderTransactionType {
  PAYMENT = 'payment',
  REFUND = 'refund',
  ERROR = 'error',
}

export type OrderEventDto =
  | {
      readonly name: OrderEventName.Paid
      readonly timestamp: string
      readonly transactionNumber: string
      readonly invoiceNumber: string
      readonly amount: number
    }
  | {
      readonly name: OrderEventName.Cancelled | OrderEventName.Refund
      readonly timestamp: string
      readonly transactionNumber: string
      readonly invoiceNumber: string
      readonly amount: number
      readonly reason: string
      readonly user?: {
        readonly email?: string
      }
    }
  | {
      readonly name: OrderEventName.PaymentRejected
      readonly timestamp: string
      readonly transactionNumber: string
      readonly errorDescription: string
      readonly returnCode: string
    }
  | {
      readonly name:
        | OrderEventName.Received
        | OrderEventName.PosConfirmed
        | OrderEventName.PosManuallyConfirmed
        | OrderEventName.PosRejected
        | OrderEventName.PayfactoTransaction
      readonly timestamp: string
    }

export type OrderTransaction = {
  readonly date: Date
  readonly type: OrderTransactionType
  readonly amount: number
  readonly transactionNumber: string
  readonly invoiceNumber: string
  readonly reason?: string
  readonly user?: string
}

export function orderTransactions(events: ReadonlyArray<OrderEventDto>): Array<OrderTransaction> {
  return _flatMap(events, (event) => {
    switch (event.name) {
      case OrderEventName.Paid:
        return {
          date: dayjs(event.timestamp).toDate(),
          type: OrderTransactionType.PAYMENT,
          amount: _round(Number(event.amount), 2),
          transactionNumber: event.transactionNumber,
          invoiceNumber: event.invoiceNumber,
        }
      case OrderEventName.Cancelled:
      case OrderEventName.Refund:
        return {
          date: dayjs(event.timestamp).toDate(),
          type: OrderTransactionType.REFUND,
          amount: _round(-Number(event.amount), 2),
          transactionNumber: event.transactionNumber,
          invoiceNumber: event.invoiceNumber,
          reason: event.reason,
          user: event.user?.email,
        }
      case OrderEventName.PaymentRejected:
        return {
          date: dayjs(event.timestamp).toDate(),
          type: OrderTransactionType.ERROR,
          amount: 0,
          transactionNumber: event.transactionNumber,
          invoiceNumber: '',
          reason: `${event.errorDescription} (${event.returnCode})`,
        }
      default:
        return []
    }
  })
}

export const totalMinusRefunds = (transactions: Array<OrderTransaction>): number => {
  return _round(_sumBy(transactions, 'amount'), 2)
}
