import dayjs from 'dayjs'
import { useModelBuilderWithCurrentLocale, useQuery, UseQueryExtendedResult } from '@district-qg/frontend-common'

import { OrderDto } from '@/app/order/Order.dto'
import { buildModel, Order } from '@/app/order/Order.model'
import { ORDERS_QUERY_KEY } from '@/app/order/useOrdersQuery'

export const TODAY_ORDERS_QUERY_KEY = [ORDERS_QUERY_KEY, 'today']

export const useTodayOrdersQuery = (): UseQueryExtendedResult<Order[], OrderDto[]> => {
  const mapper = useModelBuilderWithCurrentLocale(buildModel)

  return useQuery({
    queryKey: TODAY_ORDERS_QUERY_KEY,
    path: '/v1/admin/orders',
    mapper,
    params: { fromDate: encodeURIComponent(dayjs().startOf('day').toISOString()) },
  })
}
