import * as React from 'react'
import { ReactElement, ReactNode } from 'react'

import { Flex, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react'

import { Item } from '@/app/item/Item.model'
import { useItemList } from '@/ui/pages/items/ItemListContext'

type Alignment = 'flex-start' | 'center' | 'flex-end'

export type TableColumn = {
  id: string
  header: string
  cell: (item: Item) => ReactNode
  alignment: Alignment
}

export type TableContentProps = {
  items: ReadonlyArray<Item>
  columns: ReadonlyArray<TableColumn>
}

export function TableContent({ columns, items }: TableContentProps): ReactElement {
  const { openEditId } = useItemList()
  return (
    <Table my='8' borderWidth='1px' fontSize='sm'>
      <Thead bg={useColorModeValue('gray.50', 'gray.800')}>
        <Tr>
          {columns.map((column) => (
            <Th whiteSpace='nowrap' scope='col' key={column.id}>
              <Flex justify={column.alignment}>{column.header}</Flex>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {items.map((item) => (
          <Tr key={item.id} onClick={() => openEditId(item.id)} cursor='pointer' data-testid={`row-${item.id}`}>
            {columns.map((column) => (
              <Td key={column.id}>
                <Flex justify={column.alignment}>{column.cell(item)}</Flex>
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
