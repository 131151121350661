export const sections = {
  backgroundImage: 'Photo d’arrière plan',
  create: {
    description: 'Description',
    modalTitle: 'Nouveau terroir',
    title: 'Nom du terroir',
    error: {
      title: 'Erreur de création de terroir',
      description: 'Une erreur s’est produite lors de la création du terroir. Veuillez essayer de nouveau.',
    },
  },
  noCategories: 'Ne contient aucun restaurant et ne s’affichera pas dans le menu.',
  title: 'Terroirs',
  update: {
    modalTitle: 'Terroir',
    title: 'Nom du terroir',
    error: {
      title: 'Erreur de modification de terroir',
      description: 'Une erreur s’est produite lors de la modification du terroir. Veuillez essayer de nouveau.',
    },
  },
}
