import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Flex, Heading, UseModalProps, useToast } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { CreateSectionRequest, useCreateSectionMutation } from '@/app/qg/useCreateSectionMutation'
import { SECTIONS_PATH } from '@/routes'
import { EditModal, EditModalActions, EditModalBody, EditModalHeader } from '@/ui/components/editModal/EditModal'
import { SectionModalForm } from '@/ui/pages/sections/components/modals/common/SectionModalForm'

export type CreateSectionModalProps = UseModalProps & {
  restaurantZoneId: string
}

export const CreateSectionModal: React.FC<CreateSectionModalProps> = ({ isOpen, onClose, restaurantZoneId }) => {
  const { t } = useTranslation(['sections', 'common'])
  const formMethods = useForm<CreateSectionRequest>({
    defaultValues: { zoneId: restaurantZoneId },
    mode: 'onChange',
  })
  const { handleSubmit, reset, formState } = formMethods
  const history = useHistory()
  const onCloseModal = () => {
    onClose()
    reset()
  }
  const toast = useToast()

  const { isLoading, mutateAsync: createSection } = useCreateSectionMutation({
    onSuccess: () => {
      onCloseModal()
      history.push(SECTIONS_PATH)
    },
    onError: () => {
      toast({
        title: t('create.error.title'),
        description: t('create.error.description'),
        status: 'error',
        isClosable: true,
        duration: 9000,
      })
    },
  })

  const createSectionAction = async (createSectionRequest: CreateSectionRequest) => {
    await createSection(createSectionRequest)
  }

  return (
    <EditModal isOpen={isOpen} onClose={onCloseModal} sideBarColor='steel.50'>
      <EditModalHeader>
        <Heading size='lg' fontWeight='medium' pb={4}>
          {t('create.modalTitle')}
        </Heading>
      </EditModalHeader>
      <FormProvider {...formMethods}>
        <EditModalBody>
          <SectionModalForm />
        </EditModalBody>
      </FormProvider>
      <EditModalActions>
        <Flex alignItems='center' justifyContent='flex-start'>
          <ButtonQg
            data-testid='submit-button'
            buttonType='primary'
            bg='steel.base'
            isLoading={isLoading}
            onClick={handleSubmit(createSectionAction)}
            disabled={!formState.isValid}
          >
            {t('common:action.save')}
          </ButtonQg>
        </Flex>
      </EditModalActions>
    </EditModal>
  )
}
