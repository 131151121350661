import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _noop from 'lodash/noop'
import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

import { CloseButton } from '@/ui/components/closeButton/CloseButton'

export type DisruptiveModalProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => Promise<unknown>
  onSuccess?: () => void
  onFailure?: (e: unknown) => void
  title?: string
  message?: string
  errorMessage?: string
}

export const DisruptiveModal: React.FC<DisruptiveModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  onSuccess,
  onFailure,
  title,
  message,
  errorMessage,
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  useEffect(() => {
    if (isOpen) setIsFailed(false)
  }, [isOpen])
  const onConfirmClick = async () => {
    setIsLoading(true)
    try {
      await onConfirm()
      setIsFailed(false)
      if (onSuccess) onSuccess()
      onClose()
    } catch (e) {
      setIsFailed(true)
      if (onFailure) onFailure(e)
    }
    setIsLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={isLoading ? _noop : onClose}>
      <ModalOverlay />
      <ModalContent>
        <Flex>
          <ModalHeader flex={1}>{title ?? t('disruptive.title')}</ModalHeader>
          <CloseButton onClick={onClose} />
        </Flex>
        <ModalBody>
          <Text>{message ?? t('disruptive.message')}</Text>
          {isFailed && (
            <Text data-testid='error-message' color='red'>
              {errorMessage ?? t('error.happened')}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button data-testid='cancel-button' variant='outline' onClick={onClose} isDisabled={isLoading}>
              {t('action.cancel')}
            </Button>
            <Button
              isLoading={isLoading}
              data-testid='confirm-button'
              colorScheme='blue'
              mr={3}
              onClick={onConfirmClick}
            >
              {t('action.confirm')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
