import { useQueryClient } from 'react-query'

import { UseAxiosMutationOptions, useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { EmployeeDto } from '@/app/employee/Employee.dto'
import { EMPLOYEES_QUERY_KEY } from '@/app/employee/useEmployeesQuery'

export type UpdateEmployeeMutationRequest = Pick<EmployeeDto, 'auth0Id' | 'firstName' | 'lastName' | 'blocked'> &
  Required<Pick<EmployeeDto, 'role'>>

export type UpdateEmployeeRequest = Omit<UpdateEmployeeMutationRequest, 'auth0Id'>

export type UseUpdateEmployeeMutationOptions = UseAxiosMutationOptions<void, UpdateEmployeeMutationRequest>

export const useUpdateEmployeeMutation = (
  options: UseUpdateEmployeeMutationOptions
): UseMutationExtendedResult<void, UpdateEmployeeMutationRequest> => {
  const queryClient = useQueryClient()

  return useMutation<void, UpdateEmployeeMutationRequest, UpdateEmployeeRequest>({
    path: (updateEmployeeRequest) => `/v1/admin/employees/${updateEmployeeRequest.auth0Id}`,
    method: 'put',
    ...options,
    requestDataBuilder: (updateEmployeeRequest) => ({
      firstName: updateEmployeeRequest.firstName,
      lastName: updateEmployeeRequest.lastName,
      role: updateEmployeeRequest.role,
      blocked: updateEmployeeRequest.blocked,
    }),
    onSuccess: async (errors, variables, context) => {
      await queryClient.invalidateQueries(EMPLOYEES_QUERY_KEY)
      /* istanbul ignore next */
      return options?.onSuccess?.(errors, variables, context)
    },
  })
}
