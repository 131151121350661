import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import dayjs from 'dayjs'

const DATE_FORMAT = 'YYYY-MM-DD'

export type UrlDateRangeStateResult = [[Date, Date], (dateRange: [Date, Date] | [Date] | null) => void]

export const useUrlDateRangeState = (): UrlDateRangeStateResult => {
  const {
    push,
    location: { search },
  } = useHistory()
  const dateRange: [Date, Date] = useMemo(() => {
    const dateStartValue = new URLSearchParams(search).get('dateStart')
    const dateEndValue = new URLSearchParams(search).get('dateEnd')
    const today = new Date()

    return [
      dateStartValue
        ? dayjs(dateStartValue, DATE_FORMAT).startOf('day').toDate()
        : dayjs(today).startOf('day').toDate(),
      dateEndValue ? dayjs(dateEndValue, DATE_FORMAT).endOf('day').toDate() : dayjs(today).endOf('day').toDate(),
    ]
  }, [search])

  const setDateRange = (dates: [Date] | [Date, Date] | null) => {
    if (dates) {
      const dateStart = dates[0]
      const dateEnd = dates.length === 2 ? dates[1] : dates[0]
      push({
        search: `dateStart=${dayjs(dateStart).format(DATE_FORMAT)}&dateEnd=${dayjs(dateEnd).format(DATE_FORMAT)}`,
      })
    } else {
      push({
        search: '',
      })
    }
  }
  return [dateRange, setDateRange]
}
