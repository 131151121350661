import * as React from 'react'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'

import { UpdateCategoryRequest } from '@/app/category/usePatchCategoryMutation'
import { S3PresignedUrlRequest } from '@/app/media/useCreateS3PresignedUrlMutation'
import { Image } from '@/app/types/image'
import { ImgixImage } from '@/ui/components/image/ImgixImage'
import { ImageUploadDropzone } from '@/ui/components/imageUploadDropzone/ImageUploadDropzone'

type UpdateCategoryImageFormProps = {
  readonly image?: Image
}

export const UpdateCategoryImageForm: React.FC<UpdateCategoryImageFormProps> = ({ image }) => {
  const { register, setValue, watch } = useFormContext<UpdateCategoryRequest>()
  const { t } = useTranslation('categories')
  const imageFieldName = 'mainImage'
  const s3PresignedUrlRequest: S3PresignedUrlRequest = {
    type: 'main',
    target: 'categories',
  }
  const watchImage = watch(imageFieldName)
  const setImageId = (imageId: string) => {
    setValue(imageFieldName, { id: imageId })
  }
  useEffect(() => {
    if (image?.id) {
      setImageId(image.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  return (
    <Stack spacing='6'>
      <ImgixImage
        path='categories/main'
        id={/* istanbul ignore next */ watchImage?.id}
        backgroundHeight='200px'
        backgroundWidth='600px'
        heightInPixel={200}
        widthInPixel={600}
        altText={image?.altText}
      />
      <FormControl id='altText'>
        <FormLabel size='xs' fontWeight='semibold' color='gray'>
          {t('update.images.altText')}
        </FormLabel>
        <Input type='text' {...register('mainImage.altText.fr', { required: false })} />
      </FormControl>
      <FormControl id='imageUpload'>
        <ImageUploadDropzone imageUploadRequest={{ s3PresignedUrlRequest, setImageId }} />
      </FormControl>
    </Stack>
  )
}
