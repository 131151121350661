import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import { ControlledSwitch, ControlledSwitchProps } from '@/ui/components/switch/Switch'

type AbstractLabeledSwitchProps = ControlledSwitchProps & {
  labelTrue?: string
  labelFalse?: string
}
const AbstractLabeledSwitch: React.FC<AbstractLabeledSwitchProps> = (props) => {
  const { labelTrue, labelFalse, id, size, state } = props
  const { t } = useTranslation()
  const labelTrueOrDefault = labelTrue ?? t('active')
  const labelFalseOrDefault = labelFalse ?? t('inactive')

  return (
    <Flex alignItems='center'>
      <ControlledSwitch {...props} />
      <Text htmlFor={id} mb={0} ml={4} fontWeight='bold' fontSize={size ?? 'md'}>
        {state ? labelTrueOrDefault : labelFalseOrDefault}
      </Text>
    </Flex>
  )
}

export type LabeledControlledSwitchProps = AbstractLabeledSwitchProps
export const LabeledControlledSwitch: React.FC<LabeledControlledSwitchProps> = (props) => (
  <AbstractLabeledSwitch {...props} />
)

export type LabeledSwitchProps = Omit<AbstractLabeledSwitchProps, 'state' | 'onChange'> & {
  initialValue?: boolean
}
export const LabeledSwitch: React.FC<LabeledSwitchProps> = ({ initialValue, ...rest }) => {
  const [state, setState] = useState(initialValue ?? false)
  const toggle = () => setState(!state)

  return <AbstractLabeledSwitch state={state} onChange={toggle} {...rest} />
}
