import React from 'react'
import { useTranslation } from 'react-i18next'

import { Heading, HStack, Spinner } from '@chakra-ui/react'

import { useQGQuery } from '@/app/qg/useQGQuery'
import { ContentHeader } from '@/ui/layout/components/ContentHeader'

import { QGConfigurationFormProvider } from './components/QGConfigurationFormProvider'

export const ConfigurationPage: React.FC = () => {
  const { t } = useTranslation('configuration')
  const { isLoading: qgIsLoading, dto: qg } = useQGQuery()

  return (
    <>
      <ContentHeader>
        <HStack>
          <Heading size='lg' fontWeight='normal'>
            {t('title')}
          </Heading>
          {qgIsLoading && <Spinner />}
        </HStack>
      </ContentHeader>
      {qg && <QGConfigurationFormProvider qg={qg} />}
    </>
  )
}
