import * as React from 'react'
import { useState } from 'react'

import { Category } from '@/app/category/Category.model'
import { Group } from '@/app/group/Group.model'
import { useItemTableColumns } from '@/ui/pages/items/components/ItemTableColumns'
import { StatusFilterValues, useSortedFilteredItems } from '@/ui/pages/items/components/ItemTableFilter'
import { TableActions } from '@/ui/pages/items/components/tableWithSearch/TableActions'
import { TableContent } from '@/ui/pages/items/components/tableWithSearch/TableContent'
import { useItemList } from '@/ui/pages/items/ItemListContext'

export const ItemTable: React.FC = () => {
  const columns = useItemTableColumns()
  const { categories, items } = useItemList()

  const [nameFilter, setNameFilter] = useState<string>()
  const [statusFilter, setStatusFilter] = useState<StatusFilterValues>()
  const [categoryIdFilter, setCategoryIdFilter] = useState<Category['id']>()
  const [groupIdFilter, setGroupIdFilter] = useState<Group['id']>()

  return (
    <>
      <TableActions
        categories={categories}
        categoryIdFilter={categoryIdFilter}
        setCategoryIdFilter={setCategoryIdFilter}
        setGroupIdFilter={setGroupIdFilter}
        setNameFilter={setNameFilter}
        setStatusFilter={setStatusFilter}
      />
      <TableContent
        items={useSortedFilteredItems(items, {
          nameFilter,
          statusFilter,
          categoryIdFilter,
          groupIdFilter,
        })}
        columns={columns}
      />
    </>
  )
}
