import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import _isEmpty from 'lodash/isEmpty'
import { Spinner, Text } from '@chakra-ui/react'

import { OrderPaymentStatus, OrderPosStatus } from '@/app/order/Order.model'
import { useOrdersQuery } from '@/app/order/useOrdersQuery'
import { useOrderTableColumns } from '@/ui/pages/orders/components/OrderTableColumns'
import { TableActions } from '@/ui/pages/orders/components/tableWithSearch/TableActions'
import { TableContent } from '@/ui/pages/orders/components/tableWithSearch/TableContent'
import { useSortedFilteredOrders } from '@/ui/pages/orders/components/useSortedFilteredOrders'

type OrderTableProps = {
  readonly dateRange: [Date, Date]
}

export const OrderTable: React.FC<OrderTableProps> = ({ dateRange }) => {
  const { t } = useTranslation('orders')
  const columns = useOrderTableColumns()

  const [searchFilter, setSearchFilter] = useState<string>()
  const [paymentStatusFilter, setPaymentStatusFilter] = useState<OrderPaymentStatus>()
  const [posStatusFilter, setPosStatusFilter] = useState<OrderPosStatus>()
  const [hideAbandonedOrders, setHideAbandonedOrders] = useState(true)
  const process = useSortedFilteredOrders({
    searchFilter,
    paymentStatusFilter,
    posStatusFilter,
    hideAbandonedOrders,
  })

  const { data: orders, isLoading } = useOrdersQuery({
    from: dateRange[0],
    to: dateRange[1],
  })

  React.useEffect(() => {
    setSearchFilter('')
    setPaymentStatusFilter(undefined)
    setPosStatusFilter(undefined)
    setHideAbandonedOrders(true)
  }, [dateRange])

  if (isLoading) {
    return <Spinner />
  }

  if (_isEmpty(orders)) {
    return <Text>{t('emptyOrders')}</Text>
  }

  return (
    <>
      <TableActions
        setSearchFilter={setSearchFilter}
        setPaymentStatusFilter={setPaymentStatusFilter}
        setPosStatusFilter={setPosStatusFilter}
        selectedSearchFilter={searchFilter}
        selectedPaymentStatusFilter={paymentStatusFilter}
        selectedPosStatusFilter={posStatusFilter}
        hideAbandonedOrders={hideAbandonedOrders}
        setHideAbandonedOrders={setHideAbandonedOrders}
      />
      <TableContent orders={process(orders)} columns={columns} />
    </>
  )
}
