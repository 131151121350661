export const items = {
  edit: {
    active: 'Active',
    category: {
      name: 'Restaurant',
      placeholder: 'Choose',
    },
    description: 'Description (200 char)',
    groups: 'Specialties',
    name: 'Name',
    price: 'Price',
    productCode: 'Product code',
    stockManagement: 'Stock management',
    title: 'Item',
    veloce: 'Veloce',
  },
  status: {
    active: 'Active',
    inactive: 'Inactive',
    new: 'New',
  },
  tableActions: {
    nameFilter: 'Filtrer by name',
    statusPlaceholder: 'All status',
    categoryIdFilterPlaceholder: 'All restaurant',
    noCategoryIdValuePlaceholder: 'No restaurant',
    groupPlaceholder: 'All specialties',
  },
  tableContent: {
    columns: {
      category: 'Restaurant',
      createdAt: 'Created at',
      groups: 'Specialties',
      name: 'Name',
      price: 'Price',
      source: 'Source',
      status: 'Status',
      updatedAt: 'Updated at',
    },
  },
  title: 'Items',
  url: '/items',
}
