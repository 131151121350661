import * as React from 'react'

import _noop from 'lodash/noop'

import { Employee } from '@/app/employee/Employee.model'

export type EmployeesListData = {
  readonly employees: ReadonlyArray<Employee>
  readonly editEmployee?: Employee
  setEditEmployee(employee: Employee | undefined): void
}

const EmployeesListContext = React.createContext<EmployeesListData>({
  employees: [],
  setEditEmployee: _noop,
})

export type EmployeesListProviderProps = {
  readonly employees?: ReadonlyArray<Employee>
}

export const EmployeesListProvider: React.FC<EmployeesListProviderProps> = ({ employees = [], children }) => {
  const [editEmployee, setEditEmployee] = React.useState<Employee | undefined>(undefined)

  const value = {
    employees,
    editEmployee,
    setEditEmployee: (employee: Employee) => setEditEmployee(employee),
  }
  return <EmployeesListContext.Provider value={value}>{children}</EmployeesListContext.Provider>
}

export const useEmployeesList = (): EmployeesListData => React.useContext(EmployeesListContext)
