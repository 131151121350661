import * as React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import _noop from 'lodash/noop'

import { Category } from '@/app/category/Category.model'
import { Item } from '@/app/item/Item.model'

export type ItemListData = {
  readonly categories: ReadonlyArray<Category>
  readonly items: ReadonlyArray<Item>
  readonly editItemId?: string
  openEditId(itemId: string): void
  closeEdit(): void
}

const ItemListContext = React.createContext<ItemListData>({
  categories: [],
  items: [],
  openEditId: _noop,
  closeEdit: _noop,
})

export type ItemListProviderProps = {
  readonly categories?: ReadonlyArray<Category>
  readonly items?: ReadonlyArray<Item>
}

export const ItemListProvider: React.FC<ItemListProviderProps> = ({ categories = [], items = [], children }) => {
  // useRouteMatch is used for deep linking
  const routeMatch = useRouteMatch<{ id: string }>('/items/:id')
  const { push } = useHistory()
  const [editItemId, setEditItemId] = React.useState<string | undefined>(routeMatch?.params?.id)

  const closeEdit = () => {
    setEditItemId(undefined)
    if (routeMatch) {
      push('/items')
    }
  }
  const value = {
    items,
    categories,
    editItemId,
    openEditId: (id: string) => setEditItemId(id),
    closeEdit,
  }
  return <ItemListContext.Provider value={value}>{children}</ItemListContext.Provider>
}

export const useItemList = (): ItemListData => React.useContext(ItemListContext)
