import _deburr from 'lodash/deburr'
import _includes from 'lodash/includes'
import _sortBy from 'lodash/sortBy'

import { Category } from '@/app/category/Category.model'
import { Group } from '@/app/group/Group.model'
import { Item } from '@/app/item/Item.model'

export const NO_CATEGORY_ID: Category['id'] = '-1'

export enum StatusFilterValues {
  IS_NEW = 'isNew',
  IS_ACTIVE_ONLINE = 'isActiveOnline',
  IS_ACTIVE_DG_EXPERIENCE = 'isActiveOnSite',
  IS_ACTIVE_CHEF_EXPERIENCE = 'isActiveChef',
  IS_INACTIVE_ONLINE = 'isInactiveOnline',
  IS_INACTIVE_DG_EXPERIENCE = 'isInactiveOnSite',
  IS_INACTIVE_CHEF_EXPERIENCE = 'isInactiveChef',
}

const isContainingLetters = (name: string, nameFilter?: string): boolean =>
  nameFilter ? _includes(_deburr(name.toLowerCase()), _deburr(nameFilter.toLowerCase())) : true

const isFilteredByStatus = (
  { isActiveOnline, isActiveOnSite, isChefExperience, isNew }: Item,
  filterValue?: StatusFilterValues
): boolean => {
  switch (filterValue) {
    case StatusFilterValues.IS_NEW:
      return isNew
    case StatusFilterValues.IS_ACTIVE_ONLINE:
      return isActiveOnline
    case StatusFilterValues.IS_ACTIVE_DG_EXPERIENCE:
      return isActiveOnSite
    case StatusFilterValues.IS_ACTIVE_CHEF_EXPERIENCE:
      return isChefExperience
    case StatusFilterValues.IS_INACTIVE_ONLINE:
      return !isActiveOnline
    case StatusFilterValues.IS_INACTIVE_DG_EXPERIENCE:
      return !isActiveOnSite
    case StatusFilterValues.IS_INACTIVE_CHEF_EXPERIENCE:
      return !isChefExperience
    default:
      return true
  }
}

const isFromCategoryId = (categoryId?: string, filterCategoryId?: string): boolean => {
  if (!filterCategoryId) {
    return true
  }
  if (!categoryId && filterCategoryId === NO_CATEGORY_ID) return true

  if (!categoryId) return false

  return categoryId === filterCategoryId
}

const isFromGroupId = (groupIds: ReadonlyArray<Group['id']>, filterGroupId?: Group['id']): boolean =>
  filterGroupId ? groupIds.includes(filterGroupId) : true

export const useSortedFilteredItems = (
  items: ReadonlyArray<Item>,
  {
    nameFilter,
    statusFilter,
    categoryIdFilter,
    groupIdFilter,
  }: {
    nameFilter?: string
    statusFilter?: StatusFilterValues
    categoryIdFilter?: string
    groupIdFilter?: string
  }
): ReadonlyArray<Item> => {
  return _sortBy(
    items.filter(
      (item) =>
        isContainingLetters(item.name, nameFilter) &&
        isFilteredByStatus(item, statusFilter) &&
        isFromCategoryId(item.categoryId, categoryIdFilter) &&
        isFromGroupId(item.groupIds, groupIdFilter)
    ),
    'name'
  )
}
