import React from 'react'
import { useTranslation } from 'react-i18next'

import { Heading, HStack } from '@chakra-ui/react'

export const OperatingHoursNormalHourHeader: React.FC = () => {
  const { t } = useTranslation('operatingHours')

  return (
    <HStack w='full' spacing={1} gridColumnStart={-3} gridColumnEnd={-2}>
      <Heading flex={1} size='xs' fontWeight='400' pt={4} textAlign='center'>
        {t('header.open')}
      </Heading>
      <Heading flex={1} size='xs' fontWeight='400' pt={4} textAlign='center'>
        {t('header.closed')}
      </Heading>
    </HStack>
  )
}
