import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
} from '@chakra-ui/react'

import { EmployeeRole } from '@/app/employee/EmployeeRole'
import { CreateEmployeeRequest } from '@/app/employee/useCreateEmployeeMutation'
import { UpdateEmployeeMutationRequest } from '@/app/employee/useUpdateEmployeeMutation'

export type EmployeeModalFormFields = CreateEmployeeRequest & UpdateEmployeeMutationRequest

export type EmployeeModalFormProps = {
  readonly mode: 'create' | 'update'
}

export const EmployeeModalForm: React.FC<EmployeeModalFormProps> = ({ mode }) => {
  const { t } = useTranslation('employees')

  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<EmployeeModalFormFields>()

  return (
    <Stack spacing='6'>
      <FormControl id='email' isRequired={mode === 'create'} isInvalid={!!errors.email}>
        <FormLabel size='xs' fontWeight='semibold' color='gray.500'>
          {t('create.email')}
        </FormLabel>
        <Input
          data-testid='email-input'
          type='text'
          isReadOnly={mode === 'update'}
          {...register('email', { required: true })}
        />
        <FormErrorMessage>{t('common:error.fieldRequired')}</FormErrorMessage>
      </FormControl>

      <HStack alignItems='flex-start'>
        <FormControl id='firstName' isRequired isInvalid={!!errors.firstName}>
          <FormLabel size='xs' fontWeight='semibold' color='gray.500'>
            {t('create.firstName')}
          </FormLabel>
          <Input data-testid='first-name-input' type='text' {...register('firstName', { required: true })} />
          <FormErrorMessage>{t('common:error.fieldRequired')}</FormErrorMessage>
        </FormControl>
        <FormControl id='lastName' isRequired isInvalid={!!errors.lastName}>
          <FormLabel size='xs' fontWeight='semibold' color='gray.500'>
            {t('create.lastName')}
          </FormLabel>
          <Input data-testid='last-name-input' type='text' {...register('lastName', { required: true })} />
          <FormErrorMessage>{t('common:error.fieldRequired')}</FormErrorMessage>
        </FormControl>
      </HStack>
      <Controller
        control={control}
        name='role'
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <FormControl isRequired>
            <RadioGroup value={value} onChange={(nextValue: string) => onChange(nextValue)}>
              <FormLabel size='xs' fontWeight='semibold' color='gray.500'>
                {t('create.role')}
              </FormLabel>
              <HStack spacing={4} color='gray.700'>
                <Radio value='concierge' data-testid='concierge-radio'>
                  {t(`role.${EmployeeRole.concierge}`)}
                </Radio>
                <Radio value='admin' data-testid='admin-radio'>
                  {t(`role.${EmployeeRole.admin}`)}
                </Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
        )}
      />
      {mode === 'update' && (
        <FormControl>
          <FormLabel size='xs' fontWeight='semibold' color='gray.500'>
            {t('update.blocked')}
          </FormLabel>
          <Switch id='blocked' colorScheme='red' {...register('blocked')} data-testid='blocked-switch' />
        </FormControl>
      )}
    </Stack>
  )
}
