import * as React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import _range from 'lodash/range'
import { Box, Divider, HStack, IconButton, Switch, Text } from '@chakra-ui/react'
import { AddIcon, ClearIcon } from '@district-qg/ui'

import { CategoryOperatingHoursType } from '@/app/category/Category.model'
import { Day } from '@/app/types/Day'
import { DEFAULT_HOURS } from '@/app/types/operatingHours'
import { CategoryCompleteOperatingHoursFormData } from '@/ui/pages/operatingHours/components/CategoryOperatingHoursFormData'

import { OperatingHoursNormalPeriod } from './OperatingHoursNormalPeriod'

type OperatingHoursNormalHourRowProps = {
  day: Day
  type: CategoryOperatingHoursType
}

export const OperatingHoursNormalHourRow: React.FC<OperatingHoursNormalHourRowProps> = ({ day, type }) => {
  const { t } = useTranslation('operatingHours')

  const { register, trigger, watch } = useFormContext<CategoryCompleteOperatingHoursFormData>()
  const {
    fields: periods,
    append,
    remove,
  } = useFieldArray<CategoryCompleteOperatingHoursFormData>({
    name: `operatingHours.${type}.regular.${day}.periods`,
  })
  const [isOpen] = watch([`operatingHours.${type}.regular.${day}.isOpen`])

  const addPeriod = () => {
    append({ ...DEFAULT_HOURS })
    trigger(`operatingHours.${type}.regular.${day}.periods`)
  }

  return (
    <>
      <Text alignSelf='center' gridColumnStart={1}>
        {t(`day.${day}`)}
      </Text>

      <Switch
        data-testid={`${day}-is-open-switch`}
        alignSelf='center'
        lineHeight='1'
        {...register(`operatingHours.${type}.regular.${day}.isOpen` as const, {
          deps: [`operatingHours.${type}.regular.${day}`],
          onChange: () => {
            if (periods.length > 1) remove(_range(1, periods.length))
          },
        })}
      />

      {periods.map((period, periodIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={period.id}>
          <HStack spacing={1} gridColumnStart={-3}>
            <OperatingHoursNormalPeriod
              type={type}
              day={day}
              periodIndex={periodIndex}
              // eslint-disable-next-line react/no-array-index-key
              key={`${type}-${day}-${periodIndex}`}
            />
          </HStack>
          <Box gridColumnStart={-2} gridColumnEnd={-1} alignSelf='center'>
            {isOpen && periodIndex === 0 && (
              <IconButton
                aria-label={t('common:action.add')}
                icon={<AddIcon boxSize='3' />}
                size='xs'
                variant='outline'
                isRound
                onClick={addPeriod}
                data-testid={`${day}-add-period`}
              />
            )}
            {periodIndex > 0 && (
              <IconButton
                aria-label={t('common:action.delete')}
                icon={<ClearIcon boxSize='3' />}
                size='xs'
                variant='ghost'
                isRound
                onClick={() => remove(periodIndex)}
                data-testid={`${day}-${periodIndex}-remove-period`}
              />
            )}
          </Box>
        </React.Fragment>
      ))}
      <Divider my={1} gridColumnStart={1} gridColumnEnd={-2} />
    </>
  )
}
