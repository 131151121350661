export const configuration = {
  title: 'Configuration',
  email: {
    title: 'Courriels de notification',
    instruction: 'Vous pouvez inscrire plusieurs courriels en les séparant par des virgules.',
    orderConfirmation: 'Confirmation de commande',
    orderError: 'Erreur de relais de commande',
    accounting: 'Rapport de comptabilité',
  },
  links: {
    title: 'Documents légaux',
    termsOfService: 'Conditions d’utilisation',
    privacyPolicy: 'Politique de confidentialité',
    location: 'Emplacement (Google Maps)',
  },
  taxNumber: {
    title: 'Numéros de taxes',
    tps: 'TPS',
    tvq: 'TVQ',
  },
  delivery: {
    title: 'Livraison et paiement',
    deliveryEnabled: 'Livraison disponible',
    deliveryFee: 'Frais de livraison',
    deliveryFeeVeloceKey: 'Item Veloce pour les frais de livraison',
  },
  contactDetails: {
    title: 'Coordonnées',
    phoneNumber: 'Téléphone',
    email: 'Courriel',
    address: 'Adresse',
    mapUrl: 'Lien Google Maps',
    addressNote: 'Note d’adresse',
    openingHours: 'Heures d’ouverture',
  },
  operations: {
    title: 'Opérations',
    orderPreparationTime: {
      label: 'Temps de préparation des commandes',
      unit: 'minutes',
    },
    earliestOrderTime: {
      label: 'Heure à\u00A0partir de\u00A0laquelle le\u00A0système accepte des\u00A0commandes',
      unit: 'hh:mm',
    },
  },
}
