import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton, IconButtonProps } from '@chakra-ui/react'

import { AddIcon } from '../../icons/Icons'

export type ButtonAddProps = Omit<IconButtonProps, 'aria-label'>

export const ButtonAdd: React.FC<ButtonAddProps> = ({ onClick, ...props }) => {
  const { t } = useTranslation()

  return (
    <IconButton
      variant='outline'
      colorScheme='epinard'
      aria-label={t('action.add')}
      rounded='full'
      size='sm'
      icon={<AddIcon />}
      onClick={onClick}
      {...props}
    />
  )
}
