import * as React from 'react'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { FormControl, Stack } from '@chakra-ui/react'

import { Group } from '@/app/group/Group.model'
import { UpdateGroupRequest } from '@/app/group/useUpdateGroupMutation'
import { S3PresignedUrlRequest } from '@/app/media/useCreateS3PresignedUrlMutation'
import { EditModalBody } from '@/ui/components/editModal/EditModal'
import { ImgixImage } from '@/ui/components/image/ImgixImage'
import { ImageUploadDropzone } from '@/ui/components/imageUploadDropzone/ImageUploadDropzone'

export type UpdateGroupImageFormProps = {
  readonly imageId?: Group['imageId']
}

export const UpdateGroupImageForm: React.FC<UpdateGroupImageFormProps> = ({ imageId }) => {
  const { setValue, watch } = useFormContext<UpdateGroupRequest>()
  const s3PresignedUrlRequest: S3PresignedUrlRequest = {
    type: 'main',
    target: 'groups',
  }
  const watchImageId = watch('imageId')
  useEffect(() => {
    if (imageId) {
      setValue('imageId', imageId)
    }
  }, [imageId, setValue])

  return (
    <EditModalBody includeFooter>
      <Stack spacing='6' flex={1} align='center'>
        <ImgixImage
          path='groups/main'
          id={watchImageId}
          backgroundHeight='200px'
          backgroundWidth='200px'
          heightInPixel={200}
          widthInPixel={200}
        />
        <FormControl id='imageUpload'>
          <ImageUploadDropzone
            imageUploadRequest={{
              s3PresignedUrlRequest,
              setImageId: (newImageId: string) => setValue('imageId', newImageId),
            }}
          />
        </FormControl>
      </Stack>
    </EditModalBody>
  )
}
