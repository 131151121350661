import * as React from 'react'

import _includes from 'lodash/includes'
import { Box, Flex, GridItem, Heading, HStack, Spinner, Stack, useDisclosure } from '@chakra-ui/react'
import { AddImageIcon } from '@district-qg/ui'

import { Category } from '@/app/category/Category.model'
import { useCategoriesQuery } from '@/app/category/useCategoriesQuery'
import { UpdateCategoryRequest } from '@/app/category/usePatchCategoryMutation'
import { Group } from '@/app/group/Group.model'
import { useUpdateGroupMutation } from '@/app/group/useUpdateGroupMutation'
import { useItemsQuery } from '@/app/item/useItemsQuery'
import { ImgixImage } from '@/ui/components/image/ImgixImage'
import { ItemEditModal } from '@/ui/pages/items/components/modal/ItemEditModal'
import { ItemListProvider } from '@/ui/pages/items/ItemListContext'

import { UpdateGroupImageModal } from '../update/groups/image/UpdateGroupImageModal'

import { GroupItem } from './GroupItem'

export type GroupViewProps = {
  category: Category
  selectedGroupId: Group['id']
}

export const GroupView: React.FC<GroupViewProps> = ({ category, selectedGroupId }) => {
  const { data: items, isLoading: itemsLoading } = useItemsQuery()
  const { data: categories, isLoading: categoriesLoading } = useCategoriesQuery()

  const {
    isOpen: isOpenUpdateGroupImageModal,
    onOpen: onOpenUpdateGroupImageModal,
    onClose: onCloseUpdateGroupImageModal,
  } = useDisclosure()

  const { mutateAsync } = useUpdateGroupMutation({
    categoryId: category.id,
    groupId: selectedGroupId,
  })
  const mutateGroupImageAsync = (data: UpdateCategoryRequest) => mutateAsync(data)

  const selectedGroup = category.groups.find((x) => x.id === selectedGroupId)

  if (selectedGroup == null) return null
  if (itemsLoading || categoriesLoading) return <Spinner data-testid='spinner' />

  const groupItems = items?.filter((item) => _includes(item.groupIds, selectedGroupId)) ?? []

  return (
    <ItemListProvider items={items} categories={categories}>
      <GridItem gridArea='detailsHeader' data-testid='groupview-header'>
        <HStack align='flex-end'>
          {selectedGroup.imageId ? (
            <Box as='button' onClick={onOpenUpdateGroupImageModal}>
              <ImgixImage
                data-testid='image'
                altText={selectedGroup.name}
                id={selectedGroup.imageId}
                backgroundHeight='80px'
                backgroundWidth='80px'
                heightInPixel={80}
                widthInPixel={80}
                path='groups/main'
              />
            </Box>
          ) : (
            <Flex
              data-testid='image-placeholder'
              w='80px'
              h='80px'
              align='center'
              justify='center'
              background='gray.100'
              borderRadius='5'
              aria-label='upload-image'
              color='steel.200'
              onClick={onOpenUpdateGroupImageModal}
              cursor='pointer'
            >
              <AddImageIcon w='40px' h='40px' />
            </Flex>
          )}
          <Heading size='lg'>{selectedGroup.name.toUpperCase()}</Heading>
        </HStack>
      </GridItem>
      <GridItem gridArea='details'>
        <Stack spacing={1}>
          {groupItems.map((item) => {
            return <GroupItem key={item.id} item={item} />
          })}
        </Stack>
      </GridItem>

      <UpdateGroupImageModal
        imageId={selectedGroup.imageId}
        isOpen={isOpenUpdateGroupImageModal}
        onClose={onCloseUpdateGroupImageModal}
        onSubmit={mutateGroupImageAsync}
      />

      <ItemEditModal />
    </ItemListProvider>
  )
}
