import * as React from 'react'

import { Box } from '@chakra-ui/react'

export const ContentHeader: React.FC = ({ children }) => {
  return (
    <Box bg='steel.50' mx={-8} mt={-8} mb={4} px={8} pt={8} pb={4}>
      {children}
    </Box>
  )
}
