import { UseMutationOptions, useQueryClient } from 'react-query'

import { AxiosError } from 'axios'
import { useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { QGDto } from '@/app/qg/QG.dto'
import { QG_QUERY_KEY } from '@/app/qg/useQGQuery'
import { SectionDto } from '@/app/section/Section.dto'
import { useQGId } from '@/environment'

export type CreateSectionRequest = Omit<SectionDto, 'id'> & {
  zoneId: string
}

export const useCreateSectionMutation = (
  options: UseMutationOptions<QGDto, AxiosError, CreateSectionRequest>
): UseMutationExtendedResult<QGDto, CreateSectionRequest> => {
  const queryClient = useQueryClient()
  const qgId = useQGId()

  return useMutation<QGDto, CreateSectionRequest>({
    ...options,
    expectedStatus: 201,
    path: `/v1/admin/qgs/${qgId}/sections`,
    method: 'post',
    requireAuthentication: true,
    onSuccess: async (errors, variables, context) => {
      await queryClient.invalidateQueries(QG_QUERY_KEY)
      /* istanbul ignore next */
      return options?.onSuccess?.(errors, variables, context)
    },
  })
}
