import _mapValues from 'lodash/mapValues'

import { ItemOperatingHours } from '@/app/item/Item.model'
import { Day } from '@/app/types/Day'
import { DEFAULT_HOURS, OperatingHoursSchedule } from '@/app/types/operatingHours'

export type ItemOperatingHoursFormData = {
  regular: Record<
    Day,
    {
      isOpen: boolean
      periods: Array<OperatingHoursSchedule>
    }
  >
}

export const DEFAULT_ITEM_OPERATING_HOURS: ItemOperatingHours = {
  regular: {
    [Day.MONDAY]: [],
    [Day.TUESDAY]: [],
    [Day.WEDNESDAY]: [],
    [Day.THURSDAY]: [],
    [Day.FRIDAY]: [],
    [Day.SATURDAY]: [],
    [Day.SUNDAY]: [],
  },
}

export function toItemOperatingHoursFormData(operatingHours: ItemOperatingHours | null): ItemOperatingHoursFormData {
  const myOperatingHours = operatingHours ?? DEFAULT_ITEM_OPERATING_HOURS
  const regular = _mapValues(myOperatingHours.regular, (schedule) =>
    schedule.length ? { isOpen: true, periods: schedule } : { isOpen: false, periods: [{ ...DEFAULT_HOURS }] }
  )

  return { regular }
}

export const toItemOperatingHours = (
  itemSingleOperatingHoursFormData: ItemOperatingHoursFormData
): ItemOperatingHours => {
  const regular = _mapValues(itemSingleOperatingHoursFormData.regular, (schedule) =>
    schedule.isOpen ? schedule.periods : []
  )

  return { regular }
}
