import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormControl, FormLabel, Heading, Input, Stack, Textarea } from '@chakra-ui/react'

import { Card, CardContent, CardHeader } from '@/ui/components/card/Card'
import { QGConfigurationFormData } from '@/ui/pages/configuration/components/QGConfigurationFormData'

export const QGConfigurationFormContactDetailsCard: React.FC = () => {
  const { t } = useTranslation('configuration')
  const { register } = useFormContext<QGConfigurationFormData>()

  return (
    <Card backgroundColor='gray.50'>
      <CardHeader>
        <Heading fontSize='2xl' fontWeight='normal'>
          {t('contactDetails.title')}
        </Heading>
      </CardHeader>
      <CardContent pb={8}>
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>{t('contactDetails.phoneNumber')}</FormLabel>
            <Input
              {...register('customerService.phoneNumber')}
              type='text'
              bg='white'
              data-testid='customerService-phoneNumber'
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t('contactDetails.address')}</FormLabel>
            <Input
              {...register('address.streetAddress.fr')}
              type='text'
              bg='white'
              data-testid='address-streetAddress'
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t('contactDetails.mapUrl')}</FormLabel>
            <Input {...register('address.mapUrl.fr')} type='text' bg='white' data-testid='address-mapUrl' />
          </FormControl>
          <FormControl>
            <FormLabel>{t('contactDetails.addressNote')}</FormLabel>
            <Textarea {...register('address.note.fr')} type='text' bg='white' data-testid='address-note' />
          </FormControl>
          <FormControl>
            <FormLabel>{t('contactDetails.email')}</FormLabel>
            <Input {...register('customerService.email')} type='email' bg='white' data-testid='customerService-email' />
          </FormControl>
          <FormControl>
            <FormLabel>{t('contactDetails.openingHours')}</FormLabel>
            <Textarea {...register('openingHours.fr')} bg='white' data-testid='openingHours' />
          </FormControl>
        </Stack>
      </CardContent>
    </Card>
  )
}
