import { useModelBuilderWithCurrentLocale, useQuery, UseQueryExtendedResult } from '@district-qg/frontend-common'

import { OrderDto } from '@/app/order/Order.dto'
import { Order, OrderModel } from '@/app/order/Order.model'

export const ORDER_QUERY_KEY = 'order'

export const useOrderQuery = (id?: string): UseQueryExtendedResult<Order, OrderDto> => {
  const mapper = useModelBuilderWithCurrentLocale(
    (orderDto: Readonly<OrderDto>, locale) => new OrderModel(orderDto, locale)
  )

  return useQuery({
    queryKey: [ORDER_QUERY_KEY, id],
    path: `/v1/admin/orders/${id}`,
    mapper,
    enabled: !!id,
  })
}
