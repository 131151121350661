import { useQueryClient } from 'react-query'

import { UseAxiosMutationOptions, useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { Category } from '@/app/category/Category.model'
import { CATEGORIES_QUERY_KEY } from '@/app/category/useCategoriesQuery'
import { CATEGORY_QUERY_KEY } from '@/app/category/useCategoryQuery'
import { GroupDto } from '@/app/group/Group.dto'
import { CATEGORY_GROUPS_QUERY_KEY } from '@/app/group/useGroupListQuery'

export type UpdateUpdateGroupListRequest = Array<Partial<GroupDto>>

export type UseUpdateGroupListMutationProps = UseAxiosMutationOptions<void, UpdateUpdateGroupListRequest> & {
  categoryId: Category['id']
}
export const useUpdateGroupListMutation = ({
  categoryId,
  onSuccess,
  ...options
}: UseUpdateGroupListMutationProps): UseMutationExtendedResult<void, UpdateUpdateGroupListRequest> => {
  const queryClient = useQueryClient()
  return useMutation<void, UpdateUpdateGroupListRequest>({
    method: 'put',
    path: `/v1/admin/categories/${categoryId}/groups`,
    ...options,
    onSuccess: async (data, variables, context) => {
      await Promise.all([
        queryClient.invalidateQueries(CATEGORIES_QUERY_KEY),
        queryClient.invalidateQueries(CATEGORY_QUERY_KEY(categoryId)),
      ])
      queryClient.removeQueries(CATEGORY_GROUPS_QUERY_KEY(categoryId))
      return onSuccess?.(data, variables, context)
    },
  })
}
