import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormLabel,
  InputRightElement,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react'

import { Order } from '@/app/order/Order.model'
import { useRefundOrderMutation } from '@/app/order/useRefundOrderMutation'
import { getToastConfiguration } from '@/ui/components/toast/getToastConfiguration'

type RefundOrderDialogProps = {
  isOpen: boolean
  onClose(): void
  orderId: Order['id']
  maxAmount: number
}

export const RefundOrderDialog: React.FC<RefundOrderDialogProps> = ({ isOpen, onClose, orderId, maxAmount }) => {
  const { t } = useTranslation('orders')
  const toast = useToast()
  const dialogRef = React.useRef(null)
  const [amount, setAmount] = React.useState('')
  const [reason, setReason] = React.useState('')

  const handleClose = () => {
    onClose()
    setAmount('')
    setReason('')
  }

  const handleReasonInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value
    setReason(inputValue)
  }

  const { mutate: refundOrder, isLoading } = useRefundOrderMutation({
    onSuccess: () => {
      onClose()
    },
    onError: () => {
      onClose()
      toast(getToastConfiguration(t('refund.error.title'), t('refund.error.description'), 'error'))
    },
  })

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={dialogRef} onClose={handleClose}>
      <AlertDialogOverlay>
        <AlertDialogContent top='1rem'>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {t('refund.dialog.title')}
          </AlertDialogHeader>
          <AlertDialogBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel fontSize='sm' mb={1}>
                  {t('refund.dialog.amount.label', { amount: maxAmount })}
                </FormLabel>
                <NumberInput
                  size='sm'
                  maxWidth='6rem'
                  precision={2}
                  min={0}
                  max={maxAmount}
                  value={amount}
                  onChange={(valueAsString) => setAmount(valueAsString)}
                >
                  <NumberInputField data-testid='amount-input' />
                  <InputRightElement>$</InputRightElement>
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel fontSize='sm' mb={1}>
                  {t('refund.dialog.reason.label')}
                </FormLabel>
                <Textarea rows={2} size='sm' onChange={handleReasonInputChange} data-testid='reason-input' />
              </FormControl>

              <Text fontSize='xs' color='red'>
                {t('refund.dialog.warning')}
              </Text>
            </Stack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={dialogRef} onClick={handleClose} data-testid='cancel-button'>
              {t('common:action.cancel')}
            </Button>
            <Button
              colorScheme='red'
              onClick={() => {
                refundOrder({ orderId, reason, amount: Number(amount) })
              }}
              ml={3}
              isLoading={isLoading}
              disabled={reason.trim() === '' || Number(amount) <= 0}
              data-testid='confirm-button'
            >
              {t('common:action.confirm')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
