import {
  UseAxiosQueryOptions,
  useModelBuilderWithCurrentLocale,
  useQuery,
  UseQueryExtendedResult,
} from '@district-qg/frontend-common'

import { Category } from '@/app/category/Category.model'
import { CATEGORY_QUERY_KEY } from '@/app/category/useCategoryQuery'
import { GroupDto } from '@/app/group/Group.dto'
import { buildModel, Group } from '@/app/group/Group.model'

export const CATEGORIES_QUERY_KEY = 'group'

export type UseGroupListQueryProps = UseAxiosQueryOptions<GroupDto[]> & {
  categoryId: Category['id']
}

export const CATEGORY_GROUPS_QUERY_KEY = (id: Category['id']): [string, string, string] => [
  ...CATEGORY_QUERY_KEY(id),
  'groups',
]

export const useGroupListQuery = ({
  categoryId,
  ...options
}: UseGroupListQueryProps): UseQueryExtendedResult<ReadonlyArray<Group>, GroupDto[]> => {
  const mapper = useModelBuilderWithCurrentLocale(buildModel)
  return useQuery({
    queryKey: CATEGORY_GROUPS_QUERY_KEY(categoryId),
    path: `/v1/admin/categories/${categoryId}/groups`,
    ...options,
    mapper,
  })
}
