import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Center, Heading, HStack, Spacer, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { OrderPaymentStatus } from '@/app/order/Order.model'
import { useOrderQuery } from '@/app/order/useOrderQuery'
import {
  EditModalActions,
  EditModalBody,
  EditModalHeader,
  EditModalHeaderSideBar,
  EditModalSideBar,
} from '@/ui/components/editModal/EditModal'
import { OrderStatusBadge } from '@/ui/pages/orders/components/OrderStatusBadge'
import { OrderCustomerInfo } from '@/ui/pages/orders/components/orderViewModal/OrderCustomerInfo'
import { OrderInvoiceInfo } from '@/ui/pages/orders/components/orderViewModal/OrderInvoiceInfo'
import { OrderItemsInfo } from '@/ui/pages/orders/components/orderViewModal/OrderItemsInfo'
import { OrderTransactionInfo } from '@/ui/pages/orders/components/orderViewModal/OrderTransactionInfo'
import { RefundOrderDialog } from '@/ui/pages/orders/components/orderViewModal/RefundOrderDialog'
import { UpdateOrderNumberForm } from '@/ui/pages/orders/components/orderViewModal/UpdateOrderNumberForm'

type OrderViewModalBodyProps = {
  readonly id?: string
}

export const OrderViewModalBody: React.FC<OrderViewModalBodyProps> = ({ id }) => {
  const { t } = useTranslation('orders')
  const { isOpen: isRefundDialogOpen, onClose: onCloseRefundDialog, onOpen: onOpenRefundDialog } = useDisclosure()

  const { data: order, isLoading } = useOrderQuery(id)

  if (!id) {
    return null
  }

  if (isLoading) {
    return (
      <Center height={96}>
        <Spinner size='xl' />
      </Center>
    )
  }

  if (!order) {
    return (
      <Box height={96}>
        <Heading size='lg' fontWeight='medium' padding={4}>
          {t('modalContent.notFoundHeader')}
        </Heading>
        <Spacer />
        <Text paddingLeft={4} fontWeight='medium'>
          {t('modalContent.notFoundContent')}
        </Text>
      </Box>
    )
  }

  const {
    creationTime,
    customer,
    items,
    transactions,
    receptionMode,
    expectedDateTime,
    invoice,
    posStatus,
    paymentStatus,
    orderNumber,
  } = order

  return (
    <>
      <EditModalHeader>
        <HStack alignItems='baseline' spacing={4} mb={2}>
          <Heading size='lg' fontWeight='medium'>
            {t('modalContent.header.command')}
          </Heading>
          <Text fontSize='sm'>{t('modalContent.header.createdAt', { date: creationTime })}</Text>
        </HStack>
        <HStack mb={4} spacing={4}>
          {paymentStatus && (
            <HStack spacing={1}>
              <Text fontSize='xs'>{t('tableContent.columns.paymentStatus')}</Text>
              <OrderStatusBadge status={paymentStatus} />
            </HStack>
          )}
          {posStatus && (
            <HStack spacing={1}>
              <Text fontSize='xs'>{t('tableContent.columns.posStatus')}</Text>
              <OrderStatusBadge status={posStatus} />
            </HStack>
          )}
        </HStack>
      </EditModalHeader>
      <EditModalBody includeFooter>
        <Stack spacing={8}>
          <OrderCustomerInfo customer={customer} />
          <OrderItemsInfo items={items} />
          <OrderTransactionInfo transactions={transactions} />
        </Stack>
      </EditModalBody>
      <EditModalHeaderSideBar>
        <Text fontSize='xl' textTransform='capitalize'>
          {t(`modalContent.${receptionMode}`)}
        </Text>
        <Text fontSize='xl'>{t('modalContent.expectedDateTime', { date: expectedDateTime })}</Text>
      </EditModalHeaderSideBar>
      <EditModalSideBar>
        <Box maxWidth={56} marginY={10}>
          {orderNumber ? (
            <Text fontWeight='semibold'>{`${t('modalContent.header.command')} #${orderNumber}`}</Text>
          ) : (
            <UpdateOrderNumberForm id={id} />
          )}
          <OrderInvoiceInfo invoice={invoice} />
        </Box>
      </EditModalSideBar>
      {paymentStatus === OrderPaymentStatus.paid && !!order.totalMinusRefunds && (
        <EditModalActions>
          <ButtonQg
            buttonType='secondary'
            colorScheme='blue'
            onClick={onOpenRefundDialog}
            data-testid='refundOrderButton'
            size='sm'
          >
            {t('refund.title')}
          </ButtonQg>
          <RefundOrderDialog
            orderId={id}
            maxAmount={order.totalMinusRefunds}
            isOpen={isRefundDialogOpen}
            onClose={onCloseRefundDialog}
          />
        </EditModalActions>
      )}
    </>
  )
}
