import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, Heading, UseModalProps, useToast } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { UpdateSectionRequest, useUpdateSectionMutation } from '@/app/qg/useUpdateSectionMutation'
import { Section } from '@/app/section/Section.model'
import { EditModal, EditModalActions, EditModalBody, EditModalHeader } from '@/ui/components/editModal/EditModal'
import { SectionModalForm } from '@/ui/pages/sections/components/modals/common/SectionModalForm'

export type UpdateSectionModalProps = UseModalProps & {
  restaurantZoneId: string
  section: Section
}

export const UpdateSectionModal: React.FC<UpdateSectionModalProps> = ({ isOpen, onClose, section }) => {
  const { t } = useTranslation(['sections', 'common'])
  const formMethods = useForm<UpdateSectionRequest>({
    defaultValues: {
      name: { fr: section.name },
      imageId: section.imageId,
    },
    mode: 'onChange',
  })
  const { handleSubmit, reset, formState } = formMethods
  const onCloseModal = () => {
    onClose()
    reset()
  }
  const toast = useToast()

  const { isLoading, mutateAsync: updateSection } = useUpdateSectionMutation({
    sectionId: section.id,
    onSuccess: () => {
      onCloseModal()
    },
    onError: () => {
      toast({
        title: t('update.error.title'),
        description: t('update.error.description'),
        status: 'error',
        isClosable: true,
        duration: 9000,
      })
    },
  })

  const updateSectionAction = async (updateSectionRequest: UpdateSectionRequest) => {
    await updateSection(updateSectionRequest)
  }

  return (
    <EditModal isOpen={isOpen} onClose={onClose} sideBarColor='steel.50'>
      <EditModalHeader>
        <Heading size='lg' fontWeight='medium' pb={4}>
          {t('update.modalTitle')}
        </Heading>
      </EditModalHeader>
      <FormProvider {...formMethods}>
        <EditModalBody>
          <SectionModalForm />
        </EditModalBody>
      </FormProvider>
      <EditModalActions>
        <Flex alignItems='center' justifyContent='flex-start'>
          <ButtonQg
            data-testid='submit-button'
            buttonType='primary'
            bg='steel.base'
            isLoading={isLoading}
            onClick={handleSubmit(updateSectionAction)}
            disabled={!formState.isValid}
          >
            {t('common:action.save')}
          </ButtonQg>
        </Flex>
      </EditModalActions>
    </EditModal>
  )
}
