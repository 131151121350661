import * as React from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Flex, Heading } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { useCreateCategoryMutation } from '@/app/category/useCreateCategoryMutation'
import { useQGQuery } from '@/app/qg/useQGQuery'
import { EditModal, EditModalActions, EditModalHeader } from '@/ui/components/editModal/EditModal'
import { CategoryModalForm } from '@/ui/pages/categories/modal/CategoryModalForm'

import { mapFieldValuesToRequest } from './mapFieldValuesToRequest'

export type CreateCategoryModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const CreateCategoryModal: React.FC<CreateCategoryModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation(['categories', 'common'])
  const formMethods = useForm()
  const { handleSubmit, reset } = formMethods
  const { data: qg } = useQGQuery()
  const { isLoading, mutateAsync } = useCreateCategoryMutation()
  const history = useHistory()
  const closeModal = () => {
    onClose()
    reset()
  }
  if (!qg) {
    return null
  }

  const createCategory = async (fieldValues: FieldValues) => {
    try {
      const { id } = await mutateAsync(mapFieldValuesToRequest(qg.id, fieldValues))
      history.push(`/categories/${id}`)
    } finally {
      closeModal()
    }
  }

  return (
    <EditModal isOpen={isOpen} onClose={closeModal} sideBarColor='white'>
      <EditModalHeader>
        <Heading size='lg' fontWeight='medium' pb={4}>
          {t('create.modalTitle')}
        </Heading>
      </EditModalHeader>
      <CategoryModalForm formMethods={formMethods} sections={qg.sections} />
      <EditModalActions>
        <Flex alignItems='center' justifyContent='flex-start'>
          <ButtonQg
            data-testid='submit-button'
            buttonType='primary'
            bg='steel.base'
            isLoading={isLoading}
            onClick={handleSubmit(createCategory)}
          >
            {t('common:action.save')}
          </ButtonQg>
        </Flex>
      </EditModalActions>
    </EditModal>
  )
}
