import {
  QGFeatureConfigurationDto,
  UseAxiosQueryOptions,
  useModelBuilderWithCurrentLocale,
  useQuery,
  UseQueryExtendedResult,
} from '@district-qg/frontend-common'

import { QGDto } from '@/app/qg/QG.dto'
import { buildQGModel, QG } from '@/app/qg/QG.model'
import { QGConfigurationDto } from '@/app/qg/QGConfiguration.dto'
import { useQGId } from '@/environment'

export const QG_QUERY_KEY = 'qgs'

export type QGQueryDto = QGDto & QGConfigurationDto & QGFeatureConfigurationDto

export const useQGQuery = (options?: UseAxiosQueryOptions<QGQueryDto>): UseQueryExtendedResult<QG, QGQueryDto> => {
  const mapper = useModelBuilderWithCurrentLocale(buildQGModel)
  const id = useQGId()
  return useQuery({
    queryKey: QG_QUERY_KEY,
    path: `/v1/admin/qgs/${id}`,
    mapper,
    ...options,
  })
}
