import { FieldValues } from 'react-hook-form'

import { UpdateCategoryRequest } from '@/app/category/usePatchCategoryMutation'

export const mapFieldValuesToRequest = (fieldValues: FieldValues): UpdateCategoryRequest => ({
  ...fieldValues,
  title: {
    fr: fieldValues.title,
    en: fieldValues.title,
  },
  subtitle: {
    fr: fieldValues.subtitle,
    en: fieldValues.subtitle,
  },
  description: {
    fr: fieldValues.description,
    en: fieldValues.description,
  },
})
