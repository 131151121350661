import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { SectionDto } from './Section.dto'

export interface Section {
  readonly id: string
  readonly name: string
  readonly imageId?: string
}

export class SectionModel implements Section {
  constructor(private readonly data: Readonly<SectionDto>, private readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get name(): string {
    return localize(this.data.name, this.locale)
  }

  get imageId(): string | undefined {
    return this.data.imageId
  }
}

export function buildSectionModel(data: SectionDto, locale: SupportedLocales): Section {
  return new SectionModel(data, locale)
}
