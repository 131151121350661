import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormControl, FormLabel, Heading, Input, InputGroup, InputRightAddon, Stack, Switch } from '@chakra-ui/react'

import { Card, CardContent, CardHeader } from '@/ui/components/card/Card'
import { QGConfigurationFormData } from '@/ui/pages/configuration/components/QGConfigurationFormData'

export const QGConfigurationFormDeliveryCard: React.FC = () => {
  const { t } = useTranslation('configuration')
  const { register } = useFormContext<QGConfigurationFormData>()

  return (
    <Card backgroundColor='gray.50'>
      <CardHeader>
        <Heading fontSize='2xl' fontWeight='normal'>
          {t('delivery.title')}
        </Heading>
      </CardHeader>
      <CardContent pb={8}>
        <Stack spacing={4}>
          <FormControl>
            <FormLabel htmlFor='deliveryEnabled'>{t('delivery.deliveryEnabled')}</FormLabel>
            <Switch id='deliveryEnabled' {...register('delivery.enabled')} data-testid='delivery-enabled' />
          </FormControl>
          <FormControl>
            <FormLabel>{t('delivery.deliveryFee')}</FormLabel>
            <InputGroup>
              <Input
                {...register('delivery.fees', { valueAsNumber: true })}
                type='number'
                min='0'
                maxW='10rem'
                bg='white'
                data-testid='delivery-fees'
              />
              <InputRightAddon>$</InputRightAddon>
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>{t('delivery.deliveryFeeVeloceKey')}</FormLabel>
            <Input
              {...register('delivery.item', { valueAsNumber: true })}
              type='number'
              maxW='10rem'
              bg='white'
              data-testid='delivery-item'
            />
          </FormControl>
        </Stack>
      </CardContent>
    </Card>
  )
}
