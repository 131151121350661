import * as React from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { EditModal } from '@/ui/components/editModal/EditModal'
import { useOrderView } from '@/ui/pages/orders/components/OrderViewContext'
import { OrderViewModalBody } from '@/ui/pages/orders/components/orderViewModal/OrderViewModalBody'

export const OrderViewModal: React.FC = () => {
  const { orderId, close } = useOrderView()
  const { isOpen, onClose } = useDisclosure({ isOpen: !!orderId, onClose: close })

  return (
    <EditModal isOpen={isOpen} onClose={onClose} sideBarColor='steel.50'>
      <OrderViewModalBody id={orderId} />
    </EditModal>
  )
}
