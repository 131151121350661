import * as React from 'react'

import { Box, BoxProps, Flex, useColorModeValue } from '@chakra-ui/react'

export const Card: React.FC<BoxProps> = (props) => (
  <Flex
    direction='column'
    bg={useColorModeValue('white', 'gray.700')}
    border='1px'
    borderColor='gray.100'
    rounded='base'
    overflow='hidden'
    {...props}
  />
)

export const CardHeader: React.FC<BoxProps> = (props) => <Box p='4' borderBottomWidth='1px' {...props} />

export const CardContent: React.FC<BoxProps> = (props) => <Box flex='1' p='4' {...props} />
