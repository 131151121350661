import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton } from '@chakra-ui/react'
import { ClearIcon } from '@district-qg/ui'

export type CloseButtonProps = {
  onClick: () => void
}

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <IconButton
      colorScheme='steel'
      aria-label={t('action.close')}
      rounded='full'
      size='xs'
      margin={2}
      icon={<ClearIcon boxSize='4' />}
      onClick={onClick}
      bg='steel.700'
      data-testid='closeButton'
    />
  )
}
