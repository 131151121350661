import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@chakra-ui/react'
import { AirplaneIcon, ConfigurationIcon, ListCheckIcon, UserIcon } from '@district-qg/ui'

import { useGetRoles } from '@/app/role/UserRolesProvider'
import { CONFIGURATION_PATH, EMPLOYEES_PATH, ITEMS_PATH, SECTIONS_PATH } from '@/routes'
import { OrdersSidebarLink } from '@/ui/layout/components/sidebar/OrdersSidebarLink'
import { CategoriesListWired } from '@/ui/pages/categories/index/CategoriesListWired'

import { SidebarLink } from './SidebarLink'

export const SidebarNav: React.FC = () => {
  const { t } = useTranslation()
  const { isAdmin } = useGetRoles()

  return (
    <>
      <OrdersSidebarLink />
      {isAdmin && (
        <>
          <SidebarLink icon={<ListCheckIcon fontSize='24' color='steel.300' />} to={ITEMS_PATH} text={t('nav.items')} />
          <SidebarLink
            icon={<AirplaneIcon fontSize='12' color='steel.300' transform='scaleX(-1)' />}
            to={SECTIONS_PATH}
            text={t('nav.sections')}
          />
          <SidebarLink
            icon={<ConfigurationIcon fontSize='16' color='steel.300' />}
            to={CONFIGURATION_PATH}
            text={t('nav.configuration')}
          />
          <SidebarLink
            icon={<UserIcon fontSize='14' color='steel.300' />}
            to={EMPLOYEES_PATH}
            text={t('nav.employees')}
          />
          <Box paddingStart='3'>
            <CategoriesListWired />
          </Box>
        </>
      )}
    </>
  )
}
