import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'

type ConfirmDeleteRowProps = {
  title: string
  isOpen: boolean
  onClose(): void
  onConfirm(): void
}

export const ConfirmDeleteRow: React.FC<ConfirmDeleteRowProps> = ({ title, isOpen, onClose, onConfirm, children }) => {
  const { t } = useTranslation()
  const cancelRef = React.useRef(null)

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>{children}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} data-testid='delete-row-cancel'>
              {t('action.cancel')}
            </Button>
            <Button
              colorScheme='red'
              onClick={() => {
                onConfirm()
                onClose()
              }}
              ml={3}
              data-testid='delete-row-confirm'
            >
              {t('action.delete')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
