import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Flex, Grid, Spacer, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'

import { Order } from '@/app/order/Order.model'
import { OrderTransactionType } from '@/app/order/OrderEvent.dto'

type OrderTransactionInfoProps = {
  readonly transactions: Order['transactions']
}

export const OrderTransactionInfo: React.FC<OrderTransactionInfoProps> = ({ transactions }) => {
  const { t } = useTranslation('orders')

  return (
    <Table borderWidth='1px' fontSize='sm'>
      <Thead bg='steel.500'>
        <Tr>
          <Th color='white' textAlign='center' fontSize={14}>
            {t('transactionDetails.title')}
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {transactions.map((transaction) => (
          <Tr key={transaction.transactionNumber} verticalAlign='top'>
            <Td px={4}>
              <Flex alignItems='flex-start'>
                <Box>
                  <Text fontWeight='bold'>
                    {transaction.type === OrderTransactionType.ERROR
                      ? t('transactionDetails.error')
                      : t('price', { value: transaction.amount })}
                  </Text>
                  <Text fontSize='xs'>{t('transactionDetails.date', { date: transaction.date })}</Text>
                  {transaction.user && <Text fontSize='xs'>{transaction.user}</Text>}
                </Box>
                <Spacer />
                <Grid templateColumns='auto auto' fontSize='xs' columnGap={2}>
                  {transaction.transactionNumber && (
                    <>
                      <Text textAlign='right'>{t('transactionDetails.transactionNumber')}</Text>
                      <Text textAlign='right' fontFamily='monospace'>
                        {transaction.transactionNumber}
                      </Text>
                    </>
                  )}
                  {transaction.invoiceNumber && (
                    <>
                      <Text textAlign='right'>{t('transactionDetails.invoiceNumber')}</Text>
                      <Text textAlign='right' fontFamily='monospace'>
                        {transaction.invoiceNumber}
                      </Text>
                    </>
                  )}
                </Grid>
              </Flex>
              {transaction.reason && <Text mt={2}>{transaction.reason}</Text>}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
