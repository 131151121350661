import React, { useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { DisruptiveModal } from '@/ui/components/disruptiveModal/DisruptiveModal'
import { LabeledControlledSwitch, LabeledSwitchProps } from '@/ui/components/switch/LabeledSwitch'
import { ControlledSwitch, ControlledSwitchProps } from '@/ui/components/switch/Switch'

type AbstractSwitchWithDisruptiveProps = Omit<ControlledSwitchProps, 'state' | 'onChange' | 'isDisabled'> & {
  switchComponent: React.FC<ControlledSwitchProps>
  onConfirm: (newValue: boolean) => Promise<unknown>
  initialValue?: boolean
  disruptiveTitle?: string
  disruptiveMessage?: string
  disruptiveErrorMessage?: string
}
const AbstractSwitchWithDisruptive: React.FC<AbstractSwitchWithDisruptiveProps> = ({
  id,
  switchComponent,
  onConfirm,
  initialValue,
  disruptiveTitle,
  disruptiveMessage,
  disruptiveErrorMessage,
  ...rest
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [state, setState] = useState(initialValue ?? false)
  const toggle = () => setState(!state)

  React.useEffect(() => {
    setState(initialValue ?? false)
  }, [initialValue])

  return (
    <>
      {switchComponent({ id, state, onChange: onOpen, ...rest })}
      <DisruptiveModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => onConfirm(!state)}
        onSuccess={toggle}
        title={disruptiveTitle}
        message={disruptiveMessage}
        errorMessage={disruptiveErrorMessage}
      />
    </>
  )
}

export type SwitchWithDisruptiveProps = Omit<AbstractSwitchWithDisruptiveProps, 'switchComponent'>
export const SwitchWithDisruptive: React.FC<SwitchWithDisruptiveProps> = (props) => (
  <AbstractSwitchWithDisruptive switchComponent={ControlledSwitch} {...props} />
)

export type LabeledSwitchWithDisruptiveProps = Omit<AbstractSwitchWithDisruptiveProps, 'switchComponent'> &
  LabeledSwitchProps
export const LabeledSwitchWithDisruptive: React.FC<LabeledSwitchWithDisruptiveProps> = (props) => (
  <AbstractSwitchWithDisruptive
    switchComponent={(switchProps) => <LabeledControlledSwitch {...props} {...switchProps} />}
    {...props}
  />
)
