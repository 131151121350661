import React from 'react'
import { useTranslation } from 'react-i18next'

import { HStack, Stack, Text } from '@chakra-ui/react'

import { OrderInvoice } from '@/app/order/OrderInvoice.model'

type OrderInvoiceInfoProps = {
  readonly invoice: OrderInvoice
}

export const OrderInvoiceInfo: React.FC<OrderInvoiceInfoProps> = ({ invoice }) => {
  const { t } = useTranslation('orders')
  const { subTotalPrice, delivery, gratuity, totalPrice, taxLines } = invoice

  return (
    <Stack marginTop={8} maxWidth={52}>
      <HStack justifyContent='space-between'>
        <Text>{t('modalContent.subTotal')}</Text>
        <Text>{t('common:price', { value: subTotalPrice })}</Text>
      </HStack>
      {delivery.fees > 0 && (
        <HStack justifyContent='space-between'>
          <Text>{t('modalContent.deliveryFees')}</Text>
          <Text>{t('common:price', { value: delivery.fees })}</Text>
        </HStack>
      )}
      {taxLines.map((line) => (
        <HStack justifyContent='space-between' key={line.title}>
          <Text>{line.title}</Text>
          <Text>{t('common:price', { value: line.value })}</Text>
        </HStack>
      ))}
      <HStack justifyContent='space-between'>
        <Text>{t('modalContent.gratuity')}</Text>
        <Text>{t('common:price', { value: gratuity?.amount ?? 0 })}</Text>
      </HStack>
      <HStack justifyContent='space-between'>
        <Text fontWeight='semibold'>{t('modalContent.total')}</Text>
        <Text fontWeight='semibold'>{t('common:price', { value: totalPrice })}</Text>
      </HStack>
    </Stack>
  )
}
