import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import _toNumber from 'lodash/toNumber'
import { Input, Stack } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { useOrderNumberUpdateMutation } from '@/app/order/useOrderNumberUpdateMutation'

export type UpdateOrderNumberFormProps = {
  readonly id: string
}

export const UpdateOrderNumberForm: React.FC<UpdateOrderNumberFormProps> = ({ id }) => {
  const { t } = useTranslation('orders')
  const { mutate } = useOrderNumberUpdateMutation(id)
  const [orderNumber, setOrderNumber] = useState<string>('')
  const onClick = async () => mutate({ orderNumber: _toNumber(orderNumber) })

  return (
    <Stack>
      <Input
        data-testid='input-field'
        backgroundColor='white'
        type='number'
        value={orderNumber}
        placeholder={t('modalContent.veloceOrderNumberFormPlaceholder')}
        onChange={(event) => setOrderNumber(event.target.value)}
        size='sm'
      />
      <ButtonQg
        data-testid='submit-button'
        disabled={!orderNumber}
        maxWidth={32}
        buttonType='primary'
        colorScheme='blue'
        onClick={onClick}
        size='sm'
      >
        {t('modalContent.confirm')}
      </ButtonQg>
    </Stack>
  )
}
