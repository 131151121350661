import React from 'react'
import { useParams } from 'react-router-dom'

import { Center, Spinner } from '@chakra-ui/react'

import { useCategoryQuery } from '@/app/category/useCategoryQuery'
import { useQGQuery } from '@/app/qg/useQGQuery'
import { CategoryView } from '@/ui/pages/categories/read/CategoryView'

export const CategoryPage: React.FC = () => {
  const { categoryId } = useParams<{ categoryId: string }>()
  const { isLoading: categoryIsLoading, data: category } = useCategoryQuery({ categoryId })
  const { isLoading: qgIsLoading, data: qg } = useQGQuery()

  if (categoryIsLoading || !category || qgIsLoading || !qg) {
    return (
      <Center h='100vh'>
        <Spinner size='xl' data-testid='spinner' />
      </Center>
    )
  }

  return <CategoryView category={category} qg={qg} />
}
