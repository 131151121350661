import React from 'react'
import { useTranslation } from 'react-i18next'

import _map from 'lodash/map'
import { Box, BoxProps, Divider, Link, Stack } from '@chakra-ui/react'

import { Category } from '@/app/category/Category.model'
import { Group } from '@/app/group/Group.model'
import { GroupsListItem } from '@/ui/pages/categories/read/GroupsListItem'

export type GroupsListProps = {
  category: Category
  openUpdateGroupsModal: () => void
  selectedGroupId: Group['id']
  setSelectedGroupId: (groupId: Group['id']) => void
} & BoxProps

export const GroupsList: React.FC<GroupsListProps> = ({
  category,
  openUpdateGroupsModal,
  selectedGroupId,
  setSelectedGroupId,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Stack w='full' borderRadius='base' borderWidth='1px' spacing={0} divider={<Divider />} {...rest}>
      {_map(category.groups, (group) => {
        return (
          <GroupsListItem
            key={group.id}
            categoryId={category.id}
            group={group}
            isSelected={selectedGroupId === group.id}
            setSelected={() => setSelectedGroupId(group.id)}
          />
        )
      })}
      <Box alignItems='center' px={2} pb={1}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          data-testid='edit-groups-list-link'
          color='blue.400'
          fontWeight='bold'
          fontSize='xs'
          _hover={{ textDecoration: 'none' }}
          onClick={() => {
            setSelectedGroupId('')
            openUpdateGroupsModal()
          }}
        >
          {t('action.editList')}
        </Link>
      </Box>
    </Stack>
  )
}
