export const common = {
  action: {
    add: 'Ajouter',
    remove: 'Effacer',
    cancel: 'Annuler',
    close: 'Fermer',
    confirm: 'Confirmer',
    delete: 'Effacer',
    edit: 'Modifier',
    editList: 'Modifier la liste',
    save: 'Sauvegarder',
    selectOrDragImage: 'Cliquez pour sélectionner ou glissez une image',
    synchronize: 'Synchroniser',
    synchronizeInProgress: 'En cours...',
    lastItemsSyncDateTime: 'Effectué à ',
  },
  price: '{{ value, currency }}',
  active: 'Actif',
  app: {
    title: 'District Gourmet – Administration',
  },
  artisans: {
    form: {
      bio: 'Biographie',
      name: 'Nom',
    },
  },
  dashboard: {
    welcomeMessage: {
      title: 'Bienvenue dans l’application d’administration du District Gourmet!',
      description: 'Cliquez sur les liens de navigation à gauche pour accéder aux commandes ou à la gestion des items.',
    },
  },
  disruptive: {
    message: 'Êtes-vous certain(e) de vouloir exécuter cette action?',
    title: 'Confirmation',
  },
  error: {
    fieldRequired: 'Ce champ est requis',
    happened: 'Une erreur est survenue. Veuillez essayer de nouveau.',
    invalidFile: 'Type de fichier invalide',
  },
  imageUpload: {
    dropzone: {
      dragMessage: 'Déposez l’image ici',
      errorMessage: 'Une erreur est survenue lors du téléversement du fichier',
      message: 'Déposez une image ici, ou cliquez pour sélectionner',
      successMessage: 'Fichier: ',
    },
    error: {
      title: 'Erreur de téléversement',
      description: "Une erreur s'est produite lors du téléversement de votre image. Veuillez essayer de nouveau.",
    },
  },
  inactive: 'Inactif',
  nav: {
    items: 'Tous les items',
    newItems: 'Nouveaux items',
    orders: 'Commandes',
    sections: 'Terroirs',
    categories: 'Restaurants',
    configuration: 'Configuration',
    operatingHours: 'Heures d’opération',
    employees: 'Employés',
  },
  user: {
    email: 'Courriel',
    forgotPassword: 'Mot de passe oublié?',
    login: 'Connexion',
    logout: 'Déconnexion',
    password: 'Mot de passe',
  },
  blockedUserMessage: 'Vous avez été bloqué de la plateforme d’administration',
}
