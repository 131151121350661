import React from 'react'

import { VStack } from '@chakra-ui/react'

import { OperatingHoursNormalHourForm } from '@/ui/pages/operatingHours/components/operatingHoursNormalHourForm/OperatingHoursNormalHourForm'

export const ItemOperatingHoursForm: React.FC = () => {
  return (
    <VStack spacing={4} alignItems='flex-start' minWidth='xl' data-testid='ItemOperatingHoursForm'>
      <OperatingHoursNormalHourForm type='online' showHeading={false} />
    </VStack>
  )
}
