import * as React from 'react'
import { Link as ReactRouterLink, useHistory, useRouteMatch } from 'react-router-dom'

import { Box, Link, Stack, useDisclosure } from '@chakra-ui/react'

import { useCategoriesQuery } from '@/app/category/useCategoriesQuery'
import { Section } from '@/app/section/Section.model'
import { Zone } from '@/app/zone/Zone.model'
import { UpdateSectionModal } from '@/ui/pages/sections/components/modals/update/UpdateSectionModal'
import { SectionRow } from '@/ui/pages/sections/components/SectionRow'

type SectionsProps = {
  readonly sections: ReadonlyArray<Section>
  readonly restaurantZoneId: Zone['id']
}

export const Sections: React.FC<SectionsProps> = ({ restaurantZoneId, sections }) => {
  const routeMatch = useRouteMatch<{
    id: string
  }>('/sections/:id')
  const routeSection = routeMatch && sections.find(({ id }) => id === routeMatch.params.id)
  const history = useHistory()
  const { isLoading, data: categories } = useCategoriesQuery()
  const { onClose, isOpen } = useDisclosure({
    isOpen: !!routeSection,
    onClose() {
      history.push('/sections')
    },
  })

  return (
    <Box>
      {!isLoading && (
        <Stack spacing='2'>
          {sections.map((section) => {
            const sectionCategory = categories?.filter((category) => category.sectionId === section.id)
            return (
              <Link
                as={ReactRouterLink}
                key={section.id}
                _hover={{ cursor: 'pointer' }}
                fontSize='sm'
                fontWeight='medium'
                to={`/sections/${section.id}`}
              >
                <SectionRow section={section} categories={sectionCategory} key={section.id} />
              </Link>
            )
          })}
        </Stack>
      )}
      {routeSection && (
        <UpdateSectionModal
          isOpen={isOpen}
          onClose={onClose}
          restaurantZoneId={restaurantZoneId}
          section={routeSection}
        />
      )}
    </Box>
  )
}
