import React, { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, Heading } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { Group } from '@/app/group/Group.model'
import { UpdateGroupRequest } from '@/app/group/useUpdateGroupMutation'
import { EditModal, EditModalActions, EditModalHeader } from '@/ui/components/editModal/EditModal'

import { UpdateGroupImageForm } from './UpdateGroupImageForm'

export interface UpdateGroupImageModalProps {
  readonly imageId?: Group['imageId']
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly onSubmit: SubmitHandler<UpdateGroupRequest>
}

export const UpdateGroupImageModal: React.FC<UpdateGroupImageModalProps> = ({ imageId, isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation(['categories', 'common'])
  const methods = useForm<UpdateGroupRequest>()
  const [isLoading, setIsLoading] = useState(false)
  const onFormSubmit = async () => {
    try {
      setIsLoading(true)
      await methods.handleSubmit(onSubmit)()
      onClose()
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    methods.reset()
  }, [imageId, methods])

  return (
    <FormProvider {...methods}>
      <EditModal isOpen={isOpen} onClose={onClose} sideBarColor='white'>
        <EditModalHeader>
          <Heading size='lg' fontWeight='medium' pb={4}>
            {t('update.groupImage')}
          </Heading>
        </EditModalHeader>
        <UpdateGroupImageForm imageId={imageId} />
        <EditModalActions>
          <Flex alignItems='center' justifyContent='flex-end' pt='4'>
            <ButtonQg
              buttonType='primary'
              onClick={onFormSubmit}
              data-testid='submit-button'
              bg='blue.500'
              isLoading={isLoading}
            >
              {t('common:action.save')}
            </ButtonQg>
          </Flex>
        </EditModalActions>
      </EditModal>
    </FormProvider>
  )
}
