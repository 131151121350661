import React from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { EditModal } from '@/ui/components/editModal/EditModal'
import { ItemEditModalBody } from '@/ui/pages/items/components/modal/ItemEditModalBody'
import { useItemList } from '@/ui/pages/items/ItemListContext'

export const ItemEditModal: React.FC = () => {
  const { items, editItemId, closeEdit } = useItemList()
  const editItem = items.find(({ id }) => id === editItemId)
  const { isOpen, onClose } = useDisclosure({ isOpen: !!editItem, onClose: closeEdit })

  return (
    <EditModal size='5xl' isOpen={isOpen} onClose={onClose} sideBarColor='steel.50'>
      {editItem && <ItemEditModalBody item={editItem} />}
    </EditModal>
  )
}
