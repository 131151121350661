import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as ReactRouterLink } from 'react-router-dom'

import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'
import { HStack, Link, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { ButtonAdd } from '@district-qg/ui'

import { Category } from '@/app/category/Category.model'

import { CreateCategoryModal } from '../create/CreateCategoryModal'

export type CategoriesListProps = {
  categories: ReadonlyArray<Category>
}

export const CategoriesList: React.FC<CategoriesListProps> = ({ categories }) => {
  const { t } = useTranslation('categories')
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <HStack justify='space-between' marginY={4}>
        <Text as='h2' casing='uppercase' fontSize='base' fontWeight='bold' color='peche.100'>
          {t('title', { count: 2 })}
        </Text>
        <ButtonAdd colorScheme='white' size='2xs' onClick={onOpen} />
      </HStack>
      <CreateCategoryModal isOpen={isOpen} onClose={onClose} />
      <Stack spacing={3} ml={-2} px={1}>
        {_map(
          _sortBy(categories, (category) => category.title.toLowerCase()),
          (category) => {
            return (
              <Link
                as={ReactRouterLink}
                key={category.id}
                fontSize='sm'
                fontWeight='medium'
                to={`${t('url')}/${category.id}`}
                px={1}
              >
                {category.title}
              </Link>
            )
          }
        )}
      </Stack>
    </>
  )
}
