import React from 'react'

import { Box, BoxProps, useColorModeValue as mode } from '@chakra-ui/react'

export const ScrollArea: React.FC<BoxProps> = (props) => (
  <Box
    overflowY='auto'
    overflowX='hidden'
    minH='px'
    {...props}
    sx={{
      '&::-webkit-scrollbar-track': {
        bg: 'transparent',
      },
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        bg: mode('blue.600', 'gray.700'),
        borderRadius: '20px',
      },
    }}
  />
)
