import React, { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex, Heading } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { UpdateCategoryRequest } from '@/app/category/usePatchCategoryMutation'
import { Image } from '@/app/types/image'
import { EditModal, EditModalActions, EditModalBody, EditModalHeader } from '@/ui/components/editModal/EditModal'
import { UpdateCategoryImageForm } from '@/ui/pages/categories/update/image/UpdateCategoryImageForm'

export interface UpdateCategoryImageModalProps {
  readonly headerText: string
  readonly image?: Image
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly onSubmit: SubmitHandler<UpdateCategoryRequest>
}

export const UpdateCategoryImageModal: React.FC<UpdateCategoryImageModalProps> = ({
  headerText,
  image,
  isOpen,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation()
  const methods = useForm<UpdateCategoryRequest>({
    defaultValues: { mainImage: { altText: { fr: image?.altText }, id: image?.id } },
  })
  const [isLoading, setIsLoading] = useState(false)
  const onFormSubmit = async () => {
    try {
      setIsLoading(true)
      await methods.handleSubmit(onSubmit)()
      onClose()
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    methods.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  return (
    <FormProvider {...methods}>
      <EditModal isOpen={isOpen} onClose={onClose} sideBarColor='white'>
        <EditModalHeader>
          <Heading size='lg' fontWeight='medium' pb={4}>
            {headerText}
          </Heading>
        </EditModalHeader>
        <EditModalBody includeFooter>
          <UpdateCategoryImageForm image={image} />
        </EditModalBody>
        <EditModalActions>
          <Flex alignItems='center' justifyContent='flex-end' pt='4'>
            <ButtonQg
              buttonType='primary'
              onClick={onFormSubmit}
              data-testid='submit-button'
              bg='blue.500'
              isLoading={isLoading}
            >
              {t('action.save')}
            </ButtonQg>
          </Flex>
        </EditModalActions>
      </EditModal>
    </FormProvider>
  )
}
