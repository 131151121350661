import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormControl, FormLabel, Heading, Input, Stack } from '@chakra-ui/react'

import { Card, CardContent, CardHeader } from '@/ui/components/card/Card'
import { QGConfigurationFormData } from '@/ui/pages/configuration/components/QGConfigurationFormData'

export const QGConfigurationFormLinksCard: React.FC = () => {
  const { t } = useTranslation('configuration')
  const { register } = useFormContext<QGConfigurationFormData>()

  return (
    <Card backgroundColor='gray.50'>
      <CardHeader>
        <Heading fontSize='2xl' fontWeight='normal'>
          {t('links.title')}
        </Heading>
      </CardHeader>
      <CardContent pb={8}>
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>{t('links.termsOfService')}</FormLabel>
            <Input {...register('legal.termsOfService.fr')} type='text' bg='white' data-testid='link-termsOfService' />
          </FormControl>
          <FormControl>
            <FormLabel>{t('links.privacyPolicy')}</FormLabel>
            <Input {...register('legal.privacyPolicy.fr')} type='text' bg='white' data-testid='link-privacyPolicy' />
          </FormControl>
        </Stack>
      </CardContent>
    </Card>
  )
}
