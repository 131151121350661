import * as React from 'react'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import { Flex, Grid, Heading, Icon } from '@chakra-ui/react'
import { CalendarCheckIcon, DateRangePickerButton } from '@district-qg/ui'

import { ContentHeader } from '@/ui/layout/components/ContentHeader'
import { OrderTable } from '@/ui/pages/orders/components/OrderTable'
import { OrderViewProvider } from '@/ui/pages/orders/components/OrderViewContext'
import { OrderViewModal } from '@/ui/pages/orders/components/OrderViewModal'
import { useUrlDateRangeState } from '@/ui/pages/orders/hooks/useUrlDateRangeState'

export const OrderListPage: React.FC = () => {
  const { t, i18n } = useTranslation('orders')
  const [dateRange, setDateRange] = useUrlDateRangeState()

  const dateStartDay = dayjs(dateRange[0]).format('L')
  const dateEndDay = dayjs(dateRange[1]).format('L')

  return (
    <OrderViewProvider>
      <ContentHeader>
        <Grid templateColumns='1fr 2fr 1fr' gap={4}>
          <Heading size='lg' fontWeight='normal'>
            {t('title')}
          </Heading>
          <DateRangePickerButton onChange={setDateRange} value={dateRange} locale={i18n.language}>
            <Flex alignItems='center' justifyContent='center'>
              <Heading size='lg' fontWeight='normal' mr={3}>
                {t('orderFilterDate', { date: dateRange[0] })}
                {dateStartDay !== dateEndDay && ` — ${t('orderFilterDate', { date: dateRange[1] })}`}
              </Heading>
              <Icon as={CalendarCheckIcon} boxSize='1.25rem' aria-label={t('common:action.selectDate')} />
            </Flex>
          </DateRangePickerButton>
        </Grid>
      </ContentHeader>
      <OrderTable dateRange={dateRange} />
      <OrderViewModal />
    </OrderViewProvider>
  )
}
