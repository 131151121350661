import * as React from 'react'

import { Box, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'

import { DatePicker, datePickerWidth, DateRangePickerProps } from './DatePicker'

export type DateRangePickerButtonProps = Pick<DateRangePickerProps, 'onChange' | 'value' | 'locale'>

export const DateRangePickerButton: React.FC<DateRangePickerButtonProps> = ({
  onChange,
  value,
  locale,
  children,
  ...rest
}) => {
  const [showDatePicker, setShowDatePicker] = React.useState(false)

  const handleChange = (dates: [Date] | [Date, Date] | null) => {
    onChange(dates)
    setShowDatePicker(false)
  }

  return (
    <Popover isOpen={showDatePicker} onClose={() => setShowDatePicker(false)} {...rest}>
      <PopoverTrigger>
        <Box as='button' onClick={() => setShowDatePicker(!showDatePicker)} data-testid='daterangepicker-button'>
          {children}
        </Box>
      </PopoverTrigger>
      <PopoverContent w={datePickerWidth} data-testid='datepicker'>
        <DatePicker selectRange onChange={handleChange} value={value} locale={locale} />
      </PopoverContent>
    </Popover>
  )
}
