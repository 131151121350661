import { FieldValues } from 'react-hook-form'

import { CreateCategoryRequest } from '@/app/category/useCreateCategoryMutation'

export const mapFieldValuesToRequest = (qgId: string, fieldValues: FieldValues): CreateCategoryRequest => ({
  qgId,
  sectionId: fieldValues.sectionId,
  title: {
    fr: fieldValues.title,
    en: fieldValues.title,
  },
  subtitle: {
    fr: fieldValues.subtitle,
    en: fieldValues.subtitle,
  },
  description: {
    fr: fieldValues.description,
    en: fieldValues.description,
  },
})
