import { Day } from './Day'

export type OperatingHoursSchedule = {
  readonly open: string
  readonly close: string
}

export const DEFAULT_HOURS: OperatingHoursSchedule = {
  open: '00:00',
  close: '00:00',
}

export type RegularOperatingHours = Record<Day, OperatingHoursSchedule[]>
export type ExceptionOperatingHours = Record<string, OperatingHoursSchedule[]>
export type OperatingHours = {
  regular: RegularOperatingHours
  exceptions: ExceptionOperatingHours
}
