import * as React from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormControl, FormLabel, Heading, Stack } from '@chakra-ui/react'

import { Card, CardContent, CardHeader } from '@/ui/components/card/Card'
import { ValueListInput } from '@/ui/components/input/ValueListInput'
import { QGConfigurationFormData } from '@/ui/pages/configuration/components/QGConfigurationFormData'

export const QGConfigurationFormEmailsCard: React.FC = () => {
  const { t } = useTranslation('configuration')
  const {
    fields: orderConfirmationFields,
    remove: removeOrderConfirmation,
    append: appendOrderConfirmation,
  } = useFieldArray<QGConfigurationFormData>({
    name: `emails.orderConfirmationBCCs`,
  })
  const {
    fields: orderErrorFields,
    remove: removeOrderError,
    append: appendOrderError,
  } = useFieldArray<QGConfigurationFormData>({
    name: `emails.orderError`,
  })
  const {
    fields: accountingEmailFields,
    remove: removeAccountingEmail,
    append: appendAccountingEmail,
  } = useFieldArray<QGConfigurationFormData>({
    name: `emails.accounting`,
  })

  return (
    <Card backgroundColor='gray.50'>
      <CardHeader>
        <Heading fontSize='2xl' fontWeight='normal'>
          {t('email.title')}
        </Heading>
      </CardHeader>
      <CardContent pb={8}>
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>{t('email.orderConfirmation')}</FormLabel>
            <ValueListInput
              values={orderConfirmationFields}
              onRemove={removeOrderConfirmation}
              onAppend={appendOrderConfirmation}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t('email.orderError')}</FormLabel>
            <ValueListInput values={orderErrorFields} onRemove={removeOrderError} onAppend={appendOrderError} />
          </FormControl>
          <FormControl>
            <FormLabel>{t('email.accounting')}</FormLabel>
            <ValueListInput
              values={accountingEmailFields}
              onRemove={removeAccountingEmail}
              onAppend={appendAccountingEmail}
            />
          </FormControl>
        </Stack>
      </CardContent>
    </Card>
  )
}
