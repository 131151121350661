import * as React from 'react'
import { KeyboardEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _uniqueId from 'lodash/uniqueId'
import { Flex, HStack, IconButton, Input, Stack, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import { AddIcon } from '@district-qg/ui'

export type ValueListInputItem = { id: string; value: string }

export type ValueListInputProps = {
  values: Array<ValueListInputItem>
  onRemove: (index: number) => void
  onAppend: (value: ValueListInputItem) => void
}

export const createValueListInputItem = (value: string): ValueListInputItem => ({
  id: _uniqueId('ValueListInputItem'),
  value,
})
export const getValue = ({ value }: ValueListInputItem): string => value

export const ValueListInput: React.FC<ValueListInputProps> = ({ values, onRemove, onAppend }) => {
  const { t } = useTranslation()
  const [newValue, setNewValue] = useState<string>('')

  const onEnter: (callback: () => void) => KeyboardEventHandler = (callback) => (event) => {
    if (event.key === 'Enter') {
      callback()
    }
  }

  const addItem = () => {
    if (newValue) {
      onAppend(createValueListInputItem(newValue))
      setNewValue('')
    }
  }

  return (
    <Stack alignItems='flex-start'>
      <Flex flexWrap='wrap'>
        {values.map(({ id, value }, index) => (
          <Tag key={id} size='lg' mr={2} mb={2} data-testid={`tag-${index}`}>
            <TagLabel>{value}</TagLabel>
            <TagCloseButton aria-label={t('action.remove')} onClick={() => onRemove(index)} />
          </Tag>
        ))}
      </Flex>
      <HStack w='full'>
        <Input
          data-testid='new-value-input'
          value={newValue}
          onChange={(event) => setNewValue(event.target.value)}
          onKeyDown={onEnter(addItem)}
          size='sm'
          type='text'
          bg='white'
          flex='1'
        />
        <IconButton
          data-testid='new-value-add-button'
          aria-label={t('action.add')}
          icon={<AddIcon boxSize='4' />}
          size='sm'
          variant='outline'
          isRound
          onClick={addItem}
          isDisabled={!newValue}
        />
      </HStack>
    </Stack>
  )
}
