import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { useAuth0UserWithSentry } from '@district-qg/frontend-common'

import { QGAdminFeaturesProvider } from '@/app/features/QGAdminFeaturesProvider'
import { AppRouter } from '@/AppRouter'
import { useSyncDocumentLocale } from '@/i18n'
import { theme } from '@/ui/theme'

export const AuthenticatedAppRouter = withAuthenticationRequired(AppRouter)

type AppProps = {
  isDev: boolean
}

export const App: React.FC<AppProps> = ({ isDev }: AppProps) => {
  useSyncDocumentLocale()
  useAuth0UserWithSentry()
  const queryClient = React.useRef(new QueryClient())

  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider resetCSS theme={theme}>
        <QueryClientProvider client={queryClient.current}>
          <QGAdminFeaturesProvider>
            <AuthenticatedAppRouter />
          </QGAdminFeaturesProvider>
          {isDev && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </ChakraProvider>
    </>
  )
}
