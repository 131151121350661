import React, { KeyboardEventHandler, useEffect, useState } from 'react'
import { DragDropContext, DragDropContextProps, Draggable, Droppable } from 'react-beautiful-dnd'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { v4 as uuidv4 } from 'uuid'
import { Box, Button, HStack, Input, Stack } from '@chakra-ui/react'
import { DragHandleIcon } from '@district-qg/ui'

import { GroupDto } from '@/app/group/Group.dto'

export type UpdateGroupsRequest = { groups: Array<GroupDto & { isNew?: boolean }> }

type UpdateGroupsFormProps = {
  groups?: Array<GroupDto>
}

export const UpdateGroupsForm: React.FC<UpdateGroupsFormProps> = ({ groups }) => {
  const { t } = useTranslation('categories')
  const {
    register,
    formState: { isDirty },
    setValue,
  } = useFormContext<UpdateGroupsRequest>()
  const { fields, append, move } = useFieldArray<UpdateGroupsRequest, 'groups', 'fieldId'>({
    name: 'groups',
    keyName: 'fieldId',
  })

  useEffect(() => {
    if (groups && !isDirty) {
      setValue('groups', [...groups], { shouldDirty: false })
    }
  }, [groups, setValue, isDirty])

  const [newGroupName, setNewGroupName] = useState<string>('')
  const addGroup = () => {
    if (newGroupName) {
      append({
        id: uuidv4(),
        name: { fr: `${newGroupName}` },
        active: false,
        isNew: true,
      })
      setNewGroupName('')
    }
  }
  const addGroupOnEnter: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      addGroup()
    }
  }

  /* istanbul ignore next */
  const onDragEnd: DragDropContextProps['onDragEnd'] = (result) => {
    if (result.destination) {
      move(result.source.index, result.destination.index)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='groups'>
        {(droppable) => (
          <Box {...droppable.droppableProps} ref={droppable.innerRef}>
            <Stack spacing='4'>
              {fields.map((group, index) => (
                <Draggable key={group.fieldId} draggableId={group.fieldId} index={index}>
                  {(draggable, snapshot) => (
                    <HStack
                      bg='white'
                      ref={draggable.innerRef}
                      {...draggable.draggableProps}
                      {...draggable.dragHandleProps}
                    >
                      <Input
                        w='md'
                        data-testid={`group-${group.id}-name`}
                        {...register(`groups.${index}.name.fr` as const)}
                        defaultValue={group.name.fr}
                        borderColor={/* istanbul ignore next */ snapshot.isDragging ? 'blue.400' : 'gray.200'}
                      />
                      <DragHandleIcon
                        color={/* istanbul ignore next */ snapshot.isDragging ? 'blue.400' : 'gray.300'}
                      />
                    </HStack>
                  )}
                </Draggable>
              ))}
              {droppable.placeholder}
              <HStack bg='white'>
                <Input
                  data-testid='new-group-name'
                  w='md'
                  value={newGroupName}
                  onChange={(event) => setNewGroupName(event.target.value)}
                  onKeyDown={addGroupOnEnter}
                />
                <Button data-testid='add-group' variant='ghost' onClick={addGroup} disabled={!newGroupName}>
                  {t('groups.edit.actions.add')}
                </Button>
              </HStack>
            </Stack>
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}
