import { useQueryClient } from 'react-query'

import { UseAxiosMutationOptions, useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { ORDER_QUERY_KEY } from '@/app/order/useOrderQuery'
import { ORDERS_QUERY_KEY } from '@/app/order/useOrdersQuery'

export type UseRefundOrderMutationRequest = {
  orderId: string
  reason: string
  amount: number
}

export type UseRefundOrderMutationOptions = UseAxiosMutationOptions<void, UseRefundOrderMutationRequest>

export const useRefundOrderMutation = (
  options?: UseRefundOrderMutationOptions
): UseMutationExtendedResult<void, UseRefundOrderMutationRequest> => {
  const queryClient = useQueryClient()

  return useMutation({
    path: ({ orderId }) => `/v1/admin/orders/${orderId}/refund`,
    method: 'post',
    requestDataBuilder: ({ reason, amount }) => ({ reason, amount }),
    ...options,
    onSuccess: async (errors, variables, context) => {
      await queryClient.invalidateQueries(ORDERS_QUERY_KEY)
      await queryClient.invalidateQueries([ORDER_QUERY_KEY, variables.orderId])
      /* istanbul ignore next */
      return options?.onSuccess?.(errors, variables, context)
    },
  })
}
