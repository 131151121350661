import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import _isEmpty from 'lodash/isEmpty'
import _without from 'lodash/without'
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Spacer,
  Stack,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { useAppFeatures } from '@district-qg/frontend-common'
import { DatePickerButton } from '@district-qg/ui'

import { Category } from '@/app/category/Category.model'
import { Group } from '@/app/group/Group.model'
import { ItemStockManagementDto } from '@/app/item/Item.dto'
import { Item } from '@/app/item/Item.model'
import { UpdateItemRequest } from '@/app/item/useUpdateItemMutation'
import { S3PresignedUrlRequest } from '@/app/media/useCreateS3PresignedUrlMutation'
import { EditModalBody, EditModalSideBar } from '@/ui/components/editModal/EditModal'
import { ImagePicker } from '@/ui/components/image/ImagePicker'
import { ImageUploadDialog } from '@/ui/components/imageUploadDialog/ImageUploadDialog'

import { ItemOperatingHoursForm } from './ItemOperatingHoursForm'

type ItemEditFormProps = {
  readonly item: Item
  readonly categories: ReadonlyArray<Category>
}

export const ItemEditForm: React.FC<ItemEditFormProps> = ({ item, categories }) => {
  const { register, setValue, getValues, watch } = useFormContext<
    UpdateItemRequest & {
      readonly stockManagement: ItemStockManagementDto
      readonly operatingHoursActive: boolean
    }
  >()
  const { t, i18n } = useTranslation('items')
  const { onSiteItemsActivationEnabled } = useAppFeatures()
  const watchCategoryId = watch('categoryId', item.categoryId)
  const watchStartDateAvailability = watch('availabilities.startDate', item.availabilities.startDate)
  const watchEndDateAvailability = watch('availabilities.endDate', item.availabilities.endDate)
  const watchGroupIds = watch('groupIds', item.groupIds)
  const watchStockManagement = watch('stockManagement', item.stockManagement)
  const watchOperatingHoursActive = watch('operatingHoursActive')
  const groups = categories.find(({ id }) => id === watchCategoryId)?.groups ?? []
  const s3PresignedUrlRequest: S3PresignedUrlRequest = {
    type: 'main',
    target: 'items',
  }
  const watchImage = watch('mainImage', { id: item.mainImage?.id })
  /* istanbul ignore next */
  const setImageId = (imageId: string) => {
    setValue('mainImage', { id: imageId }, { shouldDirty: true })
  }
  useEffect(() => {
    /* istanbul ignore next */
    if (item.mainImage?.id) {
      setImageId(item.mainImage.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.mainImage])

  useEffect(() => {
    const category = categories.find(({ id }) => id === item.categoryId)
    if (category) {
      setValue('categoryId', item.categoryId, { shouldDirty: false })
    } else {
      setValue('categoryId', undefined, { shouldDirty: false })
    }
    if (category?.groups.find((group) => item.groupIds.includes(group.id))) {
      setValue('groupIds', item.groupIds, { shouldDirty: false })
    } else {
      setValue('groupIds', [], { shouldDirty: false })
    }
  }, [item, setValue, categories])

  const setCategoryId = (categoryId: string) => {
    setValue('categoryId', categoryId)
    const category = categories.find(({ id }) => id === getValues('categoryId'))
    if (category && category.id === item.categoryId) {
      setValue('groupIds', item.groupIds, { shouldDirty: true })
    } else {
      setValue('groupIds', [], { shouldDirty: true })
    }
  }

  const onGroupChange = (groupId: string, isChecked: boolean) => {
    const groupIds = getValues('groupIds') ?? []
    setValue('groupIds', isChecked ? [...groupIds, groupId] : _without(groupIds, groupId), { shouldDirty: true })
  }

  return (
    <>
      <EditModalBody includeFooter>
        <Stack spacing='6'>
          <FormControl>
            <Flex align='center'>
              <HStack spacing={8}>
                <HStack spacing={4}>
                  <Switch
                    data-testid='item-isActiveOnline-switch'
                    size='lg'
                    defaultChecked={item.isActiveOnline}
                    {...register('isActiveOnline', { required: false })}
                  />
                  <FormLabel htmlFor='email-alerts' fontWeight='700' ml='3' mt='1'>
                    {t('edit.online')}
                  </FormLabel>
                </HStack>
                {onSiteItemsActivationEnabled && (
                  <HStack spacing={4}>
                    <Switch
                      data-testid='item-isActiveOnSite-switch'
                      size='lg'
                      defaultChecked={item.isActiveOnSite}
                      {...register('isActiveOnSite', { required: false })}
                    />
                    <FormLabel htmlFor='email-alerts' fontWeight='700' ml='3' mt='1'>
                      {t('edit.onSite')}
                    </FormLabel>
                  </HStack>
                )}
                <HStack spacing={4}>
                  <Switch
                    data-testid='item-isChefExperience-switch'
                    size='lg'
                    defaultChecked={item.isChefExperience}
                    {...register('isChefExperience', { required: false })}
                  />
                  <FormLabel htmlFor='email-alerts' fontWeight='700' ml='3' mt='1'>
                    {t('edit.isChefExperience')}
                  </FormLabel>
                </HStack>
              </HStack>
              <Spacer />
              <ImageUploadDialog imageUploadRequest={{ s3PresignedUrlRequest, setImageId }}>
                <ImagePicker
                  altText={item.mainImage?.altText}
                  id={watchImage?.id}
                  backgroundHeight='100px'
                  backgroundWidth='100px'
                  heightInPixel={100}
                  widthInPixel={100}
                  path='items/main'
                />
              </ImageUploadDialog>
            </Flex>
          </FormControl>
          <FormControl id='name'>
            <FormLabel>{t('edit.name')}</FormLabel>
            <Input defaultValue={item.name} type='text' {...register('name.fr', { required: false })} />
            <Text color='blue.500'>{`${t('edit.veloceName', {
              externalId: item.externalId,
              externalName: item.external.name,
            })}`}</Text>
          </FormControl>
          <HStack spacing={4} alignItems='flex-end'>
            <FormControl id='price' width={32}>
              <FormLabel>{t('edit.price')}</FormLabel>
              <Input defaultValue={item.external.price.amount} type='text' backgroundColor='steel.50' readOnly />
            </FormControl>
            <Checkbox isChecked={item.external.taxes.tps} isDisabled fontWeight='700'>
              {t('edit.tps')}
            </Checkbox>
            <Checkbox isChecked={item.external.taxes.tvq} isDisabled fontWeight='700'>
              {t('edit.tvq')}
            </Checkbox>
          </HStack>

          <FormControl id='description'>
            <FormLabel>{t('edit.description')}</FormLabel>
            <Textarea
              type='text'
              height='100px'
              defaultValue={item.description}
              {...register('description.fr', { required: false })}
            />
          </FormControl>
          <FormControl>
            <Checkbox
              data-testid='item-operating-hours-active'
              defaultChecked={!_isEmpty(item.operatingHours)}
              {...register('operatingHoursActive', {})}
              onChange={(event) => setValue('operatingHoursActive', event.target.checked, { shouldDirty: true })}
              fontWeight='700'
            >
              {t('edit.operatingHoursManagement')}
            </Checkbox>
            {watchOperatingHoursActive && (
              <Box my={4}>
                <ItemOperatingHoursForm />
              </Box>
            )}
          </FormControl>

          <Text fontWeight='400' fontSize='12' color='gray.600'>
            {t('edit.lastUpdate', { externalId: item.externalId, date: item.external.updatedAt })}
          </Text>
        </Stack>
      </EditModalBody>
      <EditModalSideBar>
        <Stack spacing={8} align='flex-start'>
          <FormControl id='availabilities'>
            <HStack spacing={4}>
              <Box>
                <FormLabel fontWeight='500' fontSize='14' color='gray.600' lineHeight='5'>
                  {t('edit.availabilitiesStart')}
                </FormLabel>
                <DatePickerButton
                  value={item.availabilities.startDate}
                  onChange={(date) => {
                    setValue('availabilities.startDate', date, {
                      shouldTouch: true,
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }}
                  locale={i18n.language}
                  clearable
                >
                  <Input
                    value={watchStartDateAvailability ? dayjs(watchStartDateAvailability).format('YYYY-MM-DD') : ''}
                    type='text'
                    fontWeight='700'
                    fontSize='14'
                    color='gray.900'
                    lineHeight='5'
                    backgroundColor='white'
                    readOnly
                  />
                </DatePickerButton>
              </Box>
              <Box>
                <FormLabel fontWeight='500' fontSize='14' color='gray.600' lineHeight='5'>
                  {t('edit.availabilitiesEnd')}
                </FormLabel>
                <DatePickerButton
                  value={item.availabilities.endDate}
                  onChange={(date) => {
                    setValue('availabilities.endDate', date, {
                      shouldTouch: true,
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }}
                  locale={i18n.language}
                  clearable
                >
                  <Input
                    value={watchEndDateAvailability ? dayjs(watchEndDateAvailability).format('YYYY-MM-DD') : ''}
                    type='text'
                    fontWeight='700'
                    fontSize='14'
                    color='gray.900'
                    lineHeight='5'
                    backgroundColor='white'
                    readOnly
                  />
                </DatePickerButton>
              </Box>
            </HStack>
          </FormControl>

          <FormControl id='category'>
            <FormLabel fontWeight='700'>{t('edit.category.name')}</FormLabel>
            <Select
              data-testid='categories'
              placeholder={t('edit.category.placeholder')}
              {...register('categoryId', { required: false })}
              onChange={(e) => setCategoryId(e.currentTarget.value)}
              backgroundColor='white'
            >
              {categories.map((category) => {
                return (
                  <option
                    data-testid={category.id}
                    key={category.id}
                    value={category.id}
                    label={category.title}
                    defaultChecked={category.id === watchCategoryId}
                  />
                )
              })}
            </Select>
          </FormControl>
          <FormControl data-testid='groups'>
            {watchCategoryId && <FormLabel fontWeight='700'>{t('edit.groups')}</FormLabel>}
            <Stack>
              {watchCategoryId &&
                watchGroupIds &&
                groups.map((group: Group) => {
                  return (
                    <Checkbox
                      data-testid={`group-${group.id}`}
                      key={group.id}
                      defaultChecked={watchGroupIds.includes(group.id)}
                      onChange={(e) => onGroupChange(e.currentTarget.value, e.currentTarget.checked)}
                      value={group.id}
                    >
                      {group.name}
                    </Checkbox>
                  )
                })}
            </Stack>
          </FormControl>
          <FormControl>
            <Checkbox
              data-testid='item-stock-management-active'
              defaultChecked={item.stockManagement.active}
              {...register('stockManagement.active', {})}
              onChange={(event) => setValue('stockManagement.active', event.target.checked, { shouldDirty: true })}
              fontWeight='700'
            >
              {t('edit.stockManagement')}
            </Checkbox>
            {watchStockManagement && watchStockManagement.active && (
              <Stack>
                <Text display='flex' alignItems='center' fontWeight='400' fontSize='12' color='gray.600'>
                  {item.stockManagement.updatedAt && dayjs(item.stockManagement.updatedAt).format('YYYY-MM-DD HH:mm')}
                </Text>
                <HStack>
                  <NumberInput
                    defaultValue={item.stockManagement.availableQuantity}
                    {...register('stockManagement.availableQuantity', {
                      valueAsNumber: true,
                    })}
                    onChange={(_, valueAsNumber) => setValue('stockManagement.availableQuantity', valueAsNumber)}
                  >
                    <NumberInputField
                      data-testid='item-stock-management-number-input-field'
                      backgroundColor='white'
                      width='5.5rem'
                      fontWeight='700'
                    />
                  </NumberInput>
                  <Text>{item.stockManagement.totalQuantity ? `/${item.stockManagement.totalQuantity}` : ''}</Text>
                </HStack>
              </Stack>
            )}
          </FormControl>
        </Stack>
      </EditModalSideBar>
    </>
  )
}
