import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, FormLabel, HStack, Input, InputGroup, InputLeftElement, Select, Stack } from '@chakra-ui/react'
import { SearchIcon } from '@district-qg/ui'

import { EmployeeRole } from '@/app/employee/EmployeeRole'
import { EmployeeStatus } from '@/app/employee/EmployeeStatus'

import { StatusFilterValues } from './EmployeesTableFilter'

type EmployeesTableActionsProps = {
  setNameFilter(value?: string): void
  setRoleFilter(value?: EmployeeRole): void
  setStatusFilter(value?: StatusFilterValues): void
}

export const EmployeesTableActions: React.FC<EmployeesTableActionsProps> = ({
  setNameFilter,
  setRoleFilter,
  setStatusFilter,
}) => {
  const { t } = useTranslation('employees')

  return (
    <Stack spacing='4' direction={{ base: 'column', md: 'row' }} justify='space-between'>
      <HStack>
        <FormControl w={{ md: '250px' }} id='search'>
          <InputGroup size='sm'>
            <FormLabel srOnly>{t('tableActions.nameFilter')}</FormLabel>
            <InputLeftElement pointerEvents='none' color='gray.400'>
              <SearchIcon />
            </InputLeftElement>
            <Input
              data-testid='name-filter-input'
              onChange={(event) => setNameFilter(event.target.value)}
              rounded='base'
              type='search'
              placeholder={t('tableActions.nameFilter')}
            />
          </InputGroup>
        </FormControl>
        <Select
          data-testid='role-filter-select'
          w={{ base: '100px', md: '200px' }}
          rounded='base'
          size='sm'
          placeholder={t('tableActions.rolePlaceholder')}
          onChange={(event) => setRoleFilter(event.target.value as EmployeeRole)}
        >
          <option key={EmployeeRole.admin} value={EmployeeRole.admin}>
            {t(`role.${EmployeeRole.admin}`)}
          </option>
          <option key={EmployeeRole.concierge} value={EmployeeRole.concierge}>
            {t(`role.${EmployeeRole.concierge}`)}
          </option>
        </Select>
        <Select
          data-testid='status-filter-select'
          w={{ base: '100px', md: '200px' }}
          rounded='base'
          size='sm'
          placeholder={t('tableActions.statusPlaceholder')}
          onChange={(event) => setStatusFilter(event.target.value as StatusFilterValues)}
        >
          <option key={EmployeeStatus.active} value={EmployeeStatus.active}>
            {t(`status.${EmployeeStatus.active}`)}
          </option>
          <option key={EmployeeStatus.blocked} value={EmployeeStatus.blocked}>
            {t(`status.${EmployeeStatus.blocked}`)}
          </option>
          <option key={EmployeeStatus.pending} value={EmployeeStatus.pending}>
            {t(`status.${EmployeeStatus.pending}`)}
          </option>
        </Select>
      </HStack>
    </Stack>
  )
}
