export const categories = {
  create: {
    section: 'Terroir',
    description: 'Description',
    modalTitle: 'Nouveau restaurant',
    subtitle: 'Sous-titre du restaurant',
    title: 'Nom du restaurant',
  },
  groups: {
    edit: {
      actions: {
        add: 'Ajouter',
      },
      title: 'Liste des spécialités',
    },
  },
  index: {
    artisans: 'Artisans',
    banner: 'Bannière',
    groups: 'Spécialités',
  },
  profile: 'Profil',
  read: {
    addArtisanProfile: 'Profil artisan',
    addGroups: 'Ajouter une liste de spécialités',
    addRestaurantPicture: 'Photo du restaurant',
  },
  title: 'Restaurants',
  update: {
    groupImage: 'Photo de la Spécialité',
    groups: 'Spécialités',
    images: {
      altText: 'Texte alternatif pour l’image (SEO)',
    },
    title: 'Restaurant',
  },
  url: '/categories',
}
