import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Heading, HStack, Icon, Stack, Text, Tooltip } from '@chakra-ui/react'
import { useAppFeatures } from '@district-qg/frontend-common'
import { ClockIcon } from '@district-qg/ui'

import { Item } from '@/app/item/Item.model'
import { useUpdateItemMutation } from '@/app/item/useUpdateItemMutation'
import { ImgixImage } from '@/ui/components/image/ImgixImage'
import { SwitchWithDisruptive } from '@/ui/components/switch/SwitchWithDisruptive'
import { useItemList } from '@/ui/pages/items/ItemListContext'

export type GroupItemProps = {
  item: Item
}

export const GroupItem: React.FC<GroupItemProps> = ({ item }) => {
  const { t } = useTranslation('items')
  const { onSiteItemsActivationEnabled } = useAppFeatures()
  const { openEditId } = useItemList()

  const { mutateAsync } = useUpdateItemMutation({ id: item.id })

  return (
    <HStack
      spacing={4}
      alignItems='center'
      py={2}
      px={4}
      border='1px'
      borderColor='gray.200'
      rounded='base'
      cursor='pointer'
      onClick={() => openEditId(item.id)}
      data-testid='item-row'
    >
      <ImgixImage
        data-testid='image'
        altText={item.name}
        id={/* istanbul ignore next */ item.mainImage?.id}
        backgroundHeight='48px'
        backgroundWidth='48px'
        heightInPixel={48}
        widthInPixel={48}
        path='items/main'
      />

      <Box flex={1}>
        <Heading as='h4' fontFamily='body' fontSize='md'>
          {item.name}
        </Heading>
        <Text fontSize='xs'>{item.description}</Text>
      </Box>

      {item.operatingHours && (
        <Tooltip label={t('status.scheduledAvailability')}>
          <span>
            <Icon as={ClockIcon} h={4} w={4} display='block' />
          </span>
        </Tooltip>
      )}

      <Text px={2}>{t('common:price', { value: item.price })}</Text>

      <Stack spacing={0} alignItems='center' onClick={/* istanbul ignore next */ (e) => e.stopPropagation()}>
        <Text color='steel.base' fontSize='xs'>
          {t('tableContent.columns.online')}
        </Text>
        <SwitchWithDisruptive
          id={`isActiveOnline-${item.id}`}
          size='sm'
          initialValue={item.isActiveOnline}
          onConfirm={(newStatus) => mutateAsync({ isActiveOnline: newStatus })}
        />
      </Stack>

      {onSiteItemsActivationEnabled && (
        <Stack spacing={0} alignItems='center' onClick={/* istanbul ignore next */ (e) => e.stopPropagation()}>
          <Text color='steel.base' fontSize='xs'>
            {t('tableContent.columns.onSite')}
          </Text>
          <SwitchWithDisruptive
            id={`isActiveOnSite-${item.id}`}
            size='sm'
            initialValue={item.isActiveOnSite}
            onConfirm={(newStatus) => mutateAsync({ isActiveOnSite: newStatus })}
          />
        </Stack>
      )}

      <Stack spacing={0} alignItems='center' onClick={/* istanbul ignore next */ (e) => e.stopPropagation()}>
        <Text color='steel.base' fontSize='xs'>
          {t('tableContent.columns.isChefExperience')}
        </Text>
        <SwitchWithDisruptive
          id={`isChefExperience-${item.id}`}
          size='sm'
          initialValue={item.isChefExperience}
          onConfirm={(newStatus) => mutateAsync({ isChefExperience: newStatus })}
        />
      </Stack>
    </HStack>
  )
}
