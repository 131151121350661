import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Heading, UseModalProps, useToast } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { CreateEmployeeRequest, useCreateEmployeeMutation } from '@/app/employee/useCreateEmployeeMutation'
import { EditModal, EditModalActions, EditModalBody, EditModalHeader } from '@/ui/components/editModal/EditModal'
import { EmployeeModalForm } from '@/ui/pages/employees/components/modals/common/EmployeeModalForm'

export type CreateEmployeeModalProps = UseModalProps

export const CreateEmployeeModal: React.FC<CreateEmployeeModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('employees')
  const formMethods = useForm<CreateEmployeeRequest>({
    mode: 'onChange',
    defaultValues: {},
  })
  const { handleSubmit, reset, formState } = formMethods

  const onCloseModal = () => {
    onClose()
    reset()
  }

  const toast = useToast()

  const { isLoading, mutateAsync: createEmployee } = useCreateEmployeeMutation({
    onSuccess: () => {
      onCloseModal()
    },
    onError: () => {
      toast({
        title: t('create.error.title'),
        description: t('create.error.description'),
        status: 'error',
        isClosable: true,
        duration: 9000,
      })
    },
  })

  const createEmployeeAction = async (createEmployeeRequest: CreateEmployeeRequest) => {
    await createEmployee(createEmployeeRequest)
  }

  return (
    <EditModal isOpen={isOpen} onClose={onCloseModal} sideBarColor='steel.50'>
      <EditModalHeader>
        <Heading size='lg' fontWeight='medium' pb={4}>
          {t('create.modalTitle')}
        </Heading>
      </EditModalHeader>
      <FormProvider {...formMethods}>
        <EditModalBody>
          <EmployeeModalForm mode='create' />
        </EditModalBody>
      </FormProvider>
      <EditModalActions>
        <ButtonQg
          data-testid='submit-button'
          buttonType='primary'
          bg='steel.base'
          isLoading={isLoading}
          onClick={handleSubmit(createEmployeeAction)}
          disabled={!formState.isValid}
        >
          {t('common:action.save')}
        </ButtonQg>
      </EditModalActions>
    </EditModal>
  )
}
