import * as React from 'react'

import { useCategoriesQuery } from '@/app/category/useCategoriesQuery'

import { CategoriesList } from './CategoriesList'

export const CategoriesListWired: React.FC = () => {
  const { isLoading, data } = useCategoriesQuery()

  if (isLoading || !data) {
    return null
  }

  return <CategoriesList categories={data} />
}
