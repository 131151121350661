import * as React from 'react'

import { Box, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'

import { DatePicker, DatePickerProps, datePickerWidth } from './DatePicker'

export type DatePickerButtonProps = Pick<DatePickerProps, 'onChange' | 'value' | 'locale'> & { clearable?: boolean }

export const DatePickerButton: React.FC<DatePickerButtonProps> = ({
  onChange,
  value,
  locale,
  children,
  clearable,
  ...rest
}) => {
  const [showDatePicker, setShowDatePicker] = React.useState(false)

  const handleChange = (date: Date | null) => {
    onChange(date)
    setShowDatePicker(false)
  }

  return (
    <Popover isOpen={showDatePicker} onClose={() => setShowDatePicker(false)} {...rest}>
      <PopoverTrigger>
        <Box as='button' onClick={() => setShowDatePicker(!showDatePicker)} data-testid='datepicker-button'>
          {children}
        </Box>
      </PopoverTrigger>
      <PopoverContent w={datePickerWidth} data-testid='datepicker'>
        <DatePicker selectRange={false} onChange={handleChange} value={value} locale={locale} clearable={clearable} />
      </PopoverContent>
    </Popover>
  )
}
