import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Heading, HStack, Spinner } from '@chakra-ui/react'

import { useCategoriesQuery } from '@/app/category/useCategoriesQuery'
import { useItemsQuery } from '@/app/item/useItemsQuery'
import { ContentHeader } from '@/ui/layout/components/ContentHeader'
import { ItemTable } from '@/ui/pages/items/components/ItemTable'
import { ItemEditModal } from '@/ui/pages/items/components/modal/ItemEditModal'
import { ItemListProvider } from '@/ui/pages/items/ItemListContext'

export const ItemListPage: React.FC = () => {
  const { t } = useTranslation('items')
  const { data: items, isLoading: itemsLoading } = useItemsQuery()
  const { data: categories, isLoading: categoriesLoading } = useCategoriesQuery()

  return (
    <>
      <ContentHeader>
        <HStack>
          <Heading size='lg' fontWeight='normal'>
            {t('title')}
          </Heading>
          {(itemsLoading || categoriesLoading) && <Spinner />}
        </HStack>
      </ContentHeader>

      <ItemListProvider items={items} categories={categories}>
        <ItemTable />
        <ItemEditModal />
      </ItemListProvider>
    </>
  )
}
