import { EmployeeRole } from '@/app/employee/EmployeeRole'
import { EmployeeStatus } from '@/app/employee/EmployeeStatus'

import { EmployeeDto, EmployeesListDto } from './Employee.dto'

export interface Employee {
  readonly auth0Id: string
  readonly email: string
  readonly firstName: string
  readonly lastName: string
  readonly role?: EmployeeRole
  readonly status: EmployeeStatus
  readonly blocked: boolean
}

export class EmployeeModel implements Employee {
  constructor(readonly data: Readonly<EmployeeDto>) {}

  get auth0Id(): string {
    return this.data.auth0Id
  }

  get email(): string {
    return this.data.email
  }

  get firstName(): string {
    return this.data.firstName
  }

  get lastName(): string {
    return this.data.lastName
  }

  get role(): EmployeeRole | undefined {
    return this.data.role
  }

  get status(): EmployeeStatus {
    return this.data.status
  }

  get blocked(): boolean {
    return this.data.blocked
  }
}

export function buildModel(data: EmployeesListDto): Employee[] {
  return data.employees.map((item) => new EmployeeModel(item))
}
