import * as React from 'react'

import { Button, ButtonProps } from '@chakra-ui/react'

type ButtonType = 'primary' | 'secondary' | 'text'

export type ButtonQgProps = ButtonProps & {
  buttonType?: ButtonType
  to?: string
}

const baseProps = {
  primary: { variant: 'solid' },
  secondary: { variant: 'outline', size: 'sm' },
  text: { variant: 'link', size: 'sm' },
}

export const ButtonQg: React.FC<ButtonQgProps> = ({
  buttonType = 'secondary' as ButtonType,
  onClick,
  children,
  ...props
}) => {
  return (
    <Button colorScheme='epinard' onClick={onClick} {...baseProps[buttonType]} {...props}>
      {children}
    </Button>
  )
}
