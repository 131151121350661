import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Text, useToast } from '@chakra-ui/react'

import { getToastConfiguration } from '@/ui/components/toast/getToastConfiguration'

export type UploadStatus = 'error' | 'success' | 'idle' | 'loading'

export type ImageUploadStatusProps = {
  status: UploadStatus
  files: File[]
}

export const ImageUploadStatus: FC<ImageUploadStatusProps> = ({ status, files }) => {
  const { t } = useTranslation()
  const toast = useToast()
  useEffect(() => {
    if (status === 'error') {
      toast(getToastConfiguration(t('imageUpload.error.title'), t('imageUpload.error.description'), 'error'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return (
    <>{status === 'success' && files[0] && <Text>{t('imageUpload.dropzone.successMessage') + files[0].name}</Text>}</>
  )
}
