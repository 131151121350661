import { useEffect } from 'react'

import { useBoolean, useBreakpointValue } from '@chakra-ui/react'

type HookCommands = {
  readonly isOpen: boolean
  readonly on: () => void
  readonly off: () => void
  readonly toggle: () => void
}

export const useMobileMenuState = (): HookCommands => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const [isOpen, actions] = useBoolean(isMobile)
  useEffect(() => {
    if (isMobile === false) {
      actions.off()
    }
  }, [isMobile, actions])
  return { isOpen, ...actions }
}
