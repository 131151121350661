import { useQueryClient } from 'react-query'

import { UseAxiosMutationOptions, useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { EmployeeDto } from '@/app/employee/Employee.dto'
import { EMPLOYEES_QUERY_KEY } from '@/app/employee/useEmployeesQuery'

export type CreateEmployeeRequest = Pick<EmployeeDto, 'email' | 'firstName' | 'lastName'> &
  Required<Pick<EmployeeDto, 'role'>>

export type UseCreateEmployeeMutationOptions = UseAxiosMutationOptions<void, CreateEmployeeRequest>

export const useCreateEmployeeMutation = (
  options?: UseCreateEmployeeMutationOptions
): UseMutationExtendedResult<void, CreateEmployeeRequest> => {
  const queryClient = useQueryClient()

  return useMutation<void, CreateEmployeeRequest>({
    expectedStatus: 201,
    path: '/v1/admin/employees',
    method: 'post',
    ...options,
    onSuccess: async (errors, variables, context) => {
      await queryClient.invalidateQueries(EMPLOYEES_QUERY_KEY)
      /* istanbul ignore next */
      return options?.onSuccess?.(errors, variables, context)
    },
  })
}
