import {
  UseAxiosQueryOptions,
  useModelBuilderWithCurrentLocale,
  useQuery,
  UseQueryExtendedResult,
} from '@district-qg/frontend-common'

import { ItemDto } from '@/app/item/Item.dto'
import { buildModel, Item } from '@/app/item/Item.model'

export const ITEMS_QUERY_KEY = 'items'

type ItemsQueryOptions = UseAxiosQueryOptions<ReadonlyArray<Item>, ItemDto[]>
export const useItemsQuery = (options?: ItemsQueryOptions): UseQueryExtendedResult<ReadonlyArray<Item>, ItemDto[]> => {
  const mapper = useModelBuilderWithCurrentLocale(buildModel)

  return useQuery({
    queryKey: ITEMS_QUERY_KEY,
    ...options,
    path: '/v1/admin/items',
    mapper,
  })
}
