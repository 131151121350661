import * as React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import _noop from 'lodash/noop'

export type OrderViewData = {
  readonly orderId?: string
  openOrderId(orderId: string): void
  close(): void
}

const OrderViewContext = React.createContext<OrderViewData>({
  close: _noop,
  openOrderId: _noop,
})

export const OrderViewProvider: React.FC = ({ children }) => {
  // useRouteMatch is used for deep linking orders details
  const modalRouteMatch = useRouteMatch<{ id: string }>('/orders/:id')
  const { push } = useHistory()
  const [orderId, setOrderId] = React.useState<string | undefined>(modalRouteMatch?.params?.id)

  const close = () => {
    setOrderId(undefined)
    if (modalRouteMatch) {
      push('/orders')
    }
  }
  const value = { orderId, openOrderId: (id: string) => setOrderId(id), close }
  return <OrderViewContext.Provider value={value}>{children}</OrderViewContext.Provider>
}

export const useOrderView = (): OrderViewData => React.useContext(OrderViewContext)
