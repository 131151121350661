import { useQueryClient } from 'react-query'

import { useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { OrderDto } from '@/app/order/Order.dto'
import { Order } from '@/app/order/Order.model'
import { ORDER_QUERY_KEY } from '@/app/order/useOrderQuery'
import { ORDERS_QUERY_KEY } from '@/app/order/useOrdersQuery'

export type UpdateOrderNumberRequest = Pick<OrderDto, 'orderNumber'>

export const useOrderNumberUpdateMutation = (
  id: Order['id']
): UseMutationExtendedResult<OrderDto, UpdateOrderNumberRequest> => {
  const queryClient = useQueryClient()

  return useMutation<OrderDto, UpdateOrderNumberRequest>({
    path: `/v1/admin/orders/${id}`,
    method: 'post',
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([ORDER_QUERY_KEY, id]),
        queryClient.invalidateQueries(ORDERS_QUERY_KEY),
      ])
    },
  })
}
