import * as React from 'react'
import { useTranslation } from 'react-i18next'

import _map from 'lodash/map'
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Stack,
  Switch,
  Tooltip,
} from '@chakra-ui/react'
import { SearchIcon } from '@district-qg/ui'

import { OrderPaymentStatus, OrderPosStatus } from '@/app/order/Order.model'

type TableActionsProps = {
  setSearchFilter(value?: string): void
  setPaymentStatusFilter(value?: OrderPaymentStatus): void
  setPosStatusFilter(value?: OrderPosStatus): void
  selectedSearchFilter?: string
  selectedPaymentStatusFilter?: OrderPaymentStatus
  selectedPosStatusFilter?: OrderPosStatus
  hideAbandonedOrders: boolean
  setHideAbandonedOrders(value: boolean): void
}

export const TableActions: React.FC<TableActionsProps> = ({
  setSearchFilter,
  setPaymentStatusFilter,
  setPosStatusFilter,
  selectedSearchFilter,
  selectedPaymentStatusFilter,
  selectedPosStatusFilter,
  hideAbandonedOrders,
  setHideAbandonedOrders,
}) => {
  const { t } = useTranslation('orders')

  return (
    <Stack spacing='2' direction={{ base: 'column', md: 'row' }} alignItems='center'>
      <Tooltip label={t('tableActions.search.tooltip')} fontSize='xs'>
        <InputGroup size='sm' maxWidth={{ md: '16rem' }}>
          <FormLabel srOnly>{t('tableActions.nameFilter')}</FormLabel>
          <InputLeftElement pointerEvents='none' color='gray.400'>
            <SearchIcon />
          </InputLeftElement>
          <Input
            data-testid='search-filter-input'
            onChange={(event) => setSearchFilter(event.target.value)}
            rounded='base'
            type='search'
            placeholder={t('tableActions.search.placeholder')}
            value={selectedSearchFilter}
          />
        </InputGroup>
      </Tooltip>
      <Select
        data-testid='payment-status-filter-select'
        rounded='base'
        maxWidth={{ md: '16rem' }}
        size='sm'
        placeholder={t('tableActions.paymentStatus.placeholder')}
        value={selectedPaymentStatusFilter}
        onChange={(event) => setPaymentStatusFilter(event.target.value as OrderPaymentStatus)}
      >
        {_map(OrderPaymentStatus, (value) => (
          <option key={value} value={value}>
            {t(`status.${value}`)}
          </option>
        ))}
      </Select>
      <Select
        data-testid='pos-status-filter-select'
        rounded='base'
        maxWidth={{ md: '16rem' }}
        size='sm'
        placeholder={t('tableActions.posStatus.placeholder')}
        value={selectedPosStatusFilter}
        onChange={(event) => setPosStatusFilter(event.target.value as OrderPosStatus)}
      >
        {_map(OrderPosStatus, (value) => (
          <option key={value} value={value}>
            {t(`status.${value}`)}
          </option>
        ))}
      </Select>
      <Spacer />
      <FormControl display='flex' alignItems='center' justifyContent='flex-end'>
        <FormLabel
          htmlFor='hideAbandonedOrders'
          mb='0'
          fontSize='sm'
          lineHeight='none'
          textAlign='right'
          color='steel.base'
          cursor='pointer'
        >
          {t('tableActions.hideAbandonedOrders')}
        </FormLabel>
        <Switch
          id='hideAbandonedOrders'
          data-testid='hideAbandonedOrders'
          size='sm'
          isChecked={hideAbandonedOrders}
          onChange={(e) => setHideAbandonedOrders(e.target.checked)}
        />
      </FormControl>
    </Stack>
  )
}
