import _map from 'lodash/map'
import _mapValues from 'lodash/mapValues'
import _reduce from 'lodash/reduce'
import _sortBy from 'lodash/sortBy'

import { Day } from '@/app/types/Day'
import { DEFAULT_HOURS, OperatingHours, OperatingHoursSchedule } from '@/app/types/operatingHours'

export type CategoryCompleteOperatingHoursFormData = {
  operatingHours: {
    online: CategoryOperatingHoursFormData
    onsite: CategoryOperatingHoursFormData
  }
}

export type CategoryOperatingHoursFormData = {
  regular: Record<
    Day,
    {
      isOpen: boolean
      periods: Array<OperatingHoursSchedule>
    }
  >
  exceptions: Array<{
    id: string
    isOpen: boolean
    periods: Array<OperatingHoursSchedule>
  }>
}

export const toCategoryOperatingHoursFormData = (operatingHours: OperatingHours): CategoryOperatingHoursFormData => {
  const regular = _mapValues(operatingHours.regular, (schedule) =>
    schedule.length ? { isOpen: true, periods: schedule } : { isOpen: false, periods: [{ ...DEFAULT_HOURS }] }
  )

  const exceptions = _map(operatingHours.exceptions, (schedule, id) =>
    schedule.length ? { id, isOpen: true, periods: schedule } : { id, isOpen: false, periods: [{ ...DEFAULT_HOURS }] }
  )

  return {
    regular,
    exceptions: _sortBy(exceptions, 'id'),
  }
}

export const toCategoryOperatingHours = (
  categorySingleOperatingHoursFormData: CategoryOperatingHoursFormData
): OperatingHours => {
  const regular = _mapValues(categorySingleOperatingHoursFormData.regular, (schedule) =>
    schedule.isOpen ? schedule.periods : []
  )

  const exceptions = _reduce(
    categorySingleOperatingHoursFormData.exceptions,
    (result, { id, isOpen, periods }) => ({ ...result, [id]: isOpen ? periods : [] }),
    {}
  )
  return {
    regular,
    exceptions,
  }
}
