import { UseMutationOptions, useQueryClient } from 'react-query'

import { AxiosError } from 'axios'
import { useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { QGConfigurationDto } from '@/app/qg/QGConfiguration.dto'
import { QG_QUERY_KEY } from '@/app/qg/useQGQuery'
import { useQGId } from '@/environment'

export type UpdateQGConfigurationRequest = Omit<QGConfigurationDto, 'operatingHours'>

export const useUpdateQGConfigurationMutation = (
  options?: UseMutationOptions<QGConfigurationDto, AxiosError, UpdateQGConfigurationRequest>
): UseMutationExtendedResult<QGConfigurationDto, UpdateQGConfigurationRequest> => {
  const queryClient = useQueryClient()
  const qgId = useQGId()

  return useMutation({
    ...options,
    path: `/v1/admin/qgs/${qgId}/configuration`,
    method: 'put',
    requireAuthentication: true,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(QG_QUERY_KEY)
      /* istanbul ignore next */
      return options?.onSuccess?.(data, variables, context)
    },
  })
}
