import React, { useState } from 'react'

import { Center, CenterProps, Image, ImageProps, Spinner } from '@chakra-ui/react'

import { useImageServiceURL } from '@/environment'
import { EmptyImage } from '@/ui/components/image/EmptyImage'

export type ImgixImageProps = {
  loadingImage?: boolean
  altText?: string
  backgroundHeight: CenterProps['height']
  backgroundWidth: CenterProps['width']
  id?: string
  heightInPixel: number
  widthInPixel: number
  path?: string
} & Omit<ImageProps, 'onClick'>

export const ImgixImage: React.FC<ImgixImageProps> = ({
  loadingImage,
  altText,
  backgroundHeight,
  backgroundWidth,
  id,
  heightInPixel,
  widthInPixel,
  path,
  ...props
}) => {
  const [notFound, setNotFound] = useState(false)

  const baseUrl = useImageServiceURL()

  return (
    <Center borderRadius='4' height={backgroundHeight} width={backgroundWidth}>
      {loadingImage ? (
        <Spinner />
      ) : (
        <Image
          data-testid='imgix-image'
          src={id ? `${baseUrl}/images/${path}/${id}?w=${widthInPixel}&h=${heightInPixel}` : undefined}
          onError={
            /* istanbul ignore next */
            () => setNotFound(true)
          }
          fallback={
            !id || notFound ? (
              <EmptyImage
                backgroundWidth={backgroundWidth}
                backgroundHeight={backgroundHeight}
                iconWidth={`${widthInPixel}px`}
                iconHeight={`${heightInPixel}px`}
                notFound={notFound}
              />
            ) : (
              <Spinner />
            )
          }
          alt={altText}
          borderRadius='4'
          {...props}
        />
      )}
    </Center>
  )
}
