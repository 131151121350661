import { SPACE } from '@district-qg/frontend-common'

export const orders = {
  url: '/orders',
  title: 'Commandes',
  orderFilterDate: '{{date, LL}}',
  emptyOrders: 'Aucune commande dans le système',
  price: '{{ value, currency }}',
  status: {
    cancelled: 'Annulé',
    confirmed: 'Confirmé',
    paid: 'Confirmé',
    paymentRejected: 'Rejeté',
    rejected: 'Rejeté',
  },
  tableActions: {
    search: {
      placeholder: 'Rechercher une commande',
      tooltip: 'Commande, nom, téléphone, adresse, appartement, code postal',
    },
    name: {
      placeholder: 'Filtrer par nom',
    },
    paymentStatus: {
      placeholder: 'Filtrer par statut du paiement',
    },
    posStatus: {
      placeholder: 'Filtrer par statut Veloce',
    },
    statusPlaceholder: 'Tous les statuts',
    hideAbandonedOrders: 'Masquer les commandes abandonnées',
  },
  tableContent: {
    creationDateTime: '{{ date, DD MMMM }}, {{ date, LT }}',
    creationTime: '{{ date, LT }}',
    columns: {
      veloceOrder: 'Commande',
      clientName: 'Nom du client',
      type: 'Mode',
      deliveryType: 'Livraison',
      takeoutType: 'Emporter',
      unknownType: 'Inconnu',
      creationDate: 'Date de création',
      creationTime: 'Créée à',
      items: 'items',
      sections: 'Terroirs',
      cost: 'Total $',
      paymentStatus: 'Paiement',
      posStatus: 'Veloce',
    },
  },
  modalContent: {
    header: {
      command: 'Commande',
      createdAt: 'Créée le {{ date, ll }} à {{ date, LT }}',
    },
    expectedDateTime: '{{ date, LT }}',
    apartment: 'app',
    confirm: 'Confirmer',
    delivery: 'Livraison',
    deliveryFees: 'Livraison',
    notFoundContent: 'Votre commande n’a pas été trouvée dans le système',
    notFoundHeader: 'Commande non trouvée',
    subTotal: 'Sous-total',
    takeout: 'Emporter',
    total: 'Total',
    gratuity: 'Pourboire',
    veloceOrderNumberFormPlaceholder: 'No de commande Véloce',
    veloceOrderNumberErrorValue: 'Erreur',
  },
  cancel: {
    title: 'Annulation',
    dialog: {
      title: 'Annuler la commande',
      inputLabel: 'Veuillez inscrire la raison de l’annulation',
      warning:
        'Attention, l’annulation de commande ne supprime pas la commande de Veloce, elle rembourse uniquement le montant total de la facture au client.',
    },
    error: {
      title: 'Erreur',
      description: 'Une erreur s’est produite lors de l’annulation de la commande. Veuillez réessayer à nouveau.',
    },
  },
  refund: {
    title: 'Remboursement',
    dialog: {
      title: 'Rembourser la commande',
      reason: { label: 'Veuillez inscrire la raison du remboursement' },
      amount: { label: 'Montant du remboursement (maximum: {{ amount, currency }})' },
      warning:
        'Attention, le remboursement complet d’une commande ne supprime pas la commande de Veloce, il rembourse uniquement le montant total de la facture au client.',
    },
    error: {
      title: 'Erreur',
      description: 'Une erreur s’est produite lors du remboursement de la commande. Veuillez réessayer à nouveau.',
    },
  },
  transactionDetails: {
    title: 'Historique des transactions',
    date: '{{ date, L LT }}',
    transactionNumber: `Transaction${SPACE.NNBSP}:`,
    invoiceNumber: `Facture${SPACE.NNBSP}:`,
    error: 'Erreur de transaction',
  },
}
