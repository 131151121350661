import React from 'react'

import { Text } from '@chakra-ui/react'
import { ButtonAdd } from '@district-qg/ui'

import { HStackItem } from '@/ui/components/hstackItem/HStackItem'

export type CallForInputProps = {
  text: string
  onClick: () => void
}

export const CallForInput: React.FC<CallForInputProps> = ({ text, onClick }) => {
  return (
    <HStackItem onClick={onClick} fontStyle='italic' alignItems='baseline'>
      <Text fontSize='lg' color='gray.300'>
        {text}
      </Text>
      <ButtonAdd colorScheme='black' size='xs' />
    </HStackItem>
  )
}
