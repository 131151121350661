import React, { FC, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { Box, Center, Text, VStack } from '@chakra-ui/react'
import { FileUploadCloudIcon } from '@district-qg/ui'

import { ImageUploadRequest, useImageUpload } from '@/app/media/useImageUpload'
import { ImageUploadStatus } from '@/ui/components/imageUploadStatus/ImageUploadStatus'

const ALLOWED_CONTENT_TYPE = 'image/*'

const baseStyle = {
  backgroundColor: '#fafafa',
  borderColor: '#C3C4C6',
  borderRadius: 2,
  borderStyle: 'dashed',
  borderWidth: 2,
  color: '#C3C4C6',
  cursor: 'pointer',
  outline: 'none',
  padding: '20px',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#3182CE',
}

const rejectStyle = {
  borderColor: '#E53E3E',
}

type ImageUploadDropzoneProps = {
  imageUploadRequest: ImageUploadRequest
}

export const ImageUploadDropzone: FC<ImageUploadDropzoneProps> = ({ imageUploadRequest }) => {
  const { t } = useTranslation()
  const { onDrop, uploadStatus } = useImageUpload(imageUploadRequest)
  const { getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles } = useDropzone({
    accept: ALLOWED_CONTENT_TYPE,
    onDrop,
    maxFiles: 1,
    multiple: false,
  })
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  )

  return (
    <Box>
      <aside>
        <ImageUploadStatus status={uploadStatus} files={acceptedFiles} />
      </aside>
      <Box {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Center>
          <VStack>
            <Box>
              <FileUploadCloudIcon w='7' h='7' />
            </Box>
            <Box>
              {isDragActive ? (
                <Text>{t('imageUpload.dropzone.dragMessage')}</Text>
              ) : (
                <Text>{t('imageUpload.dropzone.message')}</Text>
              )}
            </Box>
          </VStack>
        </Center>
      </Box>
    </Box>
  )
}
