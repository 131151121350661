import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Badge } from '@chakra-ui/react'

import { OrderPaymentStatus, OrderPosStatus } from '@/app/order/Order.model'

interface OrderStatusBadgeProps {
  status?: OrderPosStatus | OrderPaymentStatus
}

const OrderStatusBadgeImpl: React.FC<OrderStatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation('orders')
  if (!status) {
    return null
  }
  const orderStatusBadgeColor = {
    [OrderPosStatus.confirmed]: { background: 'success.bg', text: 'black', label: t('status.confirmed') },
    [OrderPosStatus.rejected]: { background: 'error.bg', text: 'white', label: t('status.rejected') },
    [OrderPaymentStatus.cancelled]: { background: 'error.bg', text: 'white', label: t('status.cancelled') },
    [OrderPaymentStatus.paid]: { background: 'success.bg', text: 'black', label: t('status.paid') },
    [OrderPaymentStatus.rejected]: { background: 'error.bg', text: 'white', label: t('status.paymentRejected') },
  }
  return (
    <Badge
      fontSize='xs'
      backgroundColor={orderStatusBadgeColor[status].background}
      textColor={orderStatusBadgeColor[status].text}
      textTransform='uppercase'
    >
      {orderStatusBadgeColor[status].label}
    </Badge>
  )
}

export const OrderStatusBadge = React.memo(OrderStatusBadgeImpl)
