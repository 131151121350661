import React, { ReactNode } from 'react'

import {
  Box,
  BoxProps,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
  ThemingProps,
  UseModalProps,
} from '@chakra-ui/react'

import { CloseButton } from '@/ui/components/closeButton/CloseButton'

export const EditModalHeader: React.FC = ({ children }) => (
  <GridItem pl={8} pt={8} pr={4} gridArea='header'>
    {children}
  </GridItem>
)

type EditModalBodyProps = {
  includeFooter?: boolean
}

export const EditModalBody: React.FC<EditModalBodyProps> = ({ includeFooter = false, children }) => (
  <GridItem pl={8} pb={8} pr={4} gridArea={includeFooter ? 'body' : '2 / 1 / -2 / -2'}>
    {children}
  </GridItem>
)

export const EditModalHeaderSideBar: React.FC = ({ children }) => (
  <GridItem pt={8} pr={8} pl={4} gridArea='headerSidebar'>
    {children}
  </GridItem>
)

export const EditModalSideBar: React.FC = ({ children }) => (
  <GridItem pr={8} pl={4} gridArea='sidebar'>
    {children}
  </GridItem>
)

export const EditModalActions: React.FC = ({ children }) => (
  <GridItem pb={8} pr={8} pl={4} gridArea='actions' display='flex' alignItems='flex-end'>
    {children}
  </GridItem>
)

export type EditModalProps = {
  size?: ThemingProps<'Modal'>['size']
  sideBarColor?: BoxProps['bg']
  scrollBehavior?: ModalProps['scrollBehavior']
  children: ReactNode
} & UseModalProps

export const EditModal: React.FC<EditModalProps> = ({
  size = '4xl',
  sideBarColor = 'steel.light',
  children,
  onClose,
  scrollBehavior,
  ...useModalProps
}) => (
  <Modal size={size} onClose={onClose} scrollBehavior={scrollBehavior} {...useModalProps}>
    <ModalOverlay />
    <ModalContent overflow={scrollBehavior === 'inside' ? 'scroll' : undefined}>
      <Grid
        templateAreas='"header headerSidebar" "body sidebar" "body actions"'
        templateRows='repeat(3, auto)'
        templateColumns='2fr 1fr'
        gap={0}
      >
        <Box borderRightRadius='md' gridColumn='-2/-1' gridRow='1/-1' bg={sideBarColor}>
          <Flex justify='flex-end' margin={2} gridColumn='-1' gridRow='1'>
            <CloseButton onClick={onClose} />
          </Flex>
        </Box>
        {children}
      </Grid>
    </ModalContent>
  </Modal>
)
