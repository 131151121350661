import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Text } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { useQGQuery } from '@/app/qg/useQGQuery'
import { useSynchronizeItems } from '@/app/qg/useSynchronizeItems'

export const SynchronizationButton: React.FC = () => {
  const { syncItems, isLoading, isSyncing } = useSynchronizeItems()
  const { t } = useTranslation()
  const { data } = useQGQuery()

  return (
    <Box align='center'>
      <ButtonQg
        data-testid='Synchronization-Button'
        w='100%'
        mb='2'
        buttonType='primary'
        colorScheme='blue'
        onClick={() => syncItems()}
        isLoading={isLoading}
        isDisabled={isSyncing}
      >
        {isSyncing ? t('action.synchronizeInProgress') : t('action.synchronize')}
      </ButtonQg>
      <Text fontSize='xs' fontStyle='italic'>
        {t('action.lastItemsSyncDateTime')}
        {data?.lastItemsSyncDateTime.toLocaleTimeString('en-CA')}
      </Text>
    </Box>
  )
}
