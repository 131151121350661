import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { Artisan, buildModel as buildArtisanModel } from '@/app/artisan/Artisan.model'
import { CategoryDto } from '@/app/category/Category.dto'
import { buildModel as buildGroupModel, Group } from '@/app/group/Group.model'
import { Image } from '@/app/types/image'

import { OperatingHours } from '../types/operatingHours'

export type CategoryType = 'restaurant' | 'market'

export interface Category {
  readonly id: string
  readonly qgId: string
  readonly sectionId: string
  readonly active: boolean
  readonly mainImage?: Image
  readonly artisans: ReadonlyArray<Artisan>
  readonly groups: ReadonlyArray<Group>
  readonly title: string
  readonly subtitle: string
  readonly description: string
  readonly operatingHours: {
    online: OperatingHours
    onsite: OperatingHours
  }
}

export type CategoryOperatingHoursType = keyof Category['operatingHours']

export class CategoryModel implements Category {
  readonly artisans = buildArtisanModel(this.data.artisans, this.locale)

  readonly groups = buildGroupModel(this.data.groups, this.locale)

  readonly mainImage = this.data.mainImage && {
    id: this.data.mainImage.id,
    altText: localize(this.data.mainImage.altText, this.locale),
  }

  constructor(readonly data: Readonly<CategoryDto>, private readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get qgId(): string {
    return this.data.qgId
  }

  get sectionId(): string {
    return this.data.sectionId
  }

  get active(): boolean {
    return this.data.active
  }

  get title(): string {
    return localize(this.data.title, this.locale)
  }

  get subtitle(): string {
    return localize(this.data.subtitle, this.locale, 'category subtitle')
  }

  get description(): string {
    return localize(this.data.description, this.locale, 'category description')
  }

  get operatingHours(): Category['operatingHours'] {
    return this.data.operatingHours
  }
}

export function buildCategoryModel(data: CategoryDto, locale: SupportedLocales): Category
export function buildCategoryModel(data: CategoryDto[], locale: SupportedLocales): Category[]
export function buildCategoryModel(data: CategoryDto[] | CategoryDto, locale: SupportedLocales): Category[] | Category {
  return Array.isArray(data)
    ? data.map((category) => buildCategoryModel(category, locale))
    : new CategoryModel(data, locale)
}
