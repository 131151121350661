import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Heading, HStack, useDisclosure } from '@chakra-ui/react'
import { ButtonAdd } from '@district-qg/ui'

import { useQGQuery } from '@/app/qg/useQGQuery'
import { ContentHeader } from '@/ui/layout/components/ContentHeader'
import { CreateSectionModal } from '@/ui/pages/sections/components/modals/create/CreateSectionModal'
import { Sections } from '@/ui/pages/sections/components/Sections'

export const SectionListPage: React.FC = () => {
  const { t } = useTranslation('sections')
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { data: qg } = useQGQuery()

  if (!qg) return null

  return (
    <>
      <ContentHeader>
        <HStack alignItems='baseline' spacing='3'>
          <Heading size='lg' fontWeight='normal'>
            {t('title')}
          </Heading>
          <ButtonAdd colorScheme='white' size='2xs' onClick={onOpen} />
        </HStack>
      </ContentHeader>
      <Sections sections={qg.restaurantZone.sections} restaurantZoneId={qg.restaurantZone.id} />
      <CreateSectionModal isOpen={isOpen} onClose={onClose} restaurantZoneId={qg.restaurantZone.id} />
    </>
  )
}
