import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react'

import { S3PresignedUrlRequest } from '@/app/media/useCreateS3PresignedUrlMutation'
import { CreateSectionRequest } from '@/app/qg/useCreateSectionMutation'
import { ImgixImage } from '@/ui/components/image/ImgixImage'
import { ImageUploadDropzone } from '@/ui/components/imageUploadDropzone/ImageUploadDropzone'

export const SectionModalForm: React.FC = () => {
  const { t } = useTranslation(['sections', 'common'])

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<CreateSectionRequest>()
  const s3PresignedUrlRequest: S3PresignedUrlRequest = {
    type: 'background',
    target: 'sections',
  }
  const watchImageId = watch('imageId')
  /* istanbul ignore next */
  const setImageId = (newImageId: string) => {
    setValue('imageId', newImageId)
  }

  return (
    <Stack spacing='6'>
      <FormControl id='title' isRequired isInvalid={!!errors.name}>
        <FormLabel size='xs' fontWeight='semibold' color='gray'>
          {t('create.title')}
        </FormLabel>
        <Input data-testid='title-input' type='text' {...register('name.fr', { required: true })} />
        <FormErrorMessage>{t('common:error.fieldRequired')}</FormErrorMessage>
      </FormControl>
      <Stack spacing='6' flex={1} align='center'>
        <ImgixImage
          path='sections/background'
          id={watchImageId}
          backgroundHeight='200px'
          backgroundWidth='200px'
          heightInPixel={200}
          widthInPixel={200}
        />
        <FormControl id='imageUpload'>
          <ImageUploadDropzone imageUploadRequest={{ s3PresignedUrlRequest, setImageId }} />
        </FormControl>
      </Stack>
    </Stack>
  )
}
