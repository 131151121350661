import { useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common/src'

export type S3PresignedUrlResponse = {
  readonly presignedUrl: string
  readonly imageId: string
}

export type S3PresignedUrlRequest = {
  readonly target: 'categories' | 'groups' | 'items' | 'qg' | 'sections'
  readonly type: 'background' | 'main'
}

export const useCreateS3PresignedUrlMutation = (): UseMutationExtendedResult<
  S3PresignedUrlResponse,
  S3PresignedUrlRequest
> => {
  return useMutation<S3PresignedUrlResponse, S3PresignedUrlRequest>({
    path: '/v1/admin/media/upload/image/presigned-url',
    method: 'post',
  })
}
