import { useQueryClient } from 'react-query'

import { useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { CategoryDto } from '@/app/category/Category.dto'
import { Category } from '@/app/category/Category.model'
import { CATEGORIES_QUERY_KEY } from '@/app/category/useCategoriesQuery'

export type UpdateCategoryRequest = Partial<
  Pick<CategoryDto, 'active' | 'description' | 'mainImage' | 'subtitle' | 'title' | 'sectionId' | 'operatingHours'>
>

export const usePatchCategoryMutation = (
  categoryId: Category['id']
): UseMutationExtendedResult<CategoryDto, UpdateCategoryRequest> => {
  const queryClient = useQueryClient()
  return useMutation<CategoryDto, UpdateCategoryRequest>({
    path: `/v1/admin/categories/${categoryId}`,
    method: 'patch',
    onSuccess: async () => {
      await queryClient.invalidateQueries(CATEGORIES_QUERY_KEY)
    },
  })
}
