import dayjs from 'dayjs'
import { useModelBuilderWithCurrentLocale, useQuery, UseQueryExtendedResult } from '@district-qg/frontend-common'

import { OrderDto } from '@/app/order/Order.dto'
import { buildModel, Order } from '@/app/order/Order.model'

type UseOrdersQueryParams = { from?: Date; to?: Date }

function fromDate(date?: Date) {
  return date ? { fromDate: encodeURIComponent(dayjs(date).toISOString()) } : {}
}

function toDate(date?: Date) {
  return date ? { toDate: encodeURIComponent(dayjs(date).toISOString()) } : {}
}

export const ORDERS_QUERY_KEY = 'orders'

export const useOrdersQuery = ({ from, to }: UseOrdersQueryParams): UseQueryExtendedResult<Order[], OrderDto[]> => {
  const mapper = useModelBuilderWithCurrentLocale(buildModel)

  return useQuery({
    queryKey: [ORDERS_QUERY_KEY, from, to],
    path: '/v1/admin/orders',
    mapper,
    params: { ...fromDate(from), ...toDate(to) },
  })
}
