import React from 'react'

import { Category } from '@/app/category/Category.model'
import { EditModal, EditModalProps } from '@/ui/components/editModal/EditModal'
import { UpdateGroupsModalBody } from '@/ui/pages/categories/update/groups/UpdateGroupsModalBody'

export type UpdateGroupsModalProps = Omit<EditModalProps, 'sideBarColor' | 'children'> & {
  categoryId: Category['id']
}

export const UpdateGroupsModal: React.FC<UpdateGroupsModalProps> = ({ categoryId, onClose, ...modalProps }) => {
  return (
    <EditModal onClose={onClose} {...modalProps} sideBarColor='white'>
      <UpdateGroupsModalBody categoryId={categoryId} onSave={onClose} />
    </EditModal>
  )
}
