import _deburr from 'lodash/deburr'
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'
import _orderBy from 'lodash/orderBy'
import _overEvery from 'lodash/overEvery'
import _split from 'lodash/split'
import _stubTrue from 'lodash/stubTrue'

import { Order, OrderPaymentStatus, OrderPosStatus } from '@/app/order/Order.model'

type SortedFilteredOrdersOptions = {
  posStatusFilter?: OrderPosStatus
  paymentStatusFilter?: OrderPaymentStatus
  searchFilter?: string
  hideAbandonedOrders?: boolean
}

type OrderPredicate = (order: Order) => boolean

function predicate({
  posStatusFilter,
  paymentStatusFilter,
  searchFilter,
  hideAbandonedOrders,
}: SortedFilteredOrdersOptions): OrderPredicate {
  const posPredicate: OrderPredicate = ({ posStatus }) => posStatus === posStatusFilter
  const paymentPredicate: OrderPredicate = ({ paymentStatus }) => paymentStatus === paymentStatusFilter

  const searchStrings = _split(_deburr(searchFilter).toLowerCase(), /\s+/)
  const searchPredicate: OrderPredicate = ({ orderNumber, customer }) =>
    searchStrings.every(
      (token) =>
        _includes(String(orderNumber), token) ||
        _includes(_deburr(customer.firstName).toLowerCase(), token) ||
        _includes(_deburr(customer.lastName).toLowerCase(), token) ||
        _includes(_deburr(customer.phoneNumber).toLowerCase(), token) ||
        _includes(_deburr(customer.streetAddress).toLowerCase(), token) ||
        _includes(_deburr(customer.postalCode).toLowerCase(), token) ||
        _includes(_deburr(customer.civicNumber).toLowerCase(), token) ||
        _includes(_deburr(customer.apartmentNumber).toLowerCase(), token)
    )

  const withStatusPredicate: OrderPredicate = ({ posStatus, paymentStatus }) =>
    !(posStatus === undefined && paymentStatus === undefined)

  return _overEvery(
    posStatusFilter ? posPredicate : _stubTrue,
    paymentStatusFilter ? paymentPredicate : _stubTrue,
    searchFilter ? searchPredicate : _stubTrue,
    hideAbandonedOrders ? withStatusPredicate : _stubTrue
  )
}

export const useSortedFilteredOrders = (
  options: SortedFilteredOrdersOptions
): ((orders?: ReadonlyArray<Order>) => ReadonlyArray<Order>) => {
  return (orders) => _orderBy(_filter(orders, predicate(options)), ['creationTime'], ['desc'])
}
