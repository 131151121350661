import * as React from 'react'
import { useTranslation } from 'react-i18next'

import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import { Badge, Box, HStack, Icon, Text, Tooltip } from '@chakra-ui/react'
import { useAppFeatures } from '@district-qg/frontend-common'
import { ClockIcon, EllipseIcon } from '@district-qg/ui'

import { Category } from '@/app/category/Category.model'
import { Item } from '@/app/item/Item.model'
import { TableColumn } from '@/ui/pages/items/components/tableWithSearch/TableContent'

import { useItemList } from '../ItemListContext'

const TEXT_MAX_WIDTH = 'xs'

const CategoryNameCell: React.FC<{ categoryId: Category['id'] }> = ({ categoryId }) => {
  const { categories } = useItemList()
  const category = _find(categories, { id: categoryId })
  if (!category) return null
  return (
    <Text maxWidth={TEXT_MAX_WIDTH} isTruncated>
      {category.title}
    </Text>
  )
}

const GroupNamesCell: React.FC<{ categoryId: Category['id']; groupIds: Item['groupIds'] }> = ({
  categoryId,
  groupIds,
}) => {
  const { categories } = useItemList()
  const category = _find(categories, { id: categoryId })
  if (!category) return null

  const groupNames = category.groups.filter((group) => groupIds.includes(group.id)).map((group) => group.name)
  return (
    <Text maxWidth={TEXT_MAX_WIDTH} isTruncated>
      {groupNames.join(', ')}
    </Text>
  )
}

export const useItemTableColumns = (): TableColumn[] => {
  const { t } = useTranslation('items')
  const { onSiteItemsActivationEnabled } = useAppFeatures()

  return [
    {
      id: 'name',
      header: t('tableContent.columns.name'),
      cell({ name, isNew, operatingHours }) {
        return (
          <Box maxWidth={TEXT_MAX_WIDTH}>
            <HStack>
              <Text isTruncated>{name}</Text>
              {isNew && (
                <Badge fontSize='xs' colorScheme='orange'>
                  {t('status.new')}
                </Badge>
              )}
              {operatingHours ? (
                <Tooltip label={t('status.scheduledAvailability')}>
                  <span>
                    <Icon as={ClockIcon} h={4} w={4} display='block' />
                  </span>
                </Tooltip>
              ) : (
                ''
              )}
            </HStack>
          </Box>
        )
      },
      alignment: 'flex-start',
    },
    {
      id: 'online',
      header: t('tableContent.columns.online'),
      cell({ isActiveOnline }) {
        return isActiveOnline && <EllipseIcon fontSize='12' color='steel.500' />
      },
      alignment: 'center',
    },
    ...(onSiteItemsActivationEnabled
      ? [
          {
            id: 'onSite',
            header: t('tableContent.columns.onSite'),
            cell({ isActiveOnSite }) {
              return isActiveOnSite && <EllipseIcon fontSize='12' color='steel.500' />
            },
            alignment: 'center',
          } as TableColumn,
          {
            id: 'isChefExperience',
            header: t('tableContent.columns.isChefExperience'),
            cell({ isChefExperience }) {
              return isChefExperience && <EllipseIcon fontSize='12' color='steel.500' />
            },
            alignment: 'center',
          } as TableColumn,
        ]
      : []),
    {
      id: 'price',
      header: t('tableContent.columns.price'),
      cell: (item) => t('common:price', { value: item.external.price.amount }),
      alignment: 'flex-end',
    },
    {
      id: 'category',
      header: t('tableContent.columns.category'),
      cell({ categoryId }) {
        return categoryId ? <CategoryNameCell categoryId={categoryId} /> : ''
      },
      alignment: 'flex-start',
    },
    {
      id: 'group',
      header: t('tableContent.columns.groups'),
      cell({ categoryId, groupIds }) {
        return categoryId && !_isEmpty(groupIds) ? <GroupNamesCell categoryId={categoryId} groupIds={groupIds} /> : ''
      },
      alignment: 'flex-start',
    },
    {
      id: 'createdAt',
      header: t('tableContent.columns.createdAt'),
      cell: ({ createdAt }) => t('creationDate', { date: new Date(createdAt) }),
      alignment: 'flex-start',
    },
  ]
}
