import { UseToastOptions } from '@chakra-ui/react'

export const getToastConfiguration = (
  title: string,
  description: string,
  status: 'success' | 'error'
): UseToastOptions => ({
  title,
  description,
  status,
  duration: 5000,
  isClosable: true,
  position: 'top',
})
