import { useCallback, useState } from 'react'

import { captureException } from '@sentry/react'

import { S3PresignedUrlRequest, useCreateS3PresignedUrlMutation } from '@/app/media/useCreateS3PresignedUrlMutation'
import { usePutImageToS3Mutation } from '@/app/media/usePutImageToS3Mutation'
import { UploadStatus } from '@/ui/components/imageUploadStatus/ImageUploadStatus'

export type ImageUploadRequest = {
  s3PresignedUrlRequest: S3PresignedUrlRequest
  setImageId: (imageId: string) => void
}

export type ImageUploadResponse = {
  onDrop: (acceptedFiles: File[]) => void
  uploadStatus: UploadStatus
}

export function useImageUpload(imageUploadRequest: ImageUploadRequest): ImageUploadResponse {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('idle')
  const { mutateAsync: createS3PresignedUrl } = useCreateS3PresignedUrlMutation()
  const { mutateAsync: putImageToS3 } = usePutImageToS3Mutation()

  const onDrop = useCallback(
    async (droppedFiles: File[]) => {
      setUploadStatus('loading')
      if (droppedFiles.length !== 1) {
        setUploadStatus('error')
        return
      }
      try {
        const data = await createS3PresignedUrl(imageUploadRequest.s3PresignedUrlRequest)
        await putImageToS3({ url: data.presignedUrl, file: droppedFiles[0] })
        setUploadStatus('success')
        imageUploadRequest.setImageId(data.imageId)
      } catch (error) {
        captureException(error)
        setUploadStatus('error')
      }
    },
    [createS3PresignedUrl, imageUploadRequest, putImageToS3]
  )

  return {
    onDrop,
    uploadStatus,
  }
}
