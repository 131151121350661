import React from 'react'

import { VStack } from '@chakra-ui/react'

import { CategoryOperatingHoursType } from '@/app/category/Category.model'
import { OperatingHoursExceptionForm } from '@/ui/pages/operatingHours/components/operatingHoursExceptionForm/OperatingHoursExceptionForm'
import { OperatingHoursNormalHourForm } from '@/ui/pages/operatingHours/components/operatingHoursNormalHourForm/OperatingHoursNormalHourForm'

type CategoryOperatingHoursFormProps = {
  type: CategoryOperatingHoursType
}

export const CategoryOperatingHoursForm: React.FC<CategoryOperatingHoursFormProps> = ({ type }) => {
  return (
    <VStack spacing={8} alignItems='flex-start' minWidth='xl' data-testid={`CategoryOperatingHoursForm-${type}`}>
      <OperatingHoursNormalHourForm type={type} />
      <OperatingHoursExceptionForm type={type} />
    </VStack>
  )
}
