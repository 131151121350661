import _mapValues from 'lodash/mapValues'
import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { QGDto } from '@/app/qg/QG.dto'
import { buildSectionModel, Section } from '@/app/section/Section.model'
import { ZoneDto } from '@/app/zone/Zone.dto'
import { buildZoneModel, Zone } from '@/app/zone/Zone.model'

import { MissingZoneTypeError } from './MissingZoneTypeError'

const getZoneDto = (type: string, data: QGDto): ZoneDto => {
  const zone = data.foodDistrict.zones.find((z) => z.type === type)
  if (!zone) throw new MissingZoneTypeError(type)
  return zone
}

export interface QG {
  readonly id: string
  readonly isFetchingItems: boolean
  readonly lastItemsSyncDateTime: Date
  readonly marketZone: Zone
  readonly name: string
  readonly restaurantZone: Zone
  readonly sections: Record<string, Section>
}

export class QGModel implements QG {
  readonly lastItemsSyncDateTime = new Date(this.data.lastItemsSyncDateTime)

  readonly restaurantZone = buildZoneModel(getZoneDto('restaurant', this.data), this.locale)

  readonly marketZone = buildZoneModel(getZoneDto('market', this.data), this.locale)

  readonly sections = _mapValues(this.data.sections, (section) => buildSectionModel(section, this.locale))

  constructor(private readonly data: Readonly<QGDto>, private readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get name(): string {
    return localize(this.data.name, this.locale)
  }

  get isFetchingItems(): boolean {
    return this.data.isFetchingItems
  }
}

export function buildQGModel(data: QGDto, locale: SupportedLocales): QG {
  return new QGModel(data, locale)
}
