import React, { useState } from 'react'

import { Switch as ChakraSwitch } from '@chakra-ui/react'

type AbstractControlledSwitchProps = {
  id: string
  state: boolean
  onChange: (isChecked: boolean) => void
  size?: 'sm' | 'md' | 'lg'
  isDisabled?: boolean
}
const AbstractControlledSwitch: React.FC<AbstractControlledSwitchProps> = ({
  id,
  state,
  onChange,
  size,
  isDisabled,
}) => (
  <ChakraSwitch
    id={id}
    data-testid='switch'
    data-props-is_checked={state}
    isChecked={state}
    isDisabled={isDisabled ?? false}
    size={size ?? 'md'}
    onChange={(e) => {
      onChange(e.target.checked)
    }}
  />
)

export type ControlledSwitchProps = AbstractControlledSwitchProps
export const ControlledSwitch: React.FC<ControlledSwitchProps> = AbstractControlledSwitch

export type SwitchProps = Omit<AbstractControlledSwitchProps, 'state' | 'onChange'> & {
  initialValue?: boolean
}
export const Switch: React.FC<SwitchProps> = ({ initialValue, ...rest }) => {
  const [state, setState] = useState(initialValue ?? false)
  const toggle = () => setState(!state)

  return <AbstractControlledSwitch state={state} onChange={toggle} {...rest} />
}
