import React from 'react'

import { Box, BoxProps, CenterProps, Icon } from '@chakra-ui/react'
import { AddImageIcon } from '@district-qg/ui'

import { ImgixImage } from '@/ui/components/image/ImgixImage'

export type ImagePickerProps = BoxProps & {
  loading?: 'true' | 'false'
  altText?: string
  backgroundHeight: CenterProps['height']
  backgroundWidth: CenterProps['width']
  id?: string
  heightInPixel: number
  widthInPixel: number
  path?: string
  showAddIcon?: boolean
}

export const ImagePicker: React.FC<ImagePickerProps> = ({
  loading,
  altText,
  backgroundHeight,
  backgroundWidth,
  id,
  heightInPixel,
  widthInPixel,
  path,
  showAddIcon = true,
  ...rest
}) => {
  return (
    <Box
      position='relative'
      m='2'
      _hover={{
        cursor: 'pointer',
      }}
      {...rest}
    >
      <ImgixImage
        loadingImage={loading === 'true'}
        altText={altText}
        id={id}
        backgroundHeight={backgroundHeight}
        backgroundWidth={backgroundWidth}
        heightInPixel={heightInPixel}
        widthInPixel={widthInPixel}
        path={path}
      />
      {showAddIcon && (
        <Icon
          aria-label='upload-image'
          p='1'
          as={AddImageIcon}
          color='steel.200'
          background='gray.100'
          borderRadius='5'
          position='absolute'
          bottom={1}
          right={1}
          h={8}
          w={8}
        />
      )}
    </Box>
  )
}
