import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { ArtisanDto } from '@/app/artisan/Artisan.dto'

export interface Artisan {
  readonly id: string
  readonly name: string
}

export class ArtisanModel implements Artisan {
  constructor(private readonly data: Readonly<ArtisanDto>, private readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get name(): string {
    return localize(this.data.name, this.locale)
  }
}

export function buildModel(data: ArtisanDto, locale: SupportedLocales): Artisan
export function buildModel(data: ArtisanDto[], locale: SupportedLocales): Artisan[]
export function buildModel(data: ArtisanDto[] | ArtisanDto, locale: SupportedLocales): Artisan[] | Artisan {
  return Array.isArray(data) ? data.map((item) => buildModel(item, locale)) : new ArtisanModel(data, locale)
}
