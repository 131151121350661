import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Box, SimpleGrid } from '@chakra-ui/react'
import { ButtonQg } from '@district-qg/ui'

import { QGConfigurationDto } from '@/app/qg/QGConfiguration.dto'
import { useUpdateQGConfigurationMutation } from '@/app/qg/useUpdateQGConfigurationMutation'
import {
  fromDto,
  QGConfigurationFormData,
  toUpdateRequest,
} from '@/ui/pages/configuration/components/QGConfigurationFormData'

import { QGConfigurationFormContactDetailsCard } from './QGConfigurationFormContactDetailsCard'
import { QGConfigurationFormDeliveryCard } from './QGConfigurationFormDeliveryCard'
import { QGConfigurationFormEmailsCard } from './QGConfigurationFormEmailsCard'
import { QGConfigurationFormLinksCard } from './QGConfigurationFormLinksCard'
import { QGConfigurationFormOperationsCard } from './QGConfigurationFormOperationsCard'
import { QGConfigurationFormTaxesCard } from './QGConfigurationFormTaxesCard'

export type QGConfigurationFormProviderProps = {
  qg: QGConfigurationDto
}

export const QGConfigurationFormProvider: React.FC<QGConfigurationFormProviderProps> = ({ qg }) => {
  const { t } = useTranslation('configuration')
  const form = useForm<QGConfigurationFormData>({
    defaultValues: fromDto(qg),
    mode: 'onChange',
  })
  const { isLoading, mutate } = useUpdateQGConfigurationMutation({
    onSuccess: (data) => {
      form.reset(fromDto(data))
    },
  })
  const onSave = (data: QGConfigurationFormData) => {
    mutate(toUpdateRequest(data))
  }

  return (
    <FormProvider {...form}>
      <SimpleGrid columns={3} spacing={8} mt={4} mb={8}>
        <QGConfigurationFormDeliveryCard />
        <QGConfigurationFormEmailsCard />
        <QGConfigurationFormContactDetailsCard />
        <QGConfigurationFormLinksCard />
        <QGConfigurationFormTaxesCard />
        <QGConfigurationFormOperationsCard />
      </SimpleGrid>
      <Box>
        <ButtonQg
          data-testid='submit-button'
          buttonType='primary'
          bg='blue.500'
          isLoading={isLoading}
          isDisabled={!form.formState.isValid || !form.formState.isDirty}
          onClick={form.handleSubmit(onSave)}
        >
          {t('common:action.save')}
        </ButtonQg>
      </Box>
    </FormProvider>
  )
}
