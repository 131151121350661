import { useModelBuilderWithCurrentLocale, useQuery, UseQueryExtendedResult } from '@district-qg/frontend-common'

import { CategoryDto } from '@/app/category/Category.dto'
import { buildCategoryModel, Category } from '@/app/category/Category.model'

export const CATEGORIES_QUERY_KEY = 'categories'

export const useCategoriesQuery = (): UseQueryExtendedResult<ReadonlyArray<Category>, CategoryDto[]> => {
  const mapper = useModelBuilderWithCurrentLocale(buildCategoryModel)

  return useQuery({
    queryKey: CATEGORIES_QUERY_KEY,
    path: '/v1/admin/categories',
    mapper,
  })
}
