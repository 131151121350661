import * as React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { Box, BoxProps, Flex, Link, LinkProps, Text } from '@chakra-ui/react'
import { ChevronRightIcon } from '@district-qg/ui'

export interface SidebarLinkProps extends LinkProps {
  icon?: React.ReactElement
  avatar?: React.ReactElement
  opacity?: BoxProps['opacity']
  to: string
  text: string
}

export const SidebarLink: React.FC<SidebarLinkProps> = ({ text, icon, avatar, opacity, ...rest }) => {
  const boxOpacity = opacity ?? (avatar ? 1 : 0.5)

  return (
    <Box p={1}>
      <Link
        as={ReactRouterLink}
        marginEnd='2'
        fontSize='sm'
        display='flex'
        px='2'
        pt='0.5'
        pb='1'
        alignItems='center'
        rounded='md'
        cursor='pointer'
        _hover={{ color: 'white', bg: 'whiteAlpha.300' }}
        className='group'
        fontWeight='medium'
        transition='background .1s ease-out'
        {...rest}
      >
        <Flex
          w={4}
          h={4}
          mr={3}
          alignItems='center'
          justifyContent='center'
          opacity={boxOpacity}
          _groupHover={{ opacity: 1 }}
        >
          {avatar || icon || <ChevronRightIcon />}
        </Flex>
        <Text>{text}</Text>
      </Link>
    </Box>
  )
}
