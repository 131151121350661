import { useMutation, UseMutationResult } from 'react-query'

import axios, { AxiosError } from 'axios'

export const MEDIA_QUERY_KEY = 'media'
const ALLOWED_CONTENT_TYPE = 'image/*'

export type PutImageToS3Request = {
  file: File
  url: string
}

export const usePutImageToS3Mutation = (): UseMutationResult<void, AxiosError, PutImageToS3Request> => {
  const putImageToS3 = async (putImageToS3Request: PutImageToS3Request) => {
    await axios.put(putImageToS3Request.url, putImageToS3Request.file, {
      validateStatus: (status) => status === 200,
      headers: {
        'Content-Type': ALLOWED_CONTENT_TYPE,
      },
    })
  }
  return useMutation(MEDIA_QUERY_KEY, putImageToS3)
}
