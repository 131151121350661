import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { Category } from '@/app/category/Category.model'
import { Group } from '@/app/group/Group.model'
import { ItemDto, Price } from '@/app/item/Item.dto'
import { Image } from '@/app/types/image'
import { OperatingHours } from '@/app/types/operatingHours'

export type ItemOperatingHours = Pick<OperatingHours, 'regular'>

export interface Item {
  readonly isActiveOnline: boolean
  readonly isActiveOnSite: boolean
  readonly isChefExperience: boolean
  readonly isNew: boolean
  readonly categoryId?: Category['id']
  readonly createdAt: Date
  readonly description?: string
  readonly external: ItemExternal
  readonly externalId: number
  readonly id: string
  readonly name: string
  readonly groupIds: ReadonlyArray<Group['id']>
  readonly source: string
  readonly price: number
  readonly mainImage?: Image
  readonly stockManagement: ItemStockManagement
  readonly availabilities: ItemAvailabilities
  readonly operatingHours: ItemOperatingHours | null
}

type ItemAvailabilities = {
  startDate: Date | null
  endDate: Date | null
}

export type ItemStockManagement =
  | {
      active: true
      totalQuantity: number
      availableQuantity: number
      updatedAt?: Date
    }
  | {
      active: false
      totalQuantity?: number
      availableQuantity?: number
      updatedAt?: Date
    }

export type ItemExternal = {
  readonly name: string
  readonly price: Price
  readonly source: string
  readonly taxes: {
    readonly tps: boolean
    readonly tvq: boolean
  }
  readonly updatedAt: Date
}

export class ItemModel implements Item {
  readonly external = {
    ...this.data.external,
    name: localize(this.data.external.name, this.locale),
    updatedAt: new Date(this.data.external.updatedAt),
  }

  readonly mainImage = this.data.mainImage && {
    id: this.data.mainImage.id,
    altText: localize(this.data.mainImage.altText, this.locale),
  }

  constructor(readonly data: Readonly<ItemDto>, readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get isActiveOnline(): boolean {
    return this.data.isActiveOnline
  }

  get isActiveOnSite(): boolean {
    return this.data.isActiveOnSite
  }

  get isChefExperience(): boolean {
    return this.data.isChefExperience
  }

  get isNew(): boolean {
    return this.data.new
  }

  get createdAt(): Date {
    return new Date(this.data.createdAt)
  }

  get description(): string {
    return localize(this.data.description, this.locale)
  }

  get externalId(): number {
    return this.data.externalId
  }

  get name(): string {
    return localize([this.data.name, this.data.external.name], this.locale)
  }

  get price(): number {
    return this.data.external.price.amount
  }

  get source(): string {
    return this.data.external.source
  }

  get stockManagement(): ItemStockManagement {
    return this.data.stockManagement
  }

  get categoryId(): string {
    /* istanbul ignore next */
    return this.data.categoryId ?? ''
  }

  get groupIds(): ReadonlyArray<string> {
    /* istanbul ignore next */
    return this.data.groupIds ?? []
  }

  get operatingHours(): ItemOperatingHours | null {
    /* istanbul ignore next */
    return this.data.operatingHours
  }

  get availabilities(): ItemAvailabilities {
    /* istanbul ignore next */
    return {
      startDate: this.data.availabilities.startDate ? new Date(this.data.availabilities.startDate) : null,
      endDate: this.data.availabilities.endDate ? new Date(this.data.availabilities.endDate) : null,
    }
  }
}

export function buildModel(data: ItemDto, locale: SupportedLocales): Item
export function buildModel(data: ItemDto[], locale: SupportedLocales): Item[]
export function buildModel(data: ItemDto[] | ItemDto, locale: SupportedLocales): Item[] | Item {
  return Array.isArray(data) ? data.map((item) => buildModel(item, locale)) : new ItemModel(data, locale)
}
