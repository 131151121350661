import * as React from 'react'
import { Link } from 'react-router-dom'

import { Box, Flex, Heading, HStack, Icon, IconButtonProps } from '@chakra-ui/react'

import { useGetRoles } from '@/app/role/UserRolesProvider'
import { HOME_PAGE_PATH } from '@/routes'
import { MobileMenuButton } from '@/ui/layout/components/MobileMenuButton'
import { ScrollArea } from '@/ui/layout/components/ScrollArea'
import { SidebarNav } from '@/ui/layout/components/sidebar/SidebarNav'
import { SidebarUser } from '@/ui/layout/components/sidebar/SidebarUser'
import { SynchronizationButton } from '@/ui/layout/components/SynchronizationButton'
import { useMobileMenuState } from '@/ui/layout/hooks/useMobileMenuState'

type AdminLayoutProps = {
  logo?: IconButtonProps['as']
}

export const AdminLayout: React.FC<AdminLayoutProps> = ({ logo, children }) => {
  const { isOpen, toggle } = useMobileMenuState()
  const { isAdmin } = useGetRoles()

  return (
    <Flex minHeight='100vh' bg='bgColor' sx={{ '--sidebar-width': '14rem;', '--sidebar-pad-x': '1rem;' }}>
      {/* sidebar */}
      <Box
        as='nav'
        display='block'
        width='var(--sidebar-width)'
        height='full'
        left='0'
        py='6'
        px='var(--sidebar-pad-x)'
        color='gray.200'
        position='fixed'
      >
        <Flex direction='column' height='full'>
          <Link to={HOME_PAGE_PATH}>
            <HStack mb='8' pl='2'>
              <Icon as={logo} w='48px' h='auto' color='white' aria-label='Le QG' />
              <Heading as='h1' size='md' color='steel.light'>
                Sainte-Foy
              </Heading>
            </HStack>
          </Link>

          <ScrollArea flex='1' pb={4}>
            <SidebarNav />
          </ScrollArea>
          {isAdmin && <SynchronizationButton />}
          <SidebarUser />
        </Flex>
      </Box>
      {/* main section */}
      <Box
        flex='1'
        p={{ base: '0', md: '6' }}
        pl={{ md: 0 }}
        marginStart={{ md: 'var(--sidebar-width)' }}
        position='relative'
        left={isOpen ? 'var(--sidebar-width)' : '0'}
        transition='left 0.2s'
      >
        <Box maxW='2560px' bg='bgColor' height='100%' pb='6' rounded={{ md: 'lg' }}>
          <Flex direction='column' height='full' bg='white' rounded={{ md: 'lg' }} overflow='hidden'>
            <Box w='full' px='4' py='2' display={{ base: 'block', md: 'none' }}>
              <MobileMenuButton onClick={toggle} isOpen={isOpen} />
            </Box>
            <Flex direction='column' flex='1' p={{ base: 4, lg: 8 }}>
              {children}
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}
