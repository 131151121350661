import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Employee } from '@/app/employee/Employee.model'

import { EmployeeStatusBadge } from './EmployeeStatusBadge'

export type TableColumn = {
  id: string
  header: string
  cell: (item: Employee) => React.ReactNode
  alignment: 'flex-start' | 'center' | 'flex-end'
}

export const useEmployeesTableColumns = (): TableColumn[] => {
  const { t } = useTranslation('employees')

  return [
    {
      id: 'email',
      header: t('tableContent.columns.email'),
      cell: ({ email }) => email,
      alignment: 'flex-start',
    },
    {
      id: 'lastName',
      header: t('tableContent.columns.lastName'),
      cell: ({ lastName }) => lastName,
      alignment: 'flex-start',
    },
    {
      id: 'firstName',
      header: t('tableContent.columns.firstName'),
      cell: ({ firstName }) => firstName,
      alignment: 'flex-start',
    },
    {
      id: 'role',
      header: t('tableContent.columns.role'),
      cell({ role }) {
        return role ? t(`role.${role}`) : ''
      },
      alignment: 'flex-start',
    },
    {
      id: 'status',
      header: t('tableContent.columns.status'),
      cell({ status }) {
        return <EmployeeStatusBadge status={status} />
      },
      alignment: 'flex-start',
    },
  ]
}
