import React from 'react'

import { Center, CenterProps } from '@chakra-ui/react'
import { IconProps, ImageIcon, NotFoundImageIcon } from '@district-qg/ui'

export type EmptyImageProps = {
  backgroundHeight: CenterProps['height']
  backgroundWidth: CenterProps['width']
  iconHeight: IconProps['height']
  iconWidth: IconProps['width']
  notFound: boolean
} & Omit<IconProps, 'height' | 'width'>

export const EmptyImage: React.FC<EmptyImageProps> = ({
  backgroundHeight,
  backgroundWidth,
  iconWidth,
  iconHeight,
  notFound,
  ...props
}) => {
  return (
    <Center borderRadius='4' height={backgroundHeight} width={backgroundWidth} bg='gray.100'>
      {notFound ? (
        <NotFoundImageIcon
          aria-label='placeholder-image'
          color='steel.200'
          height={iconHeight}
          width={iconWidth}
          {...props}
        />
      ) : (
        <ImageIcon aria-label='placeholder-image' color='steel.200' height={iconHeight} width={iconWidth} {...props} />
      )}
    </Center>
  )
}
