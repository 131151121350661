import { SupportedLocales } from '@district-qg/frontend-common'

import { CustomerDto } from '@/app/customer/Customer.dto'

export interface Customer {
  readonly firstName: string
  readonly lastName: string
  readonly email: string
  readonly postalCode: string
  readonly civicNumber: string
  readonly apartmentNumber: string
  readonly streetAddress: string
  readonly city: string
  readonly phoneNumber: string
  readonly memberships: string[]
}

class CustomerModel implements Customer {
  constructor(private readonly data: Readonly<CustomerDto>, private readonly locale: SupportedLocales) {}

  get firstName(): string {
    return this.data.firstName
  }

  get lastName(): string {
    return this.data.lastName
  }

  get email(): string {
    return this.data.email
  }

  get postalCode(): string {
    return this.data.postalCode
  }

  get civicNumber(): string {
    return this.data.civicNumber
  }

  get apartmentNumber(): string {
    return this.data.apartmentNumber
  }

  get streetAddress(): string {
    return this.data.streetAddress
  }

  get city(): string {
    return this.data.city
  }

  get phoneNumber(): string {
    return this.data.phoneNumber
  }

  get memberships(): string[] {
    return this.data.memberships
  }
}

export function buildModel(data: CustomerDto, locale: SupportedLocales): Customer
export function buildModel(data: CustomerDto[], locale: SupportedLocales): Customer[]
export function buildModel(data: CustomerDto[] | CustomerDto, locale: SupportedLocales): Customer[] | Customer {
  return Array.isArray(data) ? data.map((customer) => buildModel(customer, locale)) : new CustomerModel(data, locale)
}
