import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import _noop from 'lodash/noop'
import { Grid, GridItem, Heading, HStack, Link, Text, useDisclosure, VStack } from '@chakra-ui/react'

import { Category } from '@/app/category/Category.model'
import { UpdateCategoryRequest, usePatchCategoryMutation } from '@/app/category/usePatchCategoryMutation'
import { Group } from '@/app/group/Group.model'
import { QG } from '@/app/qg/QG.model'
import { CallForInput } from '@/ui/components/callForInput/CallForInput'
import { LabeledSwitchWithDisruptive } from '@/ui/components/switch/SwitchWithDisruptive'
import { GroupView } from '@/ui/pages/categories/groups/GroupView'
import { CategoryImageHStackItem } from '@/ui/pages/categories/read/CategoryImageHStackItem'
import { GroupsList } from '@/ui/pages/categories/read/GroupsList'
import { ScheduleView } from '@/ui/pages/categories/schedules/ScheduleView'
import { UpdateGroupsModal } from '@/ui/pages/categories/update/groups/UpdateGroupsModal'
import { UpdateCategoryImageModal } from '@/ui/pages/categories/update/image/UpdateCategoryImageModal'
import { UpdateCategoryModal } from '@/ui/pages/categories/update/UpdateCategoryModal'

export type CategoryViewProps = {
  category: Category
  qg: QG
}

export const CategoryView: React.FC<CategoryViewProps> = ({ category, qg }) => {
  const { t } = useTranslation(['categories', 'common'])

  const {
    isOpen: isOpenUpdateCategoryModal,
    onClose: onCloseUpdateCategoryModal,
    onOpen: onOpenUpdateCategoryModal,
  } = useDisclosure()
  const {
    isOpen: isOpenUpdateCategoryRestaurantImageModal,
    onClose: onCloseUpdateCategoryRestaurantImageModal,
    onOpen: onOpenUpdateCategoryRestaurantImageModal,
  } = useDisclosure()
  const {
    isOpen: isOpenUpdateGroupsModal,
    onOpen: onOpenUpdateGroupsModal,
    onClose: onCloseUpdateGroupsModal,
  } = useDisclosure()

  const [selectedGroupId, setSelectedGroupId] = useState<Group['id']>('')
  const [isEditingSchedule, setIsEditingSchedule] = useState(false)

  React.useEffect(() => {
    setSelectedGroupId('')
    setIsEditingSchedule(false)
  }, [category.id])

  const { mutateAsync } = usePatchCategoryMutation(category.id)
  const mutateActiveStatus = async (newStatus: boolean) => mutateAsync({ active: newStatus })
  const updateCategoryImage = async (data: UpdateCategoryRequest) => mutateAsync({ ...data })

  return (
    <>
      <Grid
        rowGap={6}
        columnGap={12}
        templateAreas='"switch switch" "header detailsHeader" "self details"'
        templateRows='repeat(2, auto)'
        templateColumns='1fr 3fr'
      >
        <GridItem gridArea='switch'>
          <LabeledSwitchWithDisruptive
            key={category.id}
            id='is-active-category'
            initialValue={category.active}
            onConfirm={mutateActiveStatus}
            size='lg'
            data-testid='active-switch'
          />
        </GridItem>
        <GridItem gridArea='header' minHeight='8'>
          <Text fontSize='md'>
            {`${qg.sections[category.sectionId].name}`} / {category.subtitle}
          </Text>
          <Heading size='xl' lineHeight='none'>
            {category.title}
          </Heading>
          <HStack mt={2} spacing={4}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              color='blue.400'
              fontWeight='bold'
              fontSize='xs'
              onClick={onOpenUpdateCategoryModal}
              _hover={{ textDecoration: 'none' }}
            >
              {t('categories:profile')}
            </Link>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              color='blue.400'
              fontWeight='bold'
              fontSize='xs'
              onClick={() => {
                setSelectedGroupId('')
                setIsEditingSchedule(true)
              }}
              _hover={{ textDecoration: 'none' }}
              data-testid='schedule-link'
            >
              {t('operatingHours:schedule')}
            </Link>
          </HStack>
        </GridItem>
        <GridItem gridArea='self'>
          <VStack spacing={2}>
            {category.groups.length ? (
              <GroupsList
                category={category}
                openUpdateGroupsModal={onOpenUpdateGroupsModal}
                selectedGroupId={selectedGroupId}
                setSelectedGroupId={(groupId: Group['id']) => {
                  setSelectedGroupId(groupId)
                  setIsEditingSchedule(false)
                }}
                mb='4'
              />
            ) : (
              <CallForInput text={t('read.addGroups')} onClick={onOpenUpdateGroupsModal} />
            )}

            {category.mainImage ? (
              <CategoryImageHStackItem
                image={category.mainImage}
                type='main'
                onClick={onOpenUpdateCategoryRestaurantImageModal}
                text={t('read.addRestaurantPicture')}
              />
            ) : (
              <CallForInput text={t('read.addRestaurantPicture')} onClick={onOpenUpdateCategoryRestaurantImageModal} />
            )}

            <CallForInput text={t('read.addArtisanProfile')} onClick={_noop} />
          </VStack>
        </GridItem>
        <GroupView category={category} selectedGroupId={selectedGroupId} />
        <ScheduleView category={category} isEditingSchedule={isEditingSchedule} />
      </Grid>
      <UpdateCategoryModal
        category={category}
        isOpen={isOpenUpdateCategoryModal}
        onClose={onCloseUpdateCategoryModal}
      />
      <UpdateGroupsModal
        categoryId={category.id}
        isOpen={isOpenUpdateGroupsModal}
        onClose={onCloseUpdateGroupsModal}
        data-testid='update-group-modal'
      />
      <UpdateCategoryImageModal
        image={category.mainImage}
        headerText={t('read.addRestaurantPicture')}
        isOpen={isOpenUpdateCategoryRestaurantImageModal}
        onClose={onCloseUpdateCategoryRestaurantImageModal}
        onSubmit={updateCategoryImage}
      />
    </>
  )
}
