import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TimeField from 'react-simple-timefield/dist/index.js'

import { Input } from '@chakra-ui/react'

import { CategoryOperatingHoursType } from '@/app/category/Category.model'
import { Day } from '@/app/types/Day'
import { CategoryCompleteOperatingHoursFormData } from '@/ui/pages/operatingHours/components/CategoryOperatingHoursFormData'

type OperatingHoursNormalPeriodProps = {
  readonly day: Day
  readonly periodIndex: number
  readonly type: CategoryOperatingHoursType
}

export const OperatingHoursNormalPeriod: React.FC<OperatingHoursNormalPeriodProps> = ({ day, periodIndex, type }) => {
  const { t } = useTranslation('operatingHours')
  const { watch } = useFormContext<CategoryCompleteOperatingHoursFormData>()
  const [isOpen, openValue] = watch([
    `operatingHours.${type}.regular.${day}.isOpen`,
    `operatingHours.${type}.regular.${day}.periods.${periodIndex}.open`,
  ])

  return (
    <>
      <Controller
        name={`operatingHours.${type}.regular.${day}.periods.${periodIndex}.open`}
        rules={{
          required: isOpen,
          validate: (value) => !isOpen || !!value,
          deps: [`operatingHours.${type}.regular.${day}.periods.${periodIndex}.close`],
        }}
        render={({ field: { onChange, value, ref }, fieldState: { invalid } }) =>
          isOpen ? (
            <TimeField
              onChange={onChange}
              value={value}
              input={
                <Input data-testid={`${day}-${periodIndex}-open-timefield`} textAlign='center' isInvalid={invalid} />
              }
              inputRef={ref as () => HTMLInputElement}
              colon=':'
            />
          ) : (
            <Input
              data-testid={`${day}-${periodIndex}-open-timefield`}
              disabled
              placeholder={t('header.closed')}
              px={1}
              textAlign='center'
            />
          )
        }
      />
      <Controller
        name={`operatingHours.${type}.regular.${day}.periods.${periodIndex}.close`}
        rules={{
          required: isOpen,
          validate: (value) => !isOpen || value > openValue,
          deps: [`operatingHours.${type}.regular.${day}.periods.${periodIndex}.open`],
        }}
        render={({ field: { onChange, value, ref }, fieldState: { invalid } }) =>
          isOpen ? (
            <TimeField
              onChange={onChange}
              value={value}
              input={
                <Input data-testid={`${day}-${periodIndex}-close-timefield`} textAlign='center' isInvalid={invalid} />
              }
              inputRef={ref as () => HTMLInputElement}
              colon=':'
            />
          ) : (
            <Input
              data-testid={`${day}-${periodIndex}-close-timefield`}
              disabled
              placeholder={t('header.closed')}
              px={1}
              textAlign='center'
            />
          )
        }
      />
    </>
  )
}
