import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TimeField from 'react-simple-timefield/dist/index.js'

import { FormControl, FormLabel, Heading, Input, InputGroup, InputRightAddon, Stack } from '@chakra-ui/react'

import { Card, CardContent, CardHeader } from '@/ui/components/card/Card'
import { QGConfigurationFormData } from '@/ui/pages/configuration/components/QGConfigurationFormData'

export const QGConfigurationFormOperationsCard: React.FC = () => {
  const { t } = useTranslation('configuration')
  const { register } = useFormContext<QGConfigurationFormData>()

  return (
    <Card backgroundColor='gray.50'>
      <CardHeader>
        <Heading fontSize='2xl' fontWeight='normal'>
          {t('operations.title')}
        </Heading>
      </CardHeader>
      <CardContent pb={8}>
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>{t('operations.orderPreparationTime.label')}</FormLabel>
            <InputGroup>
              <Input
                data-testid='orderPreparationTime'
                {...register('orderPreparationTime', { valueAsNumber: true })}
                type='number'
                bg='white'
                maxW='10rem'
              />
              <InputRightAddon>{t('operations.orderPreparationTime.unit')}</InputRightAddon>
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>{t('operations.earliestOrderTime.label')}</FormLabel>
            <InputGroup>
              <Controller
                name='earliestOrderTime'
                rules={{ required: true }}
                render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
                  <TimeField
                    data-testid='earliestOrderTime'
                    onChange={onChange}
                    value={value}
                    input={<Input isInvalid={invalid} bg='white' maxW='10rem' />}
                    inputRef={ref as () => HTMLInputElement}
                    colon=':'
                  />
                )}
              />
              <InputRightAddon>{t('operations.earliestOrderTime.unit')}</InputRightAddon>
            </InputGroup>
          </FormControl>
        </Stack>
      </CardContent>
    </Card>
  )
}
