import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, FormLabel, HStack, Input, InputGroup, InputLeftElement, Select, Stack } from '@chakra-ui/react'
import { useAppFeatures } from '@district-qg/frontend-common'
import { SearchIcon } from '@district-qg/ui'

import { Category } from '@/app/category/Category.model'
import { Group } from '@/app/group/Group.model'
import { NO_CATEGORY_ID, StatusFilterValues } from '@/ui/pages/items/components/ItemTableFilter'

type TableActionsProps = {
  categories: ReadonlyArray<Category>
  categoryIdFilter?: string
  setCategoryIdFilter(value?: Category['id']): void
  setGroupIdFilter(value?: Group['id']): void
  setNameFilter(value?: string): void
  setStatusFilter(value?: StatusFilterValues): void
}

export const TableActions: React.FC<TableActionsProps> = ({
  categories,
  categoryIdFilter,
  setCategoryIdFilter,
  setGroupIdFilter,
  setNameFilter,
  setStatusFilter,
}) => {
  const { t } = useTranslation('items')
  const { onSiteItemsActivationEnabled } = useAppFeatures()

  const setCategoryIdFilterAndDeleteGroupIdFilter = (categoryId: Category['id'] | undefined) => {
    setCategoryIdFilter(categoryId)
    setGroupIdFilter(undefined)
  }

  return (
    <Stack spacing='4' direction={{ base: 'column', md: 'row' }} justify='space-between'>
      <HStack>
        <FormControl w={{ md: '250px' }} id='search'>
          <InputGroup size='sm'>
            <FormLabel srOnly>{t('tableActions.nameFilter')}</FormLabel>
            <InputLeftElement pointerEvents='none' color='gray.400'>
              <SearchIcon />
            </InputLeftElement>
            <Input
              data-testid='name-filter-input'
              onChange={(event) => setNameFilter(event.target.value)}
              rounded='base'
              type='search'
              placeholder={t('tableActions.nameFilter')}
            />
          </InputGroup>
        </FormControl>
        <Select
          data-testid='isActiveOnline-filter-select'
          w={{ base: '100px', md: '200px' }}
          rounded='base'
          size='sm'
          placeholder={t('tableActions.statusPlaceholder')}
          onChange={(event) => setStatusFilter(event.target.value as StatusFilterValues)}
        >
          <option key='isNew' value='isNew'>
            {t('status.new')}
          </option>
          <option key='isActiveOnline' value='isActiveOnline'>
            {t('status.isActiveOnline')}
          </option>
          {onSiteItemsActivationEnabled && (
            <>
              <option key='isActiveOnSite' value='isActiveOnSite'>
                {t('status.isActiveOnSite')}
              </option>
              <option key='isActiveChef' value='isActiveChef'>
                {t('status.isActiveChef')}
              </option>
            </>
          )}
          <option key='isInactiveOnline' value='isInactiveOnline'>
            {t('status.isInactiveOnline')}
          </option>
          {onSiteItemsActivationEnabled && (
            <>
              <option key='isInactiveOnSite' value='isInactiveOnSite'>
                {t('status.isInactiveOnSite')}
              </option>
              <option key='isInactiveChef' value='isInactiveChef'>
                {t('status.isInactiveChef')}
              </option>
            </>
          )}
        </Select>
        <Select
          data-testid='categoryId-filter-select'
          w={{ base: '100px', md: '200px' }}
          rounded='base'
          size='sm'
          placeholder={t('tableActions.categoryIdFilterPlaceholder')}
          onChange={(event) => setCategoryIdFilterAndDeleteGroupIdFilter(event.target.value)}
        >
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.title}
            </option>
          ))}
          <option value={NO_CATEGORY_ID}>{t('tableActions.noCategoryIdValuePlaceholder')}</option>
        </Select>
        <Select
          data-testid='groupId-filter-select'
          w={{ base: '100px', md: '200px' }}
          rounded='base'
          size='sm'
          placeholder={t('tableActions.groupPlaceholder')}
          onChange={(event) => setGroupIdFilter(event.target.value)}
          isDisabled={!categoryIdFilter || categoryIdFilter === NO_CATEGORY_ID}
        >
          {categories
            .find((c) => c.id === categoryIdFilter)
            ?.groups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
        </Select>
      </HStack>
    </Stack>
  )
}
