import _deburr from 'lodash/deburr'
import _includes from 'lodash/includes'
import _sortBy from 'lodash/sortBy'

import { Employee } from '@/app/employee/Employee.model'
import { EmployeeRole } from '@/app/employee/EmployeeRole'
import { EmployeeStatus } from '@/app/employee/EmployeeStatus'

export enum StatusFilterValues {
  IS_ACTIVE = 'active',
  IS_PENDING = 'pending',
  IS_BLOCKED = 'blocked',
}

const isContainingLetters = ({ firstName, lastName, email }: Employee, nameFilter?: string): boolean => {
  const stringToSearch = `${firstName} ${lastName} ${email}`
  return nameFilter ? _includes(_deburr(stringToSearch.toLowerCase()), _deburr(nameFilter.toLowerCase())) : true
}

const isFilteredByRole = ({ role }: Employee, filterValue?: EmployeeRole): boolean => {
  return filterValue ? filterValue === role : true
}

const isFilteredByStatus = ({ status }: Employee, filterValue?: StatusFilterValues): boolean => {
  switch (filterValue) {
    case StatusFilterValues.IS_ACTIVE:
      return status === EmployeeStatus.active
    case StatusFilterValues.IS_PENDING:
      return status === EmployeeStatus.pending
    case StatusFilterValues.IS_BLOCKED:
      return status === EmployeeStatus.blocked
    default:
      return true
  }
}

export const useSortedFilteredEmployees = (
  employees: ReadonlyArray<Employee>,
  {
    nameFilter,
    roleFilter,
    statusFilter,
  }: {
    nameFilter?: string
    roleFilter?: EmployeeRole
    statusFilter?: StatusFilterValues
  }
): ReadonlyArray<Employee> => {
  return _sortBy(
    employees.filter(
      (employee) =>
        isContainingLetters(employee, nameFilter) &&
        isFilteredByRole(employee, roleFilter) &&
        isFilteredByStatus(employee, statusFilter)
    ),
    'name'
  )
}
