import * as React from 'react'

import { EllipseIcon } from '@district-qg/ui'

import { OrderPosStatus } from '@/app/order/Order.model'
import { useTodayOrdersQuery } from '@/app/order/useTodayOrdersQuery'

export const OrdersSidebarLinkIcon: React.FC = () => {
  const { data: orders } = useTodayOrdersQuery()
  const orderInError = orders?.some((order) => order.posStatus === OrderPosStatus.rejected)

  return <EllipseIcon fontSize='12' color={orderInError ? 'red.500' : 'steel.300'} />
}
