import { SupportedLocales } from '@district-qg/frontend-common'

import { Item, ItemModel } from '@/app/item/Item.model'
import { OrderItemDto } from '@/app/order/Order.dto'

export interface OrderItem extends Item {
  readonly quantity: number
}

export class OrderItemModel extends ItemModel implements OrderItem {
  constructor(readonly data: Readonly<OrderItemDto>, readonly locale: SupportedLocales) {
    super(data, locale)
  }

  get quantity(): number {
    return this.data.quantity
  }
}
