import * as React from 'react'
import { ReactElement } from 'react'

import { Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'

import { Employee } from '@/app/employee/Employee.model'
import { useEmployeesList } from '@/ui/pages/employees/EmployeesListContext'

import { TableColumn } from './EmployeesTableColumns'

export type EmployeesTableContentProps = {
  employees: ReadonlyArray<Employee>
  columns: ReadonlyArray<TableColumn>
}

export function EmployeesTableContent({ columns, employees }: EmployeesTableContentProps): ReactElement {
  const { setEditEmployee } = useEmployeesList()

  return (
    <Table my='8' borderWidth='1px' fontSize='sm'>
      <Thead bg='gray.50'>
        <Tr>
          {columns.map((column) => (
            <Th whiteSpace='nowrap' scope='col' key={column.id}>
              <Flex justify={column.alignment}>{column.header}</Flex>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {employees.map((employee) => (
          <Tr
            key={employee.email}
            onClick={() => setEditEmployee(employee)}
            cursor='pointer'
            data-testid={`row-${employee.email}`}
          >
            {columns.map((column) => (
              <Td key={column.id}>
                <Flex justify={column.alignment}>{column.cell(employee)}</Flex>
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
