import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useGetRoles } from '@/app/role/UserRolesProvider'
import { ORDERS_PATH } from '@/routes'
import { OrdersSidebarLinkIcon } from '@/ui/layout/components/sidebar/OrdersSidebarLinkIcon'
import { SidebarLink } from '@/ui/layout/components/sidebar/SidebarLink'

export const OrdersSidebarLink: React.FC = () => {
  const { t } = useTranslation()
  const { isAdmin, isConcierge } = useGetRoles()

  return isAdmin || isConcierge ? (
    <SidebarLink icon={<OrdersSidebarLinkIcon />} to={ORDERS_PATH} text={t('nav.orders')} opacity={1} />
  ) : null
}
