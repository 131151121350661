import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { buildSectionModel, Section } from '@/app/section/Section.model'
import { ZoneDto } from '@/app/zone/Zone.dto'

export interface Zone {
  readonly name: string
  readonly id: string
  readonly sections: ReadonlyArray<Section>
}

export class ZoneModel implements Zone {
  readonly sections = this.data.sections.map((section) => buildSectionModel(section, this.locale))

  constructor(private readonly data: Readonly<ZoneDto>, private readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get name(): string {
    return localize(this.data.name, this.locale)
  }
}

export function buildZoneModel(data: ZoneDto, locale: SupportedLocales): Zone {
  return new ZoneModel(data, locale)
}
