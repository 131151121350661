import React from 'react'

import { HStack, StackProps } from '@chakra-ui/react'

export type HstackItemProps = {
  onClick: () => void
} & StackProps

export const HStackItem: React.FC<HstackItemProps> = ({ onClick, children, ...props }) => {
  return (
    <HStack
      display='flex'
      spacing={2}
      onClick={onClick}
      border='solid'
      borderRadius='base'
      borderColor='gray.200'
      borderWidth='thin'
      p={2}
      width='100%'
      cursor='pointer'
      {...props}
    >
      {children}
    </HStack>
  )
}
