import { QueryObserverOptions } from 'react-query'

import { AxiosError } from 'axios'
import {
  SupportedLocales,
  useModelBuilderWithCurrentLocale,
  useQuery,
  UseQueryExtendedResult,
} from '@district-qg/frontend-common'

import { CategoryDto } from '@/app/category/Category.dto'
import { buildCategoryModel, Category } from '@/app/category/Category.model'
import { CATEGORIES_QUERY_KEY } from '@/app/category/useCategoriesQuery'

export const CATEGORY_QUERY_KEY = (id: Category['id']): [string, string] => [CATEGORIES_QUERY_KEY, id]

export type UseCategoryQueryOptions = QueryObserverOptions<CategoryDto, AxiosError> & {
  categoryId: Category['id']
}

export const useCategoryQuery = ({
  categoryId,
  ...options
}: UseCategoryQueryOptions): UseQueryExtendedResult<Category, CategoryDto> => {
  const mapper = useModelBuilderWithCurrentLocale(
    buildCategoryModel as (dto: CategoryDto, locale: SupportedLocales) => Category
  )

  return useQuery({
    ...options,
    queryKey: CATEGORY_QUERY_KEY(categoryId),
    path: `/v1/admin/categories/${categoryId}`,
    mapper,
  })
}
