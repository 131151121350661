import * as React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import _capitalize from 'lodash/capitalize'
import _range from 'lodash/range'
import { Box, HStack, IconButton, Switch, Text } from '@chakra-ui/react'
import { AddIcon, ClearIcon } from '@district-qg/ui'

import { CategoryOperatingHoursType } from '@/app/category/Category.model'
import { DEFAULT_HOURS } from '@/app/types/operatingHours'
import { CategoryCompleteOperatingHoursFormData } from '@/ui/pages/operatingHours/components/CategoryOperatingHoursFormData'

import { ConfirmDeleteRow } from './ConfirmDeleteRow'
import { OperatingHoursExceptionPeriod } from './OperatingHoursExceptionPeriod'

type OperatingHoursExceptionRowProps = {
  index: number
  day: string
  deleteRow(index: number): void
  type: CategoryOperatingHoursType
}

export const OperatingHoursExceptionRow: React.FC<OperatingHoursExceptionRowProps> = ({
  index,
  day,
  deleteRow,
  type,
}) => {
  const { t } = useTranslation('operatingHours')

  const [confirmIsOpen, setConfirmIsOpen] = React.useState(false)
  const onConfirmClose = () => setConfirmIsOpen(false)

  const { register, trigger, watch } = useFormContext<CategoryCompleteOperatingHoursFormData>()
  const {
    fields: periods,
    append,
    remove,
  } = useFieldArray<CategoryCompleteOperatingHoursFormData>({
    name: `operatingHours.${type}.exceptions.${index}.periods`,
  })
  const [isOpen] = watch([`operatingHours.${type}.exceptions.${index}.isOpen`])

  const rowDate = _capitalize(t('exception.date', { date: dayjs(day, 'YYYY-MM-DD').toDate() }))

  const addPeriod = () => {
    append({ ...DEFAULT_HOURS })
    trigger(`operatingHours.${type}.exceptions.${index}.periods`)
  }

  return (
    <>
      <Text alignSelf='center' gridColumnStart={1}>
        {rowDate}
      </Text>

      <HStack>
        <IconButton
          size='xs'
          onClick={() => setConfirmIsOpen(true)}
          aria-label={t('common:action.remove')}
          variant='outline'
          isRound
          icon={<ClearIcon boxSize='4' />}
          data-testid={`${index}-remove-button`}
        />

        <ConfirmDeleteRow
          title={t('confirmDelete.title')}
          isOpen={confirmIsOpen}
          onClose={onConfirmClose}
          onConfirm={() => deleteRow(index)}
        >
          {rowDate}
        </ConfirmDeleteRow>
      </HStack>
      <Switch
        data-testid={`${index}-is-open-switch`}
        alignSelf='center'
        lineHeight='1'
        {...register(`operatingHours.${type}.exceptions.${index}.isOpen` as const, {
          deps: [`operatingHours.${type}.exceptions.${index}`],
          onChange: () => {
            if (periods.length > 1) remove(_range(1, periods.length))
          },
        })}
      />

      {periods &&
        periods.map((period, periodIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={period.id}>
            <HStack spacing={1} gridColumnStart={-3}>
              <OperatingHoursExceptionPeriod
                type={type}
                exceptionIndex={index}
                periodIndex={periodIndex}
                // eslint-disable-next-line react/no-array-index-key
                key={`${type}-${index}-${periodIndex}`}
              />
            </HStack>
            <Box gridColumnStart={-1} gridColumnEnd={-2} alignSelf='center'>
              {isOpen && periodIndex === 0 && (
                <HStack>
                  <IconButton
                    aria-label={t('common:action.add')}
                    icon={<AddIcon boxSize='3' />}
                    size='xs'
                    variant='outline'
                    isRound
                    onClick={addPeriod}
                    data-testid={`${index}-add-button`}
                  />
                </HStack>
              )}
              {periodIndex > 0 && (
                <IconButton
                  aria-label={t('common:action.delete')}
                  icon={<ClearIcon boxSize='3' />}
                  size='xs'
                  variant='ghost'
                  isRound
                  onClick={() => remove(periodIndex)}
                  data-testid={`${index}-${periodIndex}-remove-period`}
                />
              )}
            </Box>
          </React.Fragment>
        ))}
    </>
  )
}
