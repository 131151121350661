import { useQueryClient } from 'react-query'

import { useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { CategoryDto } from '@/app/category/Category.dto'
import { CATEGORIES_QUERY_KEY } from '@/app/category/useCategoriesQuery'

export type CreateCategoryRequest = Pick<CategoryDto, 'qgId' | 'sectionId' | 'title' | 'subtitle' | 'description'>

export const useCreateCategoryMutation = (): UseMutationExtendedResult<CategoryDto, CreateCategoryRequest> => {
  const queryClient = useQueryClient()

  return useMutation({
    path: '/v1/admin/categories',
    method: 'post',
    requireAuthentication: true,
    onSuccess: async () => {
      await queryClient.invalidateQueries(CATEGORIES_QUERY_KEY)
    },
  })
}
