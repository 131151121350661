import { UseMutationOptions, useQueryClient } from 'react-query'

import { AxiosError } from 'axios'
import { useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { QGDto } from '@/app/qg/QG.dto'
import { QG_QUERY_KEY } from '@/app/qg/useQGQuery'
import { SectionDto } from '@/app/section/Section.dto'
import { Section } from '@/app/section/Section.model'
import { useQGId } from '@/environment'

export type UpdateSectionRequest = Omit<SectionDto, 'id'>

export const useUpdateSectionMutation = (
  options: UseMutationOptions<QGDto, AxiosError, UpdateSectionRequest> & {
    sectionId: Section['id']
  }
): UseMutationExtendedResult<QGDto, UpdateSectionRequest> => {
  const queryClient = useQueryClient()
  const qgId = useQGId()

  return useMutation<QGDto, UpdateSectionRequest>({
    ...options,
    path: `/v1/admin/qgs/${qgId}/sections/${options.sectionId}`,
    method: 'put',
    requireAuthentication: true,
    onSuccess: async (errors, variables, context) => {
      await queryClient.invalidateQueries(QG_QUERY_KEY)
      /* istanbul ignore next */
      return options?.onSuccess?.(errors, variables, context)
    },
  })
}
