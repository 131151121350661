export const datePickerStyles = {
  '.react-calendar': {
    fontSize: '1rem',
    fontFamily: 'body',
    lineHeight: 1,
    borderColor: 'gray.200',
    width: 'auto',
  },

  '.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus': {
    backgroundColor: 'gray.100',
  },
  '.react-calendar__navigation button[disabled]': {
    backgroundColor: 'gray.50',
  },
  '.react-calendar__month-view__days__day--weekend': {
    backgroundColor: 'gray.50',
    color: 'steel.500',
  },
  '.react-calendar__month-view__days__day--neighboringMonth': {
    color: 'steel.400',
  },
  '.react-calendar__tile:disabled': {
    backgroundColor: 'gray.50',
  },
  '.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus': {
    backgroundColor: 'peche.100',
    color: 'textColor',
  },
  '.react-calendar__tile--now': {
    background: 'gray.100',
  },
  '.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus': {
    background: 'peche.100',
  },
  '.react-calendar__tile--hasActive': {
    background: 'steel.100',
  },
  '.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus': {
    background: 'steel.light',
  },
  '.react-calendar__tile--active': {
    background: 'peche.200',
    color: 'inherit',
    fontWeight: 'bold',
  },
  '.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus': {
    background: 'peche.400',
    color: 'white',
  },
  '.react-calendar--selectRange .react-calendar__tile--hover': {
    backgroundColor: 'peche.100',
  },
}
