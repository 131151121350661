import React, { FC, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { Box, useToast, VStack } from '@chakra-ui/react'

import { ImageUploadRequest, useImageUpload } from '@/app/media/useImageUpload'
import { getToastConfiguration } from '@/ui/components/toast/getToastConfiguration'

const ALLOWED_CONTENT_TYPE = 'image/*'

export type ImageUploadDialogProps = {
  imageUploadRequest: ImageUploadRequest
}

export const ImageUploadDialog: FC<ImageUploadDialogProps> = ({ children, imageUploadRequest }) => {
  const { onDrop, uploadStatus } = useImageUpload(imageUploadRequest)
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ALLOWED_CONTENT_TYPE,
    noClick: true,
    noKeyboard: true,
    onDrop,
    maxFiles: 1,
  })

  const { t } = useTranslation()
  const toast = useToast()
  useEffect(() => {
    if (uploadStatus === 'error') {
      toast(getToastConfiguration(t('imageUpload.error.title'), t('imageUpload.error.description'), 'error'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatus])

  const loading = uploadStatus === 'loading'
  const childrenWithLoading = React.Children.map(children, (child) =>
    React.isValidElement(child) ? React.cloneElement(child, { loading: loading.toString() }) : child
  )

  return (
    <VStack>
      <Box className='container' onClick={open}>
        <Box {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {childrenWithLoading}
        </Box>
      </Box>
    </VStack>
  )
}
