import * as React from 'react'
import { useState } from 'react'
import Calendar from 'react-calendar'
import { useTranslation } from 'react-i18next'

import { Box, BoxProps, Center, CSSObject } from '@chakra-ui/react'

import 'react-calendar/dist/Calendar.css'

import { ButtonQg } from '../buttonQg/ButtonQg'

import { datePickerStyles } from './datePickerStyles'

export const datePickerWidth = '350px'

type DatePickerCommonProps = {
  locale: string
  customStyles?: CSSObject
  containerProps?: BoxProps
  clearable?: boolean
}

export type DatePickerProps = DatePickerCommonProps & {
  selectRange: false
  onChange: (date: Date | null) => void
  value?: Date | null
}

export type DateRangePickerProps = DatePickerCommonProps & {
  selectRange: true
  onChange: (date: [Date] | [Date, Date] | null) => void
  value?: Date[] | null
}

export const DatePicker: React.FC<DatePickerProps | DateRangePickerProps> = ({
  selectRange,
  clearable,
  onChange,
  value,
  locale,
  customStyles,
  containerProps,
}) => {
  const { t } = useTranslation()
  const [calendarValue, setCalendarValue] = useState(value)

  const handleChange = (date: Date | [Date] | [Date, Date] | null) => {
    setCalendarValue(date)
    onChange(date as (Date & ([Date] | [Date, Date])) | null)
  }

  return (
    <Box w={datePickerWidth} boxShadow='lg' sx={{ ...datePickerStyles, ...customStyles }} {...containerProps}>
      <Calendar
        value={calendarValue}
        onChange={handleChange}
        locale={locale}
        minDetail='year'
        prev2Label={null}
        next2Label={null}
        selectRange={selectRange}
      />
      {clearable && (
        <Center py={2}>
          <ButtonQg
            data-testid='date-clear-button'
            onClick={() => {
              handleChange(null)
            }}
          >
            {t('action.delete')}
          </ButtonQg>
        </Center>
      )}
    </Box>
  )
}
