import { useQueryClient } from 'react-query'

import { useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { Category } from '@/app/category/Category.model'
import { CATEGORIES_QUERY_KEY } from '@/app/category/useCategoriesQuery'
import { CATEGORY_QUERY_KEY } from '@/app/category/useCategoryQuery'
import { GroupDto } from '@/app/group/Group.dto'
import { Group } from '@/app/group/Group.model'

export type UpdateGroupRequest = Partial<Pick<GroupDto, 'active' | 'imageId'>>

export type UseUpdateGroupMutationProps = {
  categoryId: Category['id']
  groupId: Group['id']
}
export const useUpdateGroupMutation = ({
  categoryId,
  groupId,
}: UseUpdateGroupMutationProps): UseMutationExtendedResult<void, UpdateGroupRequest> => {
  const queryClient = useQueryClient()
  return useMutation<void, UpdateGroupRequest>({
    path: `/v1/admin/categories/${categoryId}/groups/${groupId}`,
    method: 'patch',
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(CATEGORIES_QUERY_KEY),
        queryClient.invalidateQueries(CATEGORY_QUERY_KEY(categoryId)),
      ])
    },
  })
}
