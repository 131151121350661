import * as React from 'react'

import { QGFeatureConfigurationProvider } from '@district-qg/frontend-common'

import { useQGQuery } from '@/app/qg/useQGQuery'

export const QGAdminFeaturesProvider: React.FC = ({ children }) => {
  const { dto: qg } = useQGQuery()
  return <QGFeatureConfigurationProvider value={qg?.features}>{children}</QGFeatureConfigurationProvider>
}
