import * as React from 'react'

import { EmployeeRole } from '@/app/employee/EmployeeRole'

import { useEmployeesList } from '../EmployeesListContext'

import { EmployeesTableActions } from './EmployeesTableActions'
import { useEmployeesTableColumns } from './EmployeesTableColumns'
import { EmployeesTableContent } from './EmployeesTableContent'
import { StatusFilterValues, useSortedFilteredEmployees } from './EmployeesTableFilter'

export const EmployeesTable: React.FC = () => {
  const columns = useEmployeesTableColumns()
  const { employees } = useEmployeesList()

  const [nameFilter, setNameFilter] = React.useState<string>()
  const [roleFilter, setRoleFilter] = React.useState<EmployeeRole>()
  const [statusFilter, setStatusFilter] = React.useState<StatusFilterValues>()

  return (
    <>
      <EmployeesTableActions
        setNameFilter={setNameFilter}
        setRoleFilter={setRoleFilter}
        setStatusFilter={setStatusFilter}
      />
      <EmployeesTableContent
        columns={columns}
        employees={useSortedFilteredEmployees(employees, {
          nameFilter,
          roleFilter,
          statusFilter,
        })}
      />
    </>
  )
}
